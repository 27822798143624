import React from 'react'
import {connect} from 'react-redux'
import MobileSearchDocs from '../../components/search-m/MobileSearchDocs'
import ReactLoading from "react-loading"
import {fulltextSearchDocs} from '../../actions'

class MobileSpecDocsSearch extends React.Component {
  componentDidMount() {
    const {mobileConfirmWord, fulltextSearchDocs} = this.props
    if (!!mobileConfirmWord) {
      fulltextSearchDocs(mobileConfirmWord, 0)
    }
  }
  componentDidUpdate(prevProps) {
    const {mobileConfirmWord, fulltextSearchDocs} = this.props
    const {mobileConfirmWord: prevMobileConfirmWord} = prevProps
    if (!!mobileConfirmWord && mobileConfirmWord !== prevMobileConfirmWord) {
      fulltextSearchDocs(mobileConfirmWord, 0)
    }
  }

  render() {
    const {mobileConfirmWord, docs} = this.props
    if (!mobileConfirmWord) {
      return null
    }
    if (docs.start === 0 && docs.isLoading) {
      return (
        <div className="loading-container">
          <ReactLoading type="bars" color="lightgray" className="loading-element"/>
        </div>
      )
    }
    if (docs.length === 0) {
      return <div className="no-result">
        Oooops<br/>没有相关的搜索结果！
      </div>
    }
    return <MobileSearchDocs />
  }
}

const mapStateToProps = state => {
  const {mobileConfirmWord, docs} = state.sm
  return {mobileConfirmWord, docs}
}

const mapDispatchToProps = dispatch => ({
  fulltextSearchDocs: (word, start) => dispatch(fulltextSearchDocs(word, start))
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileSpecDocsSearch)