import React from 'react'
import {withRouter, Link} from 'react-router-dom'
import {resetSearch, setFuzzySearchWord, setMobileConfirmWord} from '../actions'
import { connect } from 'react-redux'

function getType(pathname) {
  if (pathname === '/search-m' || pathname === '/search-m/') {
    return "entry"
  } else if (pathname.startsWith('/search-m/cat/docs')) {
    return "docs"
  } else if (pathname.startsWith('/search-m/cat/learn')) {
    return "courses"
  }
  return "entry"
}

function getPlaceholder(headerType) {
  switch(headerType) {
    case "entry":
      return "搜索"
    case "docs":
      return "搜索文档"
    case "learn":
      return "搜索课程"
    default:
      return "搜索"
  }
}

class MobileSearchLayout extends React.Component {
  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
    props.resetSearch()
  }
  componentDidMount() {
    this.inputRef.current.focus()
  }
  componentWillUpdate(prevProps) {
    const pathname = this.props.location.pathname
    const prevPathname = prevProps.location.pathname
    if (pathname !== prevPathname) {
      this.props.setMobileConfirmWord(this.props.word)
      this.props.resetSearch()
    }
  }
  onSearchChange() {
    const txtVal = this.inputRef.current.value
    this.props.setFuzzySearchWord(txtVal)
    if (!txtVal) {
      this.props.resetSearch(true)
    }
  }
  onKeyDown(e) {
    if(e.keyCode == 13) {
      const txtVal = this.inputRef.current.value
      this.props.setMobileConfirmWord(txtVal)
    }
  }
  render() {
    const {children, location} = this.props
    const {pathname} = location
    const headerType = getType(pathname)
    return (
      <div className="search-m">
        <header className="search-header">
          <div className={`input-container ${headerType}`}>
            <div className="input-container-inner">
              <Link to="/search-m"><i className="go-back"/></Link>
              <input type="text" 
                placeholder={getPlaceholder(headerType)} 
                  ref={this.inputRef} 
                  onChange={() => this.onSearchChange()}
                  onKeyDown={e => this.onKeyDown(e)}/>
              <i className="icon"/>
            </div>
          </div>
          <Link className="cancel" to="/">取消</Link>
        </header>
        {children}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const {word} = state.search
  return {
    word
  }
}

const mapDispatchToProps = dispatch => ({
  resetSearch: (hard) => dispatch(resetSearch(hard)),
  setFuzzySearchWord: word => dispatch(setFuzzySearchWord(word)),
  setMobileConfirmWord: word => dispatch(setMobileConfirmWord(word))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MobileSearchLayout))