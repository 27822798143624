import React from "react";
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
  testLoggedIn, getLearnPathDetail, likeLearnPath, likeLearnCourse, openLearnPath,
  startLearnPathCourse, getLearnCommentCount
} from '../../actions';
import ContentLoader from "react-content-loader";
import { toast } from 'react-toastify';
import Breadscrub from '../../components/Breadscrub';
import CourseCategory from '../../components/CourseCategory';

import iconCollect from '../../assets/icon-collect.png';
import iconUnCollect from '../../assets/icon-nocollect.png';

import appInsights, { setGlobalParam, clearGlobalParams, getGlobalParams } from '../../tools/telemetry'

import { runtimeConfig } from '../../config'

const { console_url, login_url } = runtimeConfig

class LearnCoursePath extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    learnPathData: null,
    commentCount: 0
  }

  componentDidMount() {
    const { pathId } = this.props.match.params;
    const { getLearnPathDetail, openLearnPath, getLearnCommentCount } = this.props;
    openLearnPath(pathId).then(() => {
      getLearnPathDetail(pathId).then(data => {
        setGlobalParam("Url", window.location.href)
        setGlobalParam('PageName', 'LearnPath')
        setGlobalParam("LearnPathID", `${pathId}`)
        setGlobalParam("LearnPathName", data.name)
        appInsights.trackEvent({ name: 'Academy_VisitedPageEvent' }, getGlobalParams())
        this.StartVisitTime = new Date()
        this.setState({
          learnPathData: data
        })
        let names = new Array()
        if (data.name) {
          names.push(data.name)
        }
        names.push("RPA课程")
        names.push("云扩学院")
        document.title = names.join("_")
      });
    });
    getLearnCommentCount(pathId).then(c => {
      this.setState({
        commentCount: c
      })
    })
  }


  componentWillUnmount() {
    const LeavePageTime = new Date()
    const StadyTime = LeavePageTime - this.StartVisitTime
    appInsights.trackEvent({ name: 'Academy_StadyTimeEvent' }, { StartVisitTime: this.StartVisitTime, LeavePageTime, StadyTime, ...getGlobalParams() })
    clearGlobalParams()
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { pathId } = nextProps.match.params;
    const { getLearnPathDetail, openLearnPath, getLearnCommentCount } = this.props;

    if (nextState.learnPathData && nextState.learnPathData.id != nextProps.match.params.pathId) {
      openLearnPath(pathId).then(() => {
        getLearnPathDetail(pathId).then(data => {
          this.setState({
            learnPathData: data
          })
          let names = new Array()
          if (data.name) {
            names.push(data.name)
          }
          names.push("RPA课程")
          names.push("云扩学院")
          document.title = names.join("_")
        });
      });
      getLearnCommentCount(pathId).then(c => {
        this.setState({
          commentCount: c
        })
      })
    }
  }

  testLoggedInThen() {
    const { testLoggedIn, history } = this.props;

    return function (callback) {
      testLoggedIn().then(() => {
        callback()
      }).catch(err => {
        if (err.message === '用户未登录') {
          const returnUrl = window.encodeURIComponent(window.location.pathname)
          window.location = `${login_url}?returnUrl=${returnUrl}`
        } else if (err.message === '未知错误') {
          toast('未知错误');
        }
      })
    }
  }

  startLearn(courseId, learnPathName) {
    const { pathId } = this.props.match.params;
    const { startLearnPathCourse } = this.props;

    appInsights.trackEvent({ name: 'Academy_UserActionEvent' }, { ButtonOrMenuName: "StartCourse", ...getGlobalParams() })
    startLearnPathCourse({ pathId, courseId }).then(() => {
      this.props.history.push(`/learn/course-detail/${courseId}?isOpen=false`);
    }).catch({})
  }

  toggleLikePath() {
    const { pathId } = this.props.match.params;
    const { likeLearnPath } = this.props;
    const { learnPathData } = this.state;

    learnPathData.favorite = !this.state.learnPathData.favorite
    appInsights.trackEvent({ name: 'Academy_UserActionEvent' }, { ButtonOrMenuName: 'Favorite', Check: learnPathData.favorite, ...getGlobalParams() })
    likeLearnPath(pathId).then(() => {
      this.setState({
        learnPathData
      })
    });
  }

  toggleLikeCourse(courseId) {
    const { likeLearnCourse } = this.props;

    likeLearnCourse(courseId);
  }

  trackClickPrev(prev) {
    appInsights.trackEvent({ name: 'Academy_UserActionEvent' }, { ButtonOrMenuName: 'Prev', PrevLearnPathID: `${prev.id}`, PrevLearnPathName: `${prev.name}`, ...getGlobalParams() })
  }

  trackClickNext(next) {
    appInsights.trackEvent({ name: 'Academy_UserActionEvent' }, { ButtonOrMenuName: 'Next', NextLearnPathID: `${next.id}`, NextLearnPathName: `${next.name}`, ...getGlobalParams() })
  }

  render() {
    const { learnPathData, commentCount } = this.state;
    const { pathId } = this.props.match.params;
    const { adminLoggedIn } = this.props

    return (
      <div style={{ backgroundColor: '#F5F6F7' }}>
        {
          !!learnPathData ?
            (
              <div className="learn-course">
                <Breadscrub items={[{ name: '首页', url: '/', trans: true }, { name: '课程', url: '/learn', trans: true }, { name: `${learnPathData.name}`, url: `/learn/path-detail/${pathId}`, trans: false }]} />
                <div className="course-container path-container">
                  <div className="course-thumb-wrap">
                    <img className="course-thumb" src={learnPathData.iconUrl} alt="" draggable="false" />
                  </div>

                  <article className="course-info-wrap">
                    <img className="icon-collect" src={learnPathData.favorite ? iconCollect : iconUnCollect} onClick={e => this.testLoggedInThen()(() => { this.toggleLikePath() })} draggable="false" />
                    <h3 className="course-title">{learnPathData.name}</h3>
                    <ul className="course-subtitle-wrap">
                      <li className="course-subtitle-item">
                        <span className="title">课程定位：</span>
                        {learnPathData.target}
                      </li>
                      <li className="course-subtitle-item">
                        <span className="title">受众：</span>
                        {learnPathData.audience}
                      </li>
                      <li className="course-subtitle-item">
                        <span className="title">课程时间：</span>
                        {learnPathData.timeDesc}
                      </li>
                    </ul>
                    {
                      (learnPathData.progress !== null && learnPathData.childCount > 0) ?
                        (
                          <div className="course-progress">
                            <div className="progress">
                              <p className="progress-info">
                                已学习 {learnPathData.progress} 课程，共 {learnPathData.childCount} 课程
                              </p>
                              <div className="progress-back">
                                <div className="progress-front" style={{ width: (Math.round(learnPathData.progress / learnPathData.childCount * 100)) + '%' }}></div>
                              </div>
                            </div>
                          </div>
                        ) :
                        null
                    }
                    <ul className="course-simple-wrap">
                      {
                        Array.from(learnPathData.roles, (role, idx) => (
                          <li key={idx} className="course-simple-item">
                            {role}
                          </li>
                        ))
                      }
                      <li className="course-simple-item">
                        {learnPathData.level}
                      </li>
                    </ul>
                    <p className="course-desc">
                      {learnPathData.description}
                    </p>
                    <div className="course-prev-wrap">
                      <span className="title">先决条件：</span>
                      {
                        Array.from(learnPathData.prevs, (prev, idx) => {
                          return (<Link key={prev.id}
                            to={`/learn/path-detail/${prev.id}`}
                            onClick={() => this.trackClickPrev(prev)}
                          >{prev.name}{(idx !== learnPathData.prevs.length - 1) ? '、' : ''}</Link>)
                        })
                      }
                    </div>
                    <div className="course-extend-wrap path-extend-wrap">
                      <span className="title">推荐延伸学习：</span>
                      {
                        Array.from(learnPathData.nexts, (next, idx) => {
                          return (<Link key={next.id}
                            to={`/learn/path-detail/${next.id}`}
                            onClick={() => this.trackClickNext(next)}
                          >{next.name}{(idx !== learnPathData.nexts.length - 1) ? '、' : ''}</Link>)
                        })
                      }
                    </div>
                  </article>
                </div>

                {/* {adminLoggedIn ? <div className="learn-comment-toolbar">
                  <a href={`${console_url}/#/user/comment/learn/${pathId}`} target="_blank" className="my-btn">管理员评论({commentCount})</a>
                </div> : null} */}

                <div className="learn-path">
                  <h3 className="learn-path-title">本学习路径的课程</h3>
                  {
                    Array.from(learnPathData.children, (course, idx) => (
                      <div key={course.id} className="course-container">
                        <div className="course-thumb-wrap">
                          <img className="course-thumb" src={course.iconUrl} alt="" draggable="false" />
                        </div>

                        <article className="course-info-wrap">
                          {/* <img className="icon-collect" src={course.favorite ? iconCollect : iconUnCollect} onClick={e => this.toggleLikeCourse(course.id)} draggable="false" /> */}
                          <h3 className="course-title">{course.name}</h3>
                          {
                            (course.progress !== null && course.childCount > 0) ?
                              (
                                <div className="course-progress">
                                  <div className="progress">
                                    <p className="progress-info">
                                      已学习 {course.progress} 单元，共 {course.childCount} 单元
                                    </p>
                                    <div className="progress-back">
                                      <div className="progress-front" style={{ width: (Math.round(course.progress / course.childCount * 100)) + '%' }}></div>
                                    </div>
                                  </div>
                                </div>
                              ) :
                              null
                          }
                          <p className="course-desc">
                            {course.description}
                          </p>
                          <button className="pure-button pure-button-primary course-learn" onClick={e => this.testLoggedInThen()(() => { this.startLearn(course.id, learnPathData.name) })}>
                            开始学习
                          </button>
                          <CourseCategory collect={learnPathData.lastCourseId ? learnPathData.lastCourseId === course.id : idx === 0} title="课程目录" dataSource={course.children} />
                        </article>
                      </div>
                    ))
                  }
                </div>
              </div>
            ) :
            (
              <div className="learn-course">
                <div className="course-container" style={{ display: 'block', height: '335px' }}>
                  <ContentLoader viewBox="0 0 960 262">
                    <rect x="102" y="0" width="90" height="80" />
                    <rect x="238" y="0" width="150" height="25" />
                    <rect x="238" y="40" width="320" height="16" />
                    <rect x="238" y="70" width="123" height="16" />
                    <rect x="238" y="90" width="200" height="4" />
                    <rect x="238" y="110" width="90" height="29" />
                    <rect x="341" y="110" width="48" height="29" />
                    <rect x="400" y="110" width="48" height="29" />
                    <rect x="460" y="110" width="48" height="29" />
                    <rect x="238" y="151" width="293" height="16" />
                    <rect x="238" y="181" width="157" height="18" />
                    <rect x="238" y="211" width="111" height="18" />
                  </ContentLoader>
                </div>
                <div className="learn-path">
                  <ContentLoader viewBox="0 0 960 330">
                    <rect x="102" y="0" width="144" height="24" />
                    <rect x="102" y="78" width="72" height="72" />
                    <rect x="238" y="78" width="130" height="24" />
                    <rect x="238" y="117" width="123" height="16" />
                    <rect x="238" y="137" width="200" height="4" />
                    <rect x="238" y="156" width="293" height="16" />
                    <rect x="238" y="187" width="88" height="43" />
                    <rect x="238" y="254" width="66" height="16" />
                    <rect x="238" y="282" width="300" height="18" />
                    <rect x="238" y="312" width="300" height="18" />
                  </ContentLoader>
                  <ContentLoader viewBox="0 0 960 275">
                    <rect x="102" y="24" width="72" height="72" />
                    <rect x="238" y="24" width="130" height="24" />
                    <rect x="238" y="63" width="123" height="16" />
                    <rect x="238" y="83" width="200" height="4" />
                    <rect x="238" y="102" width="293" height="16" />
                    <rect x="238" y="133" width="88" height="43" />
                    <rect x="238" y="200" width="66" height="16" />
                    <rect x="238" y="228" width="300" height="18" />
                    <rect x="238" y="258" width="300" height="18" />
                  </ContentLoader>
                </div>
              </div>
            )
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { adminLoggedIn } = state.global
  return {
    adminLoggedIn
  }
}

const mapDispatchToProps = (dispatch) => ({
  testLoggedIn: () => dispatch(testLoggedIn()),
  getLearnPathDetail: pathId => dispatch(getLearnPathDetail(pathId)),
  openLearnPath: pathId => dispatch(openLearnPath(pathId)),
  likeLearnPath: pathId => dispatch(likeLearnPath(pathId)),
  likeLearnCourse: courseId => dispatch(likeLearnCourse(courseId)),
  startLearnPathCourse: pair => dispatch(startLearnPathCourse(pair)),
  getLearnCommentCount: learnId => dispatch(getLearnCommentCount(learnId))
})

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LearnCoursePath)))
