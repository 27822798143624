export function getCurrentStoredLang() {
  const lang = localStorage.getItem('lang')
  return lang
}

export function setCurrentStoredLang(lang) {
  if (lang.startsWith('en')) {
    lang = 'en-us'
  } else if (lang.startsWith('zh')) {
    lang = 'zh-cn'
  } else if (lang.startsWith('ja')) {
    lang = 'ja-jp'
  } else {
    lang = 'zh-cn'
  }
  localStorage.setItem('lang', lang)
}

export function applyStoredLangIfEmpty() {
  let lang = null
  const pathname = window.location.pathname
  if (pathname.startsWith('/en-us')) {
    lang = 'en-us'
  } else if (pathname.startsWith('/zh-cn')) {
    lang = 'zh-cn'
  } else if (pathname.startsWith('/ja-jp')) {
    lang = 'ja-jp'
  }
  if (!!lang) {
    setCurrentStoredLang(lang)
    return
  }
  const lang_now = getCurrentStoredLang()
  if (!!lang_now) {
    return
  }
  lang = 'zh-cn'
  setCurrentStoredLang(lang)
}