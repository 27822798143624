import React, { useState, useRef, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { useDeviceType } from '../tools/hooks'
import IconCircleStar from '../assets/index/circle-star.png'
import {processCourseIconUrl} from '../tools/customUtil'

import { runtimeConfig } from '../config'
const { is_prod } = runtimeConfig

const STEP_SIZE = 276 + 40

const OnlineCourseContainer = ({ winWidth, winHeight, courses, testLoggedIn }) => {
  const wrapperRef = useRef(null)
  const [hasOverflow, setHasOverflow] = useState(false)
  const [containerLeft, setContainerLeft] = useState(0)
  const [wrapperWidth, setWrapperWidth] = useState(0)
  const [containerWidth, setContainerWidth] = useState(0)
  const isMobile = useDeviceType(winWidth)
  useEffect(() => {
    if (isMobile) {
      setContainerLeft(0)
    }
  }, [isMobile])

  useEffect(() => {
    if (is_prod) {
      effectiveUpdater()
    } else {
      setTimeout(effectiveUpdater, 400)
    }
  }, [winWidth, courses])

  function effectiveUpdater() {
    if (!wrapperRef.current) {
      return
    }
    const wrapperWidth = wrapperRef.current.offsetWidth
    const containerWidth = wrapperRef.current.querySelectorAll('.g-course').length * STEP_SIZE + 72
    if (wrapperWidth < containerWidth) {
      setHasOverflow(true)
    } else {
      setHasOverflow(false)
    }
    setWrapperWidth(wrapperWidth)
    setContainerWidth(containerWidth)
  }

  const hasScroller = !isMobile && hasOverflow

  const scrollLeftEnabled = containerLeft > 0
  const scrollRightEnabled = (containerLeft + wrapperWidth) < containerWidth

  function handleScrollLeft() {
    let _containerLeft = containerLeft - STEP_SIZE;
    if (_containerLeft <= 0) {
      _containerLeft = 0
    }
    setContainerLeft(_containerLeft)
  }

  function handleScrollRight() {
    const maxRight = containerWidth - wrapperWidth;
    let _containerLeft = containerLeft + STEP_SIZE
    if (_containerLeft >= maxRight) {
      _containerLeft = maxRight
    }
    setContainerLeft(_containerLeft)
  }

  function getSmallClass(idx) {
    if (isMobile) {
      return ''
    }
    const leftBoundary = containerLeft
    const rightBoundary = containerLeft + wrapperWidth
    const itemLeft = idx * STEP_SIZE
    const itemRight = (idx + 1) * STEP_SIZE
    if (leftBoundary > itemLeft && leftBoundary < itemRight) {
      return ' small-left'
    }
    if (rightBoundary > itemLeft && rightBoundary < itemRight) {
      return ' small'
    }
    return ''
  }

  function outlinkNav(url) {
    window.open(url, "_blank")
  }

  return (
    <div className="online-courses">
      <h2>新课推荐</h2>
      <div className="sep">
        <div className="sep-left"></div>
        <img src={IconCircleStar} className="circle-star" />
        <div className="sep-right"></div>
      </div>
      <div className="index-course-wrapper" ref={wrapperRef}>
        {hasScroller ? (
          <Fragment>
            <div className={`scroll-left${scrollLeftEnabled ? ' active' : ''}`} onClick={() => {
              scrollLeftEnabled && handleScrollLeft()
            }}></div>
            <div className={`scroll-right${scrollRightEnabled ? ' active' : ''}`} onClick={() => {
              scrollRightEnabled && handleScrollRight()
            }}></div>
          </Fragment>
        ) : null}
        <div className="search-more">
          <Link to="/learn">查看更多课程</Link>
        </div>
        <div className="g-course-list" style={{ left: -containerLeft }}>
          {
            Array.from(courses, (cItem, idx) => (
              <div className="g-course" key={cItem.id}>
                <div className={`g-course-inner${getSmallClass(idx)}`}>
                  <div className={`bar${cItem.type === 1 ? ' lp' : ' c'}`}></div>
                  <img src={processCourseIconUrl(cItem.iconUrl)} />
                  <div className="content-body">
                    <label>{cItem.type === 1 ? '学习路径' : '课 程'}</label>
                    <Link className="name" to={`${cItem.type === 1 ? '/learn/path-detail/' : '/learn/course-detail/'}${cItem.id}`}>{cItem.name}</Link>
                    <div className="txt-line">
                      <i />{cItem.timeDesc}<span className="txt-line-split">|</span>{cItem.learningCount}人在学
                </div>
                    <div className="role-line">
                      {
                        cItem.roleNames.filter((_, rIdx) => rIdx < 2).map((rn, rIdx) => (
                          <div className="role" key={rIdx}>{rn}</div>
                        ))
                      }
                    </div>
                    <div className="last-row">
                      {
                        (cItem.progress !== null && cItem.childCount > 0) ?
                          (
                            <Fragment>
                              <div className="progress"><div className="progress-inner" style={{ width: (Math.round(cItem.progress / cItem.childCount * 100)) + '%' }}></div></div>
                              <div className="perc">{Math.round(cItem.progress / cItem.childCount * 100)}%</div>
                            </Fragment>
                          ) :
                          null
                      }
                      <div className={cItem.isFavorite ? "star" : "no-star"} onClick={() => testLoggedIn(cItem.type, cItem.id)}></div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
          {/* <div className="course-item">
            <div className={`course-item-inner${getSmallClass(0)}`}>
              <header style={{ backgroundColor: '#1A88E5' }} onClick={() => outlinkNav("https://academy.encootech.com/courses/rpa-overview/")}>
                <h3>RPA概览</h3>
                <p>开启RPA之旅</p>
              </header>
              <div className="course-item-body">
                <div className="lesson disabled">
                  <img src={IconLes1_1} />
                  RPA基础介绍
                </div>
                <div className="lesson disabled">
                  <img src={IconLes1_2} />
                  RPA适用场景
                </div>
                <div className="lesson disabled">
                  <img src={IconLes1_3} />
                  自动化拐点
                </div>
                <div className="lesson disabled">
                  <img src={IconLes1_4} />
                  BotTime简介
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  winWidth: state.winsize.width,
  winHeight: state.winsize.height,
  courses: state.indexData.newCourses
})

export default connect(mapStateToProps)(OnlineCourseContainer)