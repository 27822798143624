import { ConstValues } from '../actions'
import update from 'immutability-helper'

const initialData = {
  loading: false,
  dataByPage: [], // [{pageIdx:0, list: []}]
  pageIdx: 0,
  count: 0,
  innerSearch: false,

  //conditions
  roleIds: [],
  level: 0,
  type: 0,
  tagIds: [],
  name: '',

  // initialized data
  roles: [], // [{id, name}]
  levels: [], // [{id, name}]
  tags: [] // [{id, name}]
}

export default (state = initialData, action) => {
  switch (action.type) {
    case ConstValues.LEARN_GET_ROLES:
      return update(state, {
        roles: { $set: action.roles }
      })
    case ConstValues.LEARN_GET_LEVELS:
      return update(state, {
        levels: { $set: action.levels }
      })
    case ConstValues.LEARN_GET_TAGS:
      return update(state, {
        tags: { $set: action.tags }
      })
    case ConstValues.LEARN_SET_ROLEIDS:
      return update(state, {
        roleIds: { $set: action.roleIds }
      })
    case ConstValues.LEARN_SET_LEVEL:
      return update(state, {
        level: { $set: action.level }
      })
    case ConstValues.LEARN_SET_TYPE:
      return update(state, {
        type: { $set: action._type }
      })
    case ConstValues.LEARN_SET_TAGIDS:
      return update(state, {
        tagIds: { $set: action.tagIds }
      })
    case ConstValues.LEARN_SET_NAME:
      return update(state, {
        name: { $set: action.name },
        innerSearch: { $set: action.innerSearch }
      })
    case ConstValues.LEARN_START_LOADING_LIST:
      return update(state, {
        loading: { $set: true }
      })
    case ConstValues.LEARN_FINISH_LOADING_LIST:
      {
        let updateQuery = {
          loading: { $set: false }
        }
        if (action.list.length > 0) {
          updateQuery.count = { $set: action.count }
          updateQuery.pageIdx = { $set: action.pageIdx }
          updateQuery.dataByPage = { $push: [{ pageIdx: action.pageIdx, list: action.list }] }
        }
        return update(state, updateQuery)
      }
    case ConstValues.LEARN_CLEAR_LIST:
      return update(state, {
        loading: { $set: false },
        dataByPage: { $set: [] },
        pageIdx: { $set: 0 },
        count: { $set: 0 }
      })
    case ConstValues.REFRESH_LL_LIST: {
      let id = action.id;
      let _dataByPage = [...state.dataByPage];
      for (const _dataByPageItem of _dataByPage) {
        for (let i = 0; i < _dataByPageItem.list.length; i++) {
          if (_dataByPageItem.list[i].id == id) {
            _dataByPageItem.list[i].isFavorite = !_dataByPageItem.list[i].isFavorite
          }
        }
      }
      return update(state, { dataByPage: { $set: _dataByPage } });
    }
    case ConstValues.INIT_LEARN_LANDING_CONDITION:
    {
      const roleName = action.roleName
      const roleIdx = state.roles.findIndex(p => p.name.replace(' ', '') === roleName.replace(' ', ''))
      const roleIds = roleIdx < 0? []: [state.roles[roleIdx].id]
      return update(state, {
        loading: {$set: false},
        dataByPage: {$set: []}, 
        pageIdx: {$set: 0},
        count: {$set: 0},
        innerSearch: {$set: false},
        roleIds: {$set: roleIds},
        level: {$set: 0},
        type: {$set: 1},
        tagIds: {$set: []},
        name: {$set: ''}
      })
    }
    default:
      return state;
  }
}