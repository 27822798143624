import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Select from 'react-select'
import { updateWiki,  userSetLang } from '../actions'
import { withTranslation } from 'react-i18next';
import i18n from '../i18n';

const SelectDocLang = ({ options, defaultValue, updateWiki, userSetLang, location, history }) => {

  const [currentLang, setCurrentLang] = useState(defaultValue)
  useEffect(() => {
    const { pathname } = location
    function getLang(pathname) {
      let lang = 'zh-cn'
      const mat = /^(\/(?<lang>(zh-cn|en-us|ja-jp)))?\/(wiki)\/(?<path>[^\?]+)(\?(?<q>[^?]+))?/.exec(pathname);
      if (mat) {
        lang = mat.groups.lang;
      }
      return lang
    }
    const lang = getLang(pathname)
    if (lang) {
      const langLabel = options.find(item => item.value == lang).label
      setCurrentLang({ value: lang, label: langLabel })
    } else if (!!defaultValue) {
      setCurrentLang(defaultValue)
    }
  }, [defaultValue])
  function onChange(option) {
    if (!!currentLang && option.value === currentLang.value) {
      return
    }
    userSetLang(option.value, history, location, i18n)
    updateWiki('', option.value, true)
  }
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      marginLeft: 5,
      marginRight: 5,
      width: 'calc(100% - 10px)'
    })
  }
  return <Select value={currentLang}
    options={options}
    onChange={onChange}
    styles={customStyles} />
}

const mapStateToProps = (state, ownProps) => {
  let { locale } = state.lang
  const options = [
    {
      value: "zh-cn",
      label: "简体中文"
    },
    {
      value: "en-us",
      label: "English"
    },
    {
      value: "ja-jp",
      label: "日本語"
    }
  ]
  const localeLabel = options.find(item => item.value == locale).label

  return {
    options: options,
    defaultValue: {
      value: locale,
      label: localeLabel
    }
  }
}

const mapDispatchToProps = dispatch => ({
  updateWiki: (v, lang, force) => dispatch(updateWiki(v, lang, force)),
  userSetLang: (lang, history, location, i18n) => dispatch(userSetLang(lang, history, location, i18n))
})

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectDocLang)))