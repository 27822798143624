import React, { Fragment } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  updateWiki, initializeWikiTreeOpenKeysByPath, getWikiMarkdownByPath,
  setNavInPage, updateActiveNavInPage, updateActiveNavInPageForce, setWikiBreadscrumbsByPath,
  setLang, setDocVersion, getGithubPageUrl, getGithubIssueUrl, getUuidByPath, getWikiCommentCount, getRelatedUnit
} from '../actions'
import * as Showdown from "showdown"
import StickyWikiSideBar from '../components/StickyWikiSideBar'
// import WikiComment from '../components/WikiComment'
import Breadscrub from '../components/Breadscrub'
import Toc from '../components/Toc'
import { withTranslation } from 'react-i18next';
import { runtimeConfig } from '../config'
import ImgModal from "../components/ImgModal";

const { console_url } = runtimeConfig

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
  prefixHeaderId: 'header',
  parseImgDimensions: true
});

class Wiki extends React.PureComponent {
  constructor(props) {
    super(props)

    this.articleRef = React.createRef();
    this.onScroll = this.onScroll.bind(this);
  }

  state = {
    githubPageUrl: null,
    githubIssueUrl: null,
    commentCount: 0,
    showBigImg: false,
    bigImgSrc: '',
  }

  goto404() {
    const { pathname } = this.props.location;
    const { history } = this.props
    if (pathname.endsWith('/wiki/404.md')) {
      history.replace('/404')
    } else {
      history.replace('/wiki/404.md')
    }
  }

  componentDidMount() {
    let { pathname, search } = this.props.location;
    if (pathname == "/wiki" || pathname == "/wiki/") {
      pathname = "/wiki/Index.md"
    }
    let version = '', lang = 'zh-cn'
    var path = extractPath(pathname + search, (_) => {
      this.props.setLang(_)
      lang = _
    }, (_) => {
      this.props.setDocVersion(_)
      version = _
    })
    const { updateWiki, initializeWikiTreeOpenKeysByPath, getWikiMarkdownByPath,
      getGithubPageUrl, getGithubIssueUrl, getUuidByPath, getWikiCommentCount } = this.props
    if (path === null) {
      this.goto404()
      return
    }
    getWikiMarkdownByPath(path, version, lang).then(() => {
      updateWiki(version, lang).then(() => {
        initializeWikiTreeOpenKeysByPath(path, lang)
        this.triggerMeasureWikiContainer();
      })
      getWikiCommentCount().then((c) => {
        this.setState({
          commentCount: c
        })
      })
    }).catch(() => {
      this.goto404()
    })
    getGithubPageUrl(path).then((githubPageUrl) => {
      if (!!githubPageUrl) {
        this.setState({
          githubPageUrl
        })
      }
    })
    getGithubIssueUrl(path).then((githubIssueUrl) => {
      if (!!githubIssueUrl) {
        this.setState({
          githubIssueUrl
        })
      }
    })
    if (!containUuidValue(window.location.href)) {
      getUuidByPath(path, lang, version).then((uuid) => {
        if (!!uuid) {
          let newUrl = rebuildUrlWithUuid(pathname, search, uuid)
          this.props.history.replace(newUrl)
        }
      })
    }
    this.ticking = false
    window.addEventListener('scroll', this.onScroll);

    this.living = true
    this.updateNavInPage()
    this.triggerMeasureWikiContainer();
  }

  componentDidUpdate(prevProps) {
    let { pathname, search } = this.props.location;
    const { pathname: prevPathname, search: prevSearch } = prevProps.location

    if (pathname !== prevPathname || search !== prevSearch) {
      if (pathname == "/wiki" || pathname == "/wiki/") {
        pathname = "/wiki/Index.md"
      }
      let version = '', lang = 'zh-cn'
      var path = extractPath(pathname + search, (_) => {
        this.props.setLang(_)
        lang = _
      }, (_) => {
        this.props.setDocVersion(_)
        version = _
      })
      if (path === null) {
        this.goto404()
        return
      }
      // 1. stop subscribing to scroll event, this may cause conflicted navInPage update
      window.removeEventListener('scroll', this.onScroll)
      this.scrollTimeoutHandle && clearTimeout(this.scrollTimeoutHandle)
      // 2. get new wiki and this call will trigger a new sync update
      const { getWikiMarkdownByPath, getGithubPageUrl, getGithubIssueUrl, getUuidByPath,
        getWikiCommentCount } = this.props
      if (!containUuidValue(window.location.href)) {
        getUuidByPath(path, lang, version).then((uuid) => {
          if (!!uuid) {
            let newUrl = rebuildUrlWithUuid(pathname, search, uuid)
            this.props.history.replace(newUrl);
          }
        })
      }
      getWikiMarkdownByPath(path, version, lang).then(() => {
        // 3. markdown is updated, now let's continue subscribe to scroll
        if (this.living) {
          this.ticking = false
          window.addEventListener('scroll', this.onScroll);
        }
        getWikiCommentCount().then((c) => {
          this.setState({
            commentCount: c
          })
        })
      }).catch(() => {
        this.goto404()
      })
      getGithubPageUrl(path).then((githubPageUrl) => {
        if (!!githubPageUrl) {
          this.setState({
            githubPageUrl
          })
        }
      })
      getGithubIssueUrl(path).then((githubIssueUrl) => {
        if (!!githubIssueUrl) {
          this.setState({
            githubIssueUrl
          })
        }
      })

      //here we update breadscrumbs
      const { setWikiBreadscrumbsByPath } = this.props
      setWikiBreadscrumbsByPath(path, lang)
    }
    const { markdown } = this.props
    const { markdown: prevMarkdown } = prevProps
    if (markdown !== prevMarkdown) {
      this.updateNavInPage()
      this.triggerMeasureWikiContainer();
    }
    document.title = this.getTitle()
  }

  getTitle() {
    let title = "云扩学院"
    let items = document.getElementsByClassName("breadscrub-item")
    let names = []
    for (let i = items.length - 1; i > -1; i--) {
      if (i == 0) {
        names.push('云扩学院')
      } else {
        names.push(items[i].textContent)
      }
    }
    return names.join("_")
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
    this.scrollTimeoutHandle && clearTimeout(this.scrollTimeoutHandle)
    this.living = false
  }

  triggerMeasureWikiContainer() {
    const event = new CustomEvent('measure-wiki-container', {})
    document.dispatchEvent(event)
  }

  ticking = false

  onScroll(e) {
    if (!this.ticking) {
      const { navInPage } = this.props
      if (!navInPage) {
        return
      }
      this.scrollTimeoutHandle = setTimeout(() => {
        const toBottom = document.body.clientHeight - window.innerHeight - 10 < window.pageYOffset
        // console.log(`toBottom:${toBottom}`)
        // everything based on this value
        const pageYOffset = window.pageYOffset;
        // 1. set active in page nav
        this.props.updateActiveNavInPage(pageYOffset)
        if (toBottom && navInPage && navInPage.length > 0) {
          const lastNav = navInPage[navInPage.length - 1].id
          this.props.updateActiveNavInPageForce(lastNav)
        }
        this.ticking = false;
      }, 100)
    }
    this.ticking = true
  }

  updateNavInPage() {
    // delay because of potential layout change in initial rendering
    // ssr render layout based on user-agent may partially solve this problem
    // this is a quick fix
    const currentPath = window.location.pathname
    setTimeout(() => {
      if (window.location.pathname !== currentPath) {
        return
      }
      const el = this.articleRef.current;
      const childEls = [...el.querySelectorAll("[id]")]
      let children = childEls.map(c => ({ name: c.innerText, id: c.id, top: c.offsetTop, nodeName: c.nodeName }))
      children.sort((a, b) => a.top - b.top);
      if (children.length > 1 && children[1]) {
        //隐藏了文章标题，所以初始化时[1]为激活状态
        children[1].active = true
      }
      this.props.setNavInPage(children)
    }, 500)
  }


  renderNavInPageList() {
    const { navInPage, wikiTop } = this.props

    const getNavClass = (nav) => {
      let className = 'nav_title_1'
      const nodeName = nav.nodeName.toUpperCase()
      if (nodeName.indexOf('H') === 0) {
        switch (nodeName) {
          case 'H1':
          case 'H2':
            className = 'nav_title_1'
            break
          default:
            className = 'nav_title_2'
            break
        }
        if (nav.active) {
          className += ' active'
        }
      }
      return className
    }

    if (!navInPage || !wikiTop) {
      return null
    }
    return Array.from(navInPage, (item, key) => (
      key != 0 ? (
        <li className={getNavClass(item)} key={item.id}
          onClick={() => {
            if (item.active) {
              return
            }
            const targetTop = document.getElementById(item.id).offsetTop + wikiTop
            window.scrollTo({
              top: targetTop,
              behavior: 'smooth'
            })
          }}>{item.name}</li>) : null
    ))
  }

  showImg = (e) => {
    if (e.target.getAttribute('contenteditable')) {
      return;
    }
    if (e.target.nodeName === 'IMG') {
      this.setState({
        showBigImg: true,
        bigImgSrc: e.target.src
      })
    }
  }

  hideImg = () => {
    this.setState({
      showBigImg: false,
      bigImgSrc: ''
    })
  }

  addTimestamp(html) {
    return html? html.replace('</h1>', `<br><span class="timestamp">更新时间：${this.props.lastUpdateTime}</span></h1>`): html
  }

  render() {
    const { markdown, winWidth, breadscrumbs, t, wikiId, adminLoggedIn, labels } = this.props;
    const { githubPageUrl, githubIssueUrl, commentCount } = this.state

    let wikiRightEl = null, wikiRightReplaceEl = null
    if (!winWidth || winWidth <= 1199) {
      wikiRightReplaceEl = (
        <div className="wiki-right-replace">
          <h4>{t('wiki.inthisarticle')}</h4>
          <ol>
            {this.renderNavInPageList()}
          </ol>
        </div>
      )
    } else {
      wikiRightEl = (
        <div className="wiki-layout wiki-right">
          <StickyWikiSideBar float='right' labels={labels}>
            {/* <div className="wiki-tool">
              <button className="pure-button pure-button-primary wiki-tool-btn"><i className="fa fa-download wiki-btn-dec" aria-hidden="true"></i>文档下载</button>
              <button className="pure-button pure-button-primary wiki-tool-btn"><i className="fa fa-star wiki-btn-dec" aria-hidden="true"></i>收藏</button>
            </div> */}
            <h4>{t('wiki.inthisarticle')}</h4>
            <ol>
              {this.renderNavInPageList()}
            </ol>
          </StickyWikiSideBar>
        </div>
      )
    }

    const { pathname, search } = this.props.location;
    const showComment = !pathname.endsWith('Index.md')

    return (
      <Fragment>
        <div className="content-area">
          <Breadscrub items={breadscrumbs} />
          <div className="wiki-container">
            <Toc />
            <div className="wiki-layout wiki-main" onClick={e => this.showImg(e)}>
              {wikiRightReplaceEl}
              <div className="mde-preview">
                <div className="mde-preview-content">
                  <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(markdown) }} ref={this.articleRef} className="article-holder"></div>
                </div>
              </div>
              {/* <WikiComment wikiId={wikiId} triggerMeasureWikiContainer={this.triggerMeasureWikiContainer}/> */}
              {/* {showComment ? <div className="wiki-comment-area comment-area">
                <div className="comment-toolbar">
                  <a href={githubPageUrl} target="_blank" className="my-btn"><span className="icon-edit"></span>{t('wiki.editdoc')}</a>
                  <a href={githubIssueUrl} target="_blank" className="my-btn"><span className="icon-comment"></span>{t('wiki.feedback')}</a>
                  {adminLoggedIn ? <a href={`${console_url}/#/user/comment/doc/${wikiId}`} target="_blank" className="my-btn">管理员评论({commentCount})</a> : null}
                </div>
              </div> : adminLoggedIn ? <div className="wiki-comment-area comment-area">
                <div className="comment-toolbar">
                  <a href={`${console_url}/#/user/comment/doc/${wikiId}`} target="_blank" className="my-btn">管理员评论({commentCount})</a>
                </div>
              </div> : null} */}
            </div>
            <ImgModal showModal={this.state.showBigImg} modalTitle="图片预览" onHandleClose={this.hideImg} src={this.state.bigImgSrc} />
            {wikiRightEl}
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  let { markdown, navInPage, wikiId, labels, lastUpdateTime } = state.wiki
  const { width: winWidth, wikiTop } = state.winsize
  const { locale: lang, version } = state.lang
  const { adminLoggedIn } = state.global
  return {
    markdown,
    winWidth,
    navInPage,
    wikiTop,
    breadscrumbs: state.widgets.breadscrumbs,
    wikiId,
    version,
    lang,
    adminLoggedIn,
    labels,
    lastUpdateTime
  }
}
const mapDispatchToProps = dispatch => ({
  updateWiki: (version, lang) => dispatch(updateWiki(version, lang)),
  initializeWikiTreeOpenKeysByPath: (path, lang) => dispatch(initializeWikiTreeOpenKeysByPath(path, lang)),
  getWikiMarkdownByPath: (path, version, lang) => dispatch(getWikiMarkdownByPath(path, version, lang)),
  setNavInPage: navInPage => dispatch(setNavInPage(navInPage)),
  updateActiveNavInPage: pageYOffset => dispatch(updateActiveNavInPage(pageYOffset)),
  updateActiveNavInPageForce: id => dispatch(updateActiveNavInPageForce(id)),
  setWikiBreadscrumbsByPath: (path, lang) => dispatch(setWikiBreadscrumbsByPath(path, lang)),
  setLang: lang => dispatch(setLang(lang)),
  setDocVersion: v => dispatch(setDocVersion(v)),
  getGithubPageUrl: path => dispatch(getGithubPageUrl(path)),
  getGithubIssueUrl: path => dispatch(getGithubIssueUrl(path)),
  getUuidByPath: (path, lang, version) => dispatch(getUuidByPath(path, lang, version)),
  getWikiCommentCount: () => dispatch(getWikiCommentCount()),
  getRelatedUnit: p => dispatch(getRelatedUnit(p))
})

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Wiki)))

function extractPath(pathname, setLang, setDocVersion) {
  const mat = /^(\/(?<lang>(zh-cn|en-us|ja-jp)))?\/(wiki)\/(?<path>[^\?]+)(\?(?<q>[^?]+))?/.exec(pathname);
  if (!mat) {
    return null
  }
  const path = mat.groups.path;
  const lang = mat.groups.lang;
  const q = mat.groups.q;
  let version = getVersionFromQuery(q)
  if (lang) {
    setLang(lang)
  }
  if (version) {
    setDocVersion(version)
  }
  return path

  function getVersionFromQuery(q) {
    if (!q) {
      return null
    }
    const splitParts = q.split('&')
    let qJson = {}
    for (const part of splitParts) {
      const items = part.split('=')
      if (items.length !== 2) {
        continue
      }
      qJson[items[0]] = items[1]
    }
    return qJson["_v"]
  }
}

function rebuildUrlWithUuid(pathname = '', search, uuid = '') {
  let paramUuid = `uuid=${uuid}`
  if (search) {
    return pathname + search + '&' + paramUuid
  } else {
    return pathname + '?' + paramUuid
  }
}

function containUuidValue(url = '') {
  let reg = /(uuid=+[^&]+)/g
  return reg.test(url);
}


