import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next';
import { getRelatedPost } from '../actions'

class RelatedPost extends React.PureComponent {

  constructor(props) {
    super(props)
    this._getRelatedPost = this._getRelatedPost.bind(this)
  }
  state = {
    posts: [],
    extUrlSuffix: ''
  }

  _getRelatedPost() {
    const { posts } = this.state
    const { getRelatedPost, labels } = this.props;
    getRelatedPost(labels).then((data) => {
      if (posts.toString() != data.toString()) {
        this.setState({
          posts: data
        })
      }
      // console.log(`_getRelatedPost data ${JSON.stringify(data)}`)
    }).catch(() => {
      console.log(`_getRelatedPost error`)
    })
  }

  componentDidMount() {
    const entry_url = sessionStorage.getItem('entry_url')
    this.setState({
      extUrlSuffix: `?entry_url=${encodeURIComponent(entry_url)}`
    })
  }
  componentDidUpdate() {
    this._getRelatedPost()
  }

  renderPosts() {
    const { posts } = this.state
    return Array.from(posts, (post, key) => (
      <li key={post.tid} className="related-li"><a className="related_a" href={`https://community.encoo.com/thread/${post.tid}${this.state.extUrlSuffix}`}>{`${post.subject}`}</a></li>
    ))
  }

  render() {
    const { t } = this.props;
    return (
      <div className="related-post">
        <h4>{t('wiki.relatedpost')}</h4>
        <ol>{this.renderPosts()}</ol>
        <div className="forum-post">
          {t('wiki.goto_forum_and_discuss_1')}<a href={`https://community.encoo.com/forum/${this.state.extUrlSuffix}`}>{t('wiki.goto_forum_and_discuss_2')}</a>{t('wiki.goto_forum_and_discuss_3')}
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  getRelatedPost: p => dispatch(getRelatedPost(p)),
})

export default withRouter(withTranslation()(connect(null, mapDispatchToProps)(RelatedPost)))