import React from 'react'

import iconTime from '../assets/icon-learning-path-time.png';
import iconCourse from '../assets/icon-learning-path-course.png';

const Collapse = ({ cusCls = "", title, renderData }) => {
  let classes = ["collapse"];
  if (cusCls !== "") {
    classes.push(cusCls)
  }

  function onClickLearn(linkUrl) {
    if (!linkUrl) {
      return
    }
    window.open(linkUrl, '_blank')
  }

  return (
    <div className={classes.join(' ')}>
      <div className="collapse__head">
        <p className="lp-title">{title}</p>
      </div>

      {Array.from(renderData, (item, idx) => {
        return (
          <div className="collapse__item" key={`${item.courseId}_${idx}`}>
            <img className="img" src={item.imgUrl} draggable="false" />
            <div className="course-content">
              <p className="course-title">{item.courseName}</p>
              <p className="course-desc">{item.courseDescription}</p>
              <div className="course-other">
                <div className="course-other__item">
                  <img className="course-other__icon" src={iconCourse} draggable="false" />
                  <span className="course-other__desc">课程时间{item.durationDesc}</span>
                </div>
                <div className="course-other__item">
                  <img className="course-other__icon" src={iconTime} draggable="false" />
                  <span className="course-other__desc">学习时间{item.learnTimeDesc}</span>
                </div>
              </div>
              <button className="course-submit" onClick={() => onClickLearn(item.linkUrl)}>开始学习</button>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Collapse