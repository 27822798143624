import { ConstValues } from '../actions'
import update from 'immutability-helper'

const initialData = {
  unitDetail: {},
}

export default (state = initialData, action) => {
  switch (action.type) {
    case ConstValues.SET_UNIT_DETAIL:
      return update(state, { unitDetail: { $set: action.data } })
    case ConstValues.COMBINING_ANSWERS:
      
      return update(state, { questionList: { $set: action.data } })

    default:
      return state
  }
}