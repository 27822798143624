import React, {Fragment} from 'react'
import {connect} from 'react-redux'
import {updateWiki, setWikiCoverBreadscrumbs} from '../actions'
import Toc from '../components/Toc'
import IconEditor from '../assets/icon-editor.png'
import IconRobot from '../assets/icon-robot.png'
import IconConsole from '../assets/icon-console.png'
import IconComp from '../assets/icon-comp.png'
import IconQsIntro from '../assets/icon-qs-intro.png'
import IconQsInstall from '../assets/icon-qs-install.png'
import IconQsAuto from '../assets/icon-qs-auto.png'
import IconQsWf from '../assets/icon-qs-wf.png'
import IconQsComp from '../assets/icon-qs-comp.png'
import IconQsUI from '../assets/icon-qs-ui.png'
import CCDevOverview from '../assets/cc-dev-overview.png'
import CCDevBeginer from '../assets/cc-dev-beginer.png'
import CCDevJun from '../assets/cc-dev-jun.png'
import CCDevSenior from '../assets/cc-dev-senior.png'
import LogoMarket from '../assets/logo-market.png'
import LogoPlatform from '../assets/logo-platform.png'
import LogoConsole from '../assets/logo-console.png'

import Breadscrub from '../components/Breadscrub'
import { Trans, withTranslation } from 'react-i18next';

class WikiCover extends React.Component {

  componentDidMount() {
    const {updateWiki, setWikiCoverBreadscrumbs} = this.props
    setWikiCoverBreadscrumbs()
    updateWiki().then(() => {
      this.triggerMeasureWikiContainer();
    })
    this.triggerMeasureWikiContainer();
  }

  triggerMeasureWikiContainer() {
    const event = new CustomEvent('measure-wiki-container', {})
    document.dispatchEvent(event)
  }

  render() {
    const {breadscrumbs, t} = this.props;
    const count1 = 3864, count2 = 5762, count3 = 3412, count4 = 1875
    return (
      <Fragment>
        <div className="content-area">
          <Breadscrub items={breadscrumbs}/>
          <div className="wiki-container">
            <Toc />
            <div className="wiki-layout wiki-main-cover">
              <h1><Trans i18nKey="wikic.title1">面向天匠RPA开发者的产品文档</Trans></h1>
              <p className="subtitle"><Trans i18nKey="wikic.title2">使用以下资源快速查找面向开发者的产品文档</Trans></p>
              <div className="wiki-doc-block-container">
                <div className="wiki-doc-block-wrapper">
                  <div className="wiki-doc-block">
                    <img alt={t('wikic.editor')} src={IconEditor} width="58" height="58"/>
                    <div className="wiki-doc-block-text">
                      <h4 className="title"><Trans i18nKey="wikic.editor">编辑器</Trans></h4>
                      <span><Trans i18nKey="wikic.watching" count={count1}>{{count1}}人在看</Trans></span>
                    </div>
                  </div>
                </div>
                <div className="wiki-doc-block-wrapper">
                  <div className="wiki-doc-block">
                    <img alt="组件" src={IconComp} width="58" height="58"/>
                    <div className="wiki-doc-block-text">
                      <h4 className="title">组件</h4>
                      <span><Trans i18nKey="wikic.watching" count={count2}>{{count2}}人在看</Trans></span>
                    </div>
                  </div>
                </div>
                <div className="wiki-doc-block-wrapper">
                  <div className="wiki-doc-block">
                    <img alt="控制台" src={IconConsole} width="58" height="58"/>
                    <div className="wiki-doc-block-text">
                      <h4 className="title">控制台</h4>
                      <span><Trans i18nKey="wikic.watching" count={count3}>{{count3}}人在看</Trans></span>
                    </div>
                  </div>
                </div>
                <div className="wiki-doc-block-wrapper">
                  <div className="wiki-doc-block">
                    <img alt="机器人" src={IconRobot} width="58" height="58"/>
                    <div className="wiki-doc-block-text">
                      <h4 className="title">机器人</h4>
                      <span><Trans i18nKey="wikic.watching" count={count4}>{{count4}}人在看</Trans></span>
                    </div>
                  </div>
                </div>
              </div>
              <h1><Trans i18nKey="wikic.title3">2分钟快速入门</Trans></h1>
              <p><Trans i18nKey="wikic.title4">安装天匠编辑器、使用录制组件来完成自动化项目创建</Trans></p>
              <div className="wiki-quick-start-container">
                <div className="wiki-quick-start-block-wrapper">
                  <div className="wiki-quick-start-block intro">
                    <img alt={t('wikic.qs_intro')} src={IconQsIntro} width="34" height="36"/>
                    <div className="wiki-quick-start-block-text">
                      <h4><Trans i18nKey="wikic.qs_intro">产品介绍</Trans></h4>
                      <span><Trans i18nKey="wikic.qs_intro_desc">天匠编辑器、控制台、机器人</Trans></span>
                    </div>
                  </div>
                </div>
                <div className="wiki-quick-start-block-wrapper">
                  <div className="wiki-quick-start-block ui">
                    <img alt={t('wikic.qs_ui')} src={IconQsUI} width="36" height="34"/>
                    <div className="wiki-quick-start-block-text">
                      <h4><Trans i18nKey="wikic.qs_ui">用户界面</Trans></h4>
                      <span><Trans i18nKey="wikic.qs_ui_desc">登录天匠智能控制台，选择BotTimeStudio安装包</Trans></span>
                    </div>
                  </div>
                </div>
                <div className="wiki-quick-start-block-wrapper">
                  <div className="wiki-quick-start-block installer">
                    <img alt={t('wikic.qs_install')} src={IconQsInstall} width="33" />
                    <div className="wiki-quick-start-block-text">
                      <h4><Trans i18nKey="wikic.qs_install">安装编辑器</Trans></h4>
                      <span><Trans i18nKey="wikic.qs_install_desc">由工具栏、工具窗口以及设计窗口三个区域组成</Trans></span>
                    </div>
                  </div>
                </div>
                <div className="wiki-quick-start-block-wrapper">
                  <div className="wiki-quick-start-block comp">
                    <img alt={t('wikic.qs_comp_market')} src={IconQsComp} width="31" height="36" />
                    <div className="wiki-quick-start-block-text">
                      <h4><Trans i18nKey="wikic.qs_comp_market">天匠组件市场</Trans></h4>
                      <span><Trans i18nKey="wikic.qs_comp_market_desc">使用的工作流程类型为流程图和序列</Trans></span>
                    </div>
                  </div>
                </div>
                <div className="wiki-quick-start-block-wrapper">
                  <div className="wiki-quick-start-block wf">
                    <img alt={t('wikic.qs_flow_type')} src={IconQsWf} width="34" height="34" />
                    <div className="wiki-quick-start-block-text">
                      <h4><Trans i18nKey="wikic.qs_flow_type">工作流程类型</Trans></h4>
                      <span><Trans i18nKey="wikic.qs_flow_type_desc">可对依赖项进行添加、更新和删除</Trans></span>
                    </div>
                  </div>
                </div>
                <div className="wiki-quick-start-block-wrapper">
                  <div className="wiki-quick-start-block auto">
                    <img alt={t('wikic.qs_auto')} src={IconQsAuto} width="32" height="37" />
                    <div className="wiki-quick-start-block-text">
                      <h4><Trans i18nKey="wikic.qs_auto">创建自动化项目</Trans></h4>
                      <span><Trans i18nKey="wikic.qs_auto_desc">使用录制组件或拖拽其他组件来完成创建自动化项目</Trans></span>
                    </div>
                  </div>
                </div>
              </div>
              <h1><Trans i18nKey="wikic.title5">热门课程</Trans></h1>
              <div className="wiki-hot-courses-container">
                <div className="wiki-hot-courses-wrapper">
                  <a className="wiki-hot-courses-block">
                    <img alt={t('wikic.cc_overview')} src={CCDevOverview}/>
                    <div className="txt-block">
                      <h2><Trans i18nKey="wikic.cc_overview">RPA概览</Trans></h2>
                      <h4><Trans i18nKey="wikic.cc_overview_desc">开启RPA之旅</Trans></h4>
                    </div>
                  </a>
                </div>
                <div className="wiki-hot-courses-wrapper">
                  <a className="wiki-hot-courses-block">
                    <img alt={t('wikic.cc_dev_beginner')} src={CCDevBeginer} />
                    <div className="txt-block">
                      <h2><Trans i18nKey="wikic.cc_dev_beginner">RPA开发者基础</Trans></h2>
                      <h4><Trans i18nKey="wikic.cc_dev_beginner_desc">零基础迈向RPA开发者</Trans></h4>
                    </div>
                  </a>
                </div>
                <div className="wiki-hot-courses-wrapper">
                  <a className="wiki-hot-courses-block">
                    <img alt={t('wikic.cc_dev_jun')} src={CCDevJun} />
                    <div className="txt-block">
                      <h2><Trans i18nKey="wikic.cc_dev_jun">RPA开发者中级</Trans></h2>
                      <h4><Trans i18nKey="wikic.cc_dev_jun_desc">成为RPA开发专家</Trans></h4>
                    </div>
                  </a>
                </div>
                <div className="wiki-hot-courses-wrapper">
                  <a className="wiki-hot-courses-block">
                    <img alt={t('wikic.cc_dev_sen')} src={CCDevSenior} />
                    <div className="txt-block">
                      <h2><Trans i18nKey="wikic.cc_dev_sen">RPA开发者高级</Trans></h2>
                      <h4><Trans i18nKey="wikic.cc_dev_sen_desc">进阶掌控全场的RPA开发大师</Trans></h4>
                    </div>
                  </a>
                </div>
              </div>
              <h1><Trans i18nKey="wikic.title6">天匠频道</Trans></h1>
              <div className="wiki-channel-container">
                <div className="wiki-channel-wrapper">
                  <a className="wiki-channel-block">
                    <img alt={t('wikic.channel_market')} src={LogoMarket} />
                    <div className="txt-block">
                      <h4><Trans i18nKey="wikic.channel_market">天匠市场</Trans></h4>
                      <span><Trans i18nKey="wikic.channel_market_desc">由云扩科技及合作伙伴共同开发的自动化组件可在编...</Trans></span>
                    </div>
                  </a>
                </div>
                <div className="wiki-channel-wrapper">
                  <a className="wiki-channel-block">
                    <img alt={t('wikic.channel_platform')} src={LogoPlatform} />
                    <div className="txt-block">
                      <h4><Trans i18nKey="wikic.channel_platform">天匠开放平台</Trans></h4>
                      <span><Trans i18nKey="wikic.channel_platform_desc">完善的产品文档、培训课程、开发者社区及丰富线下活动</Trans></span>
                    </div>
                  </a>
                </div>
                <div className="wiki-channel-wrapper">
                  <a className="wiki-channel-block">
                    <img alt={t('wikic.channel_console')} src={LogoConsole} />
                    <div className="txt-block">
                      <h4><Trans i18nKey="wikic.channel_console">天匠控制台</Trans></h4>
                      <span><Trans i18nKey="wikic.channel_console_desc">企业RPA系统的中央控制中心，提拱机器人和流程的...</Trans></span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  breadscrumbs: state.widgets.breadscrumbs,
  lang: state.lang.locale
})

const mapDispatchToProps = dispatch => ({
  updateWiki: () => dispatch(updateWiki()),
  setWikiCoverBreadscrumbs: () => dispatch(setWikiCoverBreadscrumbs())
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(WikiCover))