import {ConstValues} from '../actions'
import update from 'immutability-helper'

const initialData = {
  mobileConfirmWord: '',
  generic: {
    isLoading: false,
    docs: [],
    docsHasMore: false,
    learns: [],
    learnsHasMore: false,
  },
  docs: {
    isLoading: false,
    start: 0,
    list: [],
    count: 0
  },
  learns: {
    isLoading: false,
    start: 0,
    list: [],
    count: 0
  }
}

export default (state = initialData, action) => {
  switch(action.type) {
    case ConstValues.SET_MOBILE_CONFIRM_WORD:
      return update(state, {
        mobileConfirmWord: {$set: action.word}
      })
    case ConstValues.RESET_SEARCH:
    {
      if (action.hard) {
        return initialData
      } else {
        return {...initialData, mobileConfirmWord: state.mobileConfirmWord}
      }
    }
    case ConstValues.START_FUZZY_SEARCH_GENERIC:
      return update(state, {
        generic: {$set: {...initialData.generic, isLoading: true}}
      })
    case ConstValues.FINISH_FUZZY_SEARCH_GENERIC:
      return update(state, {
        generic: {
          isLoading: {$set: false},
          docs: {$set: action.docs},
          docsHasMore: {$set: action.docsCount > action.docs.length},
          learns: {$set: action.learns},
          learnsHasMore: {$set: action.learnsCount > action.learns.length}
        }
      })
    case ConstValues.START_SEARCH_DOCS:
      return update(state, {
        docs: {
          isLoading: {$set: true},
          start: {$set: action.start},
        }
      })
    case ConstValues.FINISH_SEARCH_DOCS:
      return update(state, {
        docs: {
          isLoading: {$set: false},
          list: {$push: action.list},
          count: {$set: action.count}
        }
      })
    case ConstValues.START_SEARCH_LEARN:
      return update(state, {
        learns: {
          isLoading: {$set: true},
          start: {$set: action.start}
        }
      })
    case ConstValues.FINISH_SEARCH_LEARN:
      return update(state, {
        learns: {
          isLoading: {$set: false},
          list: {$push: action.list},
          count: {$set: action.count}
        }
      })
    default:
      return state
  }
}