import "@babel/polyfill";
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import React from 'react';
import { hydrate } from 'react-dom';
//store
import rootReducer from './reducers'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
//ScrollToTop
import ScrollToTop from './components/ScrollToTop'
import InitializeData from './components/InitializeData'
import './i18n'
import "./tools/telemetry"
import {testLoggedIn} from './actions'

(function () {

  if ( typeof window.CustomEvent === "function" ) return false;

  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: null };
    var evt = document.createEvent( 'CustomEvent' );
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
    return evt;
   }

  window.CustomEvent = CustomEvent;
})();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const preloadedState = window.__PRELOADED_STATE__
delete window.__PRELOADED_STATE__

const store = createStore(
  rootReducer, preloadedState,
  composeEnhancers(applyMiddleware(
    thunkMiddleware // lets us dispatch() functions
  ))
)

function thenFunc() {
  hydrate(
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop/>
        <InitializeData />
        <App />
      </BrowserRouter>
    </Provider>,
    document.getElementById('root')
  );
  
  if (module.hot) {
    module.hot.accept();
  }
}

store.dispatch(testLoggedIn()).then(() => {
  thenFunc()
}).catch(() => {
  thenFunc()
})


