import React from "react";
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
  testLoggedIn, getLearnCourseDetail, likeLearnCourse, openLearnCourse,
  getLearnCommentCount, getLearnCrumbsByUnitId, getLearnCrumbsByCourseId
} from '../../actions';
import ContentLoader from "react-content-loader";
import Breadscrub from '../../components/Breadscrub';
import CourseCategory from '../../components/CourseCategory';
import { toast } from 'react-toastify';

import iconCollect from '../../assets/icon-collect.png';
import iconUnCollect from '../../assets/icon-nocollect.png';

import appInsights, { setGlobalParam, clearGlobalParams, getGlobalParams } from '../../tools/telemetry'

import { runtimeConfig } from '../../config'

const { console_url, login_url } = runtimeConfig

class LearnCourseDetail extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    learnCourseData: null,
    commentCount: 0
  }

  componentDidMount() {
    const { courseId } = this.props.match.params;
    const search = this.props.location.search;
    const { getLearnCourseDetail, openLearnCourse, getLearnCommentCount, getLearnCrumbsByUnitId, getLearnCrumbsByCourseId, courseBreadsCrumbs } = this.props;
    let isOpen = true;
    if (search && search.startsWith('?isOpen=')) {
      isOpen = false;
    }
    window.history.replaceState(null, null, `/learn/course-detail/${courseId}`);

    const _getLearnCourseDetail = () => {
      getLearnCourseDetail(courseId).then(data => {
        setGlobalParam("Url", window.location.href)
        setGlobalParam('PageName', 'Course')
        setGlobalParam("CourseID", `${data.id}`)
        setGlobalParam("CourseName", data.name)
        appInsights.trackEvent({ name: 'Academy_VisitedPageEvent' }, getGlobalParams())
        this.StartVisitTime = new Date()
        this.setState({
          learnCourseData: data
        });
      });
    }

    if (isOpen) {
      openLearnCourse(courseId).then(_getLearnCourseDetail);
    } else {
      _getLearnCourseDetail()
    }

    getLearnCommentCount(courseId).then(c => {
      this.setState({
        commentCount: c
      })
    })

    getLearnCrumbsByCourseId(courseId).then(data => {
      let names = new Array()
      if (data.courseName) {
        names.push(data.courseName)
      }
      if (data.pathName) {
        names.push(data.pathName)
      }
      names.push("RPA课程")
      names.push("云扩学院")
      document.title = names.join("_")
    })

    let front = this.refs.progressFront;
    if (front) {
      let fWidth = Math.round(learn / total * 100);
      front.style.width = fWidth + "%";
    }
  }

  componentWillUnmount() {
    const LeavePageTime = new Date()
    const StadyTime = LeavePageTime - this.StartVisitTime
    appInsights.trackEvent({ name: 'Academy_StadyTimeEvent' }, { StartVisitTime: this.StartVisitTime, LeavePageTime, StadyTime, ...getGlobalParams() })
    clearGlobalParams()
  }

  async testLoggedInThen() {
    const { testLoggedIn, history } = this.props;

    try {
      await testLoggedIn();
      this.toggleLikeCourse();
    } catch (err) {
      if (err.message === '用户未登录') {
        const returnUrl = window.encodeURIComponent(window.location.pathname)
        window.location = `${login_url}?returnUrl=${returnUrl}`
      } else if (err.message === '未知错误') {
        toast('未知错误');
      }
    }
  }

  toggleLikeCourse() {
    const { courseId } = this.props.match.params;
    const { likeLearnCourse } = this.props;
    const { learnCourseData } = this.state;

    learnCourseData.favorite = !this.state.learnCourseData.favorite
    appInsights.trackEvent({ name: 'Academy_UserActionEvent' }, { ButtonOrMenuName: 'Favorite', Check: learnCourseData.favorite, ...getGlobalParams() })
    likeLearnCourse(courseId).then(() => {
      this.setState({
        learnCourseData
      })
    });
  }

  render() {
    const { learnCourseData, commentCount } = this.state;
    const { courseId } = this.props.match.params;
    const { adminLoggedIn, courseBreadsCrumbs } = this.props
    let crumbItems = [{ name: '首页', url: '/', trans: true }, { name: '课程', url: '/learn', trans: true }]
    if (courseBreadsCrumbs != null) {
      if (courseBreadsCrumbs.pathName) {
        crumbItems.push({ name: courseBreadsCrumbs.pathName, url: `/learn/path-detail/${courseBreadsCrumbs.pathId}`, trans: true })
      }
      crumbItems.push({ name: courseBreadsCrumbs.courseName, url: `/learn/course-detail/${courseBreadsCrumbs.courseId}`, trans: false })
    }

    return (
      <div style={{ backgroundColor: '#F5F6F7' }}>
        {
          !!learnCourseData ?
            (
              <div className="learn-course">
                <Breadscrub items={crumbItems} />
                <div className="course-container">
                  <div className="course-thumb-wrap">
                    <img className="course-thumb" src={learnCourseData.iconUrl} alt="" draggable="false" />
                  </div>

                  <article className="course-info-wrap">
                    <img className="icon-collect" src={learnCourseData.favorite ? iconCollect : iconUnCollect} onClick={e => this.testLoggedInThen(e)} draggable="false" />
                    <h3 className="course-title">{learnCourseData.name}</h3>
                    <ul className="course-subtitle-wrap">
                      <li className="course-subtitle-item">
                        <span className="title">课程定位：</span>
                        {learnCourseData.target}
                      </li>
                      <li className="course-subtitle-item">
                        <span className="title">受众：</span>
                        {learnCourseData.audience}
                      </li>
                      <li className="course-subtitle-item">
                        <span className="title">课程时间：</span>
                        {learnCourseData.timeDesc}
                      </li>
                    </ul>
                    {
                      (learnCourseData.progress !== null && learnCourseData.childCount > 0) ?
                        (
                          <div className="course-progress">
                            <div className="progress">
                              <p className="progress-info">
                                已学习 {learnCourseData.progress} 单元，共 {learnCourseData.childCount} 单元
                              </p>
                              <div className="progress-back">
                                <div className="progress-front" style={{ width: (Math.round(learnCourseData.progress / learnCourseData.childCount * 100)) + '%' }}></div>
                              </div>
                            </div>
                          </div>
                        ) :
                        null
                    }
                    <ul className="course-simple-wrap">
                      {
                        Array.from(learnCourseData.roles, (role, idx) => (
                          <li key={idx} className="course-simple-item">
                            {role}
                          </li>
                        ))
                      }
                      <li className="course-simple-item">
                        {learnCourseData.level}
                      </li>
                    </ul>
                    <p className="course-desc">
                      {learnCourseData.description}
                    </p>
                    {/* <button className="pure-button pure-button-primary course-learn">{learnCourseData.progress ? "继续学习" : "开始学习"}</button> */}
                    <CourseCategory collect={true} title="课程目录" dataSource={learnCourseData.children} />
                  </article>
                </div>
                {/* {adminLoggedIn ? <div className="learn-comment-toolbar">
                  <a href={`${console_url}/#/user/comment/learn/${courseId}`} target="_blank" className="my-btn">管理员评论({commentCount})</a>
                </div> : null} */}
              </div>
            ) :
            (
              <div className="learn-course">
                <div className="course-container" style={{ display: 'block', height: '489px' }}>
                  <ContentLoader viewBox="0 0 960 399">
                    <rect x="106" y="0" width="73" height="73" />
                    <rect x="238" y="0" width="150" height="40" />
                    <rect x="238" y="55" width="320" height="16" />
                    <rect x="238" y="85" width="123" height="16" />
                    <rect x="238" y="105" width="200" height="4" />
                    <rect x="238" y="125" width="90" height="29" />
                    <rect x="341" y="125" width="48" height="29" />
                    <rect x="400" y="125" width="48" height="29" />
                    <rect x="460" y="125" width="48" height="29" />
                    <rect x="238" y="167" width="293" height="16" />
                    <rect x="238" y="213" width="88" height="43" />
                    <rect x="238" y="280" width="66" height="16" />
                    <rect x="238" y="312" width="300" height="18" />
                  </ContentLoader>
                </div>
              </div>
            )
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  adminLoggedIn: state.global.adminLoggedIn,
  courseBreadsCrumbs: state.learnCrumbs.courseBreadsCrumbs
})

const mapDispatchToProps = (dispatch) => ({
  testLoggedIn: () => dispatch(testLoggedIn()),
  getLearnCourseDetail: courseId => dispatch(getLearnCourseDetail(courseId)),
  openLearnCourse: courseId => dispatch(openLearnCourse(courseId)),
  likeLearnCourse: courseId => dispatch(likeLearnCourse(courseId)),
  getLearnCommentCount: learnId => dispatch(getLearnCommentCount(learnId)),
  getLearnCrumbsByUnitId: unitId => dispatch(getLearnCrumbsByUnitId(unitId)),
  getLearnCrumbsByCourseId: courseId => dispatch(getLearnCrumbsByCourseId(courseId))
})

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LearnCourseDetail)))
