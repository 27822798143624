import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter,Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next';
import { getRelatedUnit } from '../actions'

class RelatedUnit extends React.PureComponent {

  //todo 获取到的unit要存在本地state里
  constructor(props) {
    super(props)
    this._getRelatedUnit = this._getRelatedUnit.bind(this)
  }
  state = {
    units: [],
  }

  _getRelatedUnit() {
    const { units } = this.state
    const { getRelatedUnit, labels } = this.props;
    getRelatedUnit(labels).then((data) => {
      if (units.toString() != data.toString()) {
        this.setState({
          units: data
        })
      }
      // console.log(`_getRelatedUnit data ${JSON.stringify(data)}`)
    }).catch(() => {
      console.log(`_getRelatedUnit error`)
    })
  }

  componentDidMount() {
  }
  componentDidUpdate() {
    this._getRelatedUnit()
  }

  renderUnits() {
    const { units } = this.state
    return Array.from(units, (unit, key) => (
      <li key={unit.id} className="related-li"><a className="related_a" href={`/learn/unit-detail/${unit.id}`}>{`${unit.name}`}</a>
      </li>
    ))
  }

  render() {
    const { t } = this.props;
    return (
      <div className="related-unit">
        <h4>{t('wiki.relatedcourse')}</h4>
        <ol>{this.renderUnits()}</ol>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  getRelatedUnit: p => dispatch(getRelatedUnit(p)),
})

export default withRouter(withTranslation()(connect(null, mapDispatchToProps)(RelatedUnit)))