import { ConstValues } from '../actions'
import update from 'immutability-helper'

const initialData = {
  isRead: false,
  hasLogin: false,
  loginLoading: false,
  loginError: '',

  registerLoading: false,
  registerError: '',
  validNameError: '',
  validEmailError: '',
  validPhoneError: '',
  validCodeError: '',
  validPasswordError: '',
  validRePasswordError: '',
  validReadError: '',

  codeLoading: false
};

export default (state = initialData, action) => {
  switch (action.type) {
    case ConstValues.SET_LOGGED_IN:
      return update(state, { hasLogin: { $set: true } });
    case ConstValues.LOGIN_START:
      return update(state, { loginLoading: { $set: true }, hasLogin: { $set: false } });
    case ConstValues.LOGIN_SUCCESS:
      return update(state, { loginLoading: { $set: false }, hasLogin: { $set: true }, loginError: { $set: '' } });
    case ConstValues.LOGIN_FAILED:
      return update(state, { loginLoading: { $set: false }, hasLogin: { $set: false }, loginError: { $set: action.errorMessage } });
    case ConstValues.LOGIN_VALID_USERNAME:
      return update(state, { loginError: { $set: action.errorMessage } });
    case ConstValues.REGISTER_START:
      return update(state, { registerLoading: { $set: true } });
    case ConstValues.REGISTER_SUCCESS:
      return update(state, {
        registerLoading: { $set: false },
        registerError: { $set: '' },
        validNameError: { $set: '' },
        validEmailError: { $set: '' },
        validPhoneError: { $set: '' },
        validCodeError: { $set: '' },
        validPasswordError: { $set: '' },
        validReadError: { $set: '' }
      });
    case ConstValues.REGISTER_FAILED:
      return update(state, { registerLoading: { $set: false }, registerError: { $set: action.errorMessage } });
    case ConstValues.REGISTER_VALID_USERNAME_SUCCESS:
      return update(state, { validNameError: { $set: '' } });
    case ConstValues.REGISTER_VALID_USERNAME_FAILED:
      return update(state, { validNameError: { $set: action.errorMessage }, registerError: { $set: '' } });
    case ConstValues.REGISTER_VALID_EMAIL_SUCCESS:
      return update(state, { validEmailError: { $set: '' } });
    case ConstValues.REGISTER_VALID_EMAIL_FAILED:
      return update(state, { validEmailError: { $set: action.errorMessage }, registerError: { $set: '' } });
    case ConstValues.REGISTER_VALID_PHONE_SUCCESS:
      return update(state, { validPhoneError: { $set: '' } });
    case ConstValues.REGISTER_VALID_PHONE_FAILED:
      return update(state, { validPhoneError: { $set: action.errorMessage }, registerError: { $set: '' } });
    case ConstValues.REGISTER_VALID_CODE:
      return update(state, { validCodeError: { $set: action.errorMessage } });
    case ConstValues.REGISTER_VALID_PASSWORD:
      return update(state, { validPasswordError: { $set: action.errorMessage } });
    case ConstValues.REGISTER_VALID_REPASSWORD:
      return update(state, { validRePasswordError: { $set: action.errorMessage } });
    case ConstValues.REGISTER_VALID_READ:
      return update(state, { validReadError: { $set: action.errorMessage } });
    case ConstValues.CODE_START:
      return update(state, { codeLoading: { $set: true } });
    case ConstValues.CODE_SUCCESS:
      return update(state, { codeLoading: { $set: false } });
    case ConstValues.TOGGLE_PRIVACY:
      return update(state, { isRead: { $set: action.isRead } });
    default:
      return state;
  }
}