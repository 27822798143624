import React, { Fragment, version } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { toggleWikiFolder, setWikiPage, wikiSearch } from '../actions'
import StickyWikiSideBar from './StickyWikiSideBar'
import AutoComplete from './AutoComplete'
import { Debounce } from 'lodash-decorators';
import SelectDocLang from './SelectDocLang'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next';
import { runtimeConfig } from '../config';
const { prod_env } = runtimeConfig

class Toc extends React.Component {
  constructor(props) {
    super(props)
    this.onTocSearchTextChanged = this.onTocSearchTextChanged.bind(this);
    this.onTocSearchEnter = this.onTocSearchEnter.bind(this);
  }

  state = {
    tocVisible: false,
    wikiSearchResult: [],
    searchLoading: false,
    searchEnterPressed: false,
    pdfDownloadUrl: 'pdfDownloadUrl'
  }

  componentDidMount() {
    
  }

  componentDidUpdate(_, prevState) {
    const { searchLoading: prevSearchLoading, searchEnterPressed: prevSearchEnterPressed } = prevState
    const { searchLoading, searchEnterPressed, wikiSearchResult } = this.state
    if (searchEnterPressed && (prevSearchLoading && !searchLoading) ||
      !searchLoading && (!prevSearchEnterPressed && searchEnterPressed)) {
      this.setState({
        searchEnterPressed: false
      })
      if (wikiSearchResult && wikiSearchResult.length > 0) {
        window.location = `/wiki/${wikiSearchResult[0].fullPath}`
      } else {
        window.location = '/wiki/404.md'
      }
    }
  }

  @Debounce(200)
  onTocSearchTextChanged(newText) {
    if (!newText) {
      this.setState({
        wikiSearchResult: [],
        searchLoading: true
      })
      return
    }
    // 技术债：如果then之后已经unmount了，这个就会报错，这个就是网络超卡的时候；
    // 这里改用reducer而不是state就能解决这个问题，就是麻烦点
    const currentPath = window.location.pathname
    this.props.wikiSearch(newText, this.props.version, this.props.lang).then(list => {
      if (window.location.pathname !== currentPath) {
        return
      }
      this.setState({
        wikiSearchResult: list,
        searchLoading: false
      })
    })
  }

  onTocSearchEnter() {
    this.setState({
      searchEnterPressed: true
    })
  }

  renderTableOfContent(items, openKeys) {
    const { toggleWikiFolder, setWikiPage, lang, version } = this.props
    return Array.from(items, (item, idx) => {
      //不显示README.md
      // if (item && item.fullPath.indexOf('README.md') > -1) {
      //   return null
      // }
      let classes = ['toc-tree-item'];
      let inOpenKeys = false;
      if (openKeys.some(k => k === item.key)) {
        inOpenKeys = true;
      }
      if (item.wikiId) {
        if (inOpenKeys) {
          classes.push('active')
        }
        return (
          <li key={item.key}>
            <Link className={classes.join(' ')} to={`/${lang}/wiki/${item.fullPath}${!!version ? `?_v=${version}` : ''}`} onClick={() => {
              setWikiPage(item.key)
              this.toggleTocVisible(false)
            }}>{item.localeName}</Link>
          </li>
        )
      } else {
        if (inOpenKeys) {
          classes.push('expanded')
        } else {
          classes.push('collapsed')
        }
        return (
          <li key={item.key}>
            <span className={classes.join(' ')} onClick={() => toggleWikiFolder(item.key)}>{item.localeName}</span>
            {inOpenKeys ? (
              <ul className="toc-tree-group">
                {this.renderTableOfContent(item.items, openKeys)}
              </ul>
            ) : null}
          </li>
        )
      }
    })
  }

  toggleTocVisible(forceVal) {
    if (forceVal === undefined || forceVal === null) {
      this.setState(state => ({
        tocVisible: !state.tocVisible
      }))
    } else {
      this.setState({
        tocVisible: forceVal
      })
    }
  }

  getPdfDownloadUrl(lang) {
    let host = "https://academy.encoo.com"
    if (!prod_env) {
      host = "https://dev-academy.bottime.com"
    }
    // console.log(`host ${host} lang ${lang}`)
    switch (lang) {
      case "ja-jp":
        return `${host}/pdf/Product_Induction_JA-JP.pdf`
      case "en-us":
        return `${host}/pdf/Product_Induction_EN-US.pdf`
      default:
        return `${host}/pdf/Product_Induction_ZH-CN.pdf`
    }
  }

  render() {
    const { wikiTree, openKeys, winWidth, wikiTop, t, lang } = this.props;
    const { wikiSearchResult } = this.state;

    const pdfDownloadUrl = this.getPdfDownloadUrl(lang)
    const isIE11 = (typeof window !== 'undefined') && (window.navigator.userAgent.indexOf('Trident') >= 0)

    let normalToc = null, collapsedToc = null
    let tocInner = (isIE11) => (
      <Fragment>
        <span className="toc-label">{t('wiki.search')}</span>
        <AutoComplete
          onTextChanged={this.onTocSearchTextChanged}
          onEnter={this.onTocSearchEnter}
          dataSource={wikiSearchResult} />
        <div style={{marginBottom:'8px'}}><SelectDocLang /></div>
        <ul className="toc-tree" style={isIE11 ? { maxHeight: `calc(100vh - ${158 + wikiTop}px)` } : {}}>
          {wikiTree ? this.renderTableOfContent(wikiTree, openKeys) : null}
        </ul>
        <a className="pdf-download" href={pdfDownloadUrl}><i className="fa fa-save"></i>{t('wiki.downloadpdf')}</a>
      </Fragment>
    )
    if (!winWidth || winWidth <= 767) {
      let tocContainerClasses = ["toc-mobile-container"]
      if (this.state.tocVisible) {
        tocContainerClasses.push("active")
      }
      collapsedToc = (
        <Fragment>
          <div className="toc-hamburger" onClick={() => this.toggleTocVisible()}>
            <i className="fa fa-bars" aria-hidden="true"></i>
          </div>
          <div className={tocContainerClasses.join(' ')}>
            {tocInner()}
          </div>
        </Fragment>
      )
    } else {
      normalToc = (
        <div className="wiki-layout wiki-table-of-content">
          <StickyWikiSideBar float='left'>
            {tocInner(isIE11)}
          </StickyWikiSideBar>
        </div>
      )
    }
    return (
      <Fragment>
        {normalToc}
        {collapsedToc}
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  let { tree, openKeys } = state.wiki
  const { width: winWidth, wikiTop } = state.winsize
  const { locale: lang, version } = state.lang
  return {
    //功能清单页面在代码层面隐藏
    wikiTree: tree ? tree.filter(p => p.fullPath != 'FunctionList.md') : tree,
    openKeys,
    winWidth,
    lang,
    version,
    wikiTop
  }
}

const mapDispatchToProps = dispatch => ({
  toggleWikiFolder: key => dispatch(toggleWikiFolder(key)),
  setWikiPage: key => dispatch(setWikiPage(key)),
  wikiSearch: (searchWord, version, lang) => dispatch(wikiSearch(searchWord, version, lang)),
})

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Toc)))