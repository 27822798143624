import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import Banner from '../assets/login/login-banner.png';
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next';
import { userSetLang } from '../actions';
import IconCN from '../assets/academy-logo/cn.svg';
import IconUS from '../assets/academy-logo/us.svg';
import IconJP from '../assets/academy-logo/jp.svg';

import { getCurrentStoredLang } from '../tools/lang';
import { setLang } from '../actions';

function withProcessLang(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
    }

    componentDidMount() {
      const { location, setLang } = this.props;
      if (!/^\/(zh-cn|en-us|ja-jp)/.test(location.pathname)) {
        let lang = 'zh-cn';
        const persistLang = getCurrentStoredLang();
        if (!!persistLang) {
          lang = persistLang;
        }
        setLang(lang);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}

const AuthLayout = ({ children, t, lang, userSetLang }) => {
  return (
    <div className="auth-container">
      <div className="auth-left">
        <img className="auth-img" src={Banner} draggable="false" />
        <article className="auth-article">
          <h3 className="title">{t("login.academy")}</h3>
          <p className="description">{t("login.desc")}</p>
          <p className="subscript">—— academy.encoo.com ——</p>
        </article>
      </div>
      <div className="auth-right">
        {/* <div className="pure-menu pure-menu-horizontal lang-bar auth-lang-bar">
          <ul className="pure-menu-list">
            <li className="pure-menu-item pure-menu-has-children pure-menu-allow-hover">
              <a className="pure-menu-link">{t('header.lang')} {lang === 'zh-cn' ? <img src={IconCN} /> : lang === 'en-us' ? <img src={IconUS} /> : lang == 'ja-jp' ? <img src={IconJP} /> : null}</a>
              <ul className="pure-menu-children">
                <li className="pure-menu-item" onClick={() => userSetLang('zh-cn')}>
                  <a className="pure-menu-link">{t('header.lang-zh')}<img src={IconCN} /></a>
                </li>
                <li className="pure-menu-item" onClick={() => userSetLang('en-us')}>
                  <a className="pure-menu-link">{t('header.lang-en')}<img src={IconUS} /></a>
                </li>
                <li className="pure-menu-item" onClick={() => userSetLang('ja-jp')}>
                  <a className="pure-menu-link">{t('header.lang-ja')}<img src={IconJP} /></a>
                </li>
              </ul>
            </li>
          </ul>
        </div> */}

        <div className="auth-form">
          {children}
        </div>
      </div>
    </div >
  )
}

const mapStateToProps = state => ({
  lang: state.lang.locale
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  userSetLang: lang => dispatch(userSetLang(lang, ownProps.history, ownProps.location, ownProps.i18n)),
  setLang: lang => dispatch(setLang(lang))
})

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withProcessLang(AuthLayout))));
