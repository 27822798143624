import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import Icon_Company from '../../assets/login/company.png';
import Icon_Username from '../../assets/login/uesrname.png';
import Icon_Password from '../../assets/login/password.png';
import Icon_Email from '../../assets/login/email.png';
import Icon_Phone from '../../assets/login/iPhone.png';
import Icon_Pass_Visible from '../../assets/login/icon-password-visible.png';
import Icon_Pass_Hidden from '../../assets/login/icon-password-hidden.png';
import Icon_must_fillin from '../../assets/login/icon-must-fillin.svg';

import MyModal from "../../components/Modal";
import MyPrivacy from "../../components/Privacy";

import {
  togglePrivacy,
  register,
  validName,
  validEmail,
  validPhone,
  validCode,
  validPassword,
  validRePassword,
  validRead,
  fetchCode
} from '../../actions';

import appInsights, { setGlobalParam, clearGlobalParams, getGlobalParams } from '../../tools/telemetry'

class FormRegister extends React.PureComponent {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.onHandleReadChecked = this.onHandleReadChecked.bind(this);
  }
  state = {
    company: "",
    username: '',
    email: '',
    phone: '',
    code: '',
    password: '',
    rePassword: '',
    showPassword: false,
    showRePassword: false,
    times: null,
    showModal: false
  }

  componentDidMount() {
    setGlobalParam("Url", window.location.href);
    setGlobalParam('PageName', 'Register');
    appInsights.trackEvent({ name: 'Academy_VisitedPageEvent' }, getGlobalParams());
    this.StartVisitTime = new Date();

    this.timer = null;
    document.addEventListener("keydown", this.handleEnter);
  }

  componentWillUnmount() {
    const LeavePageTime = new Date();
    const StadyTime = LeavePageTime - this.StartVisitTime;
    appInsights.trackEvent({ name: 'Academy_StadyTimeEvent' }, { StartVisitTime: this.StartVisitTime, LeavePageTime, StadyTime, ...getGlobalParams() });
    clearGlobalParams();

    this.timer && clearInterval(this.timer);
    document.removeEventListener("keydown", this.handleEnter);
  }

  handleEnter = (e) => {
    if (e.keyCode === 13) {
      this.onSubmit();
    }
  }

  onHandleCompany(company) {
    this.setState({ company });
  }

  onHandleUserName(username) {
    const { validName } = this.props;
    this.setState({ username });
    validName(username);
  }
  onHandleEmail(email) {
    const { validEmail } = this.props;
    this.setState({ email });
    validEmail(email);
  }
  onHandlePhone(phone) {
    const { validPhone } = this.props;
    this.setState({ phone });
    validPhone(phone);
  }
  onHandleCode(code) {
    const { validCode } = this.props;
    this.setState({ code });
    validCode(code);
  }
  onHandlePassword(password) {
    const { validPassword } = this.props;
    this.setState({ password });
    validPassword(password);
  }
  togglePassVisible() {
    this.setState(state => ({
      showPassword: !state.showPassword
    }))
  }
  toggleRePassVisible() {
    this.setState(state => ({
      showRePassword: !state.showRePassword
    }))
  }
  onHandleRePassword(rePassword) {
    const { validRePassword } = this.props;
    const { password } = this.state;
    this.setState({ rePassword });
    validRePassword({ password, rePassword });
  }
  onHandleRead() {
    const { isRead, validRead, togglePrivacy } = this.props;
    togglePrivacy(!isRead).then(() => {
      validRead(!isRead);
    });
  }
  onHandleReadChecked() {
    const { validRead, togglePrivacy } = this.props;
    togglePrivacy(true).then(() => {
      validRead(true);
    });
  }
  async onFetchCode(e) {
    e.preventDefault();
    const { validPhone, fetchCode, validPhoneError } = this.props;
    const { phone, times } = this.state;
    await validPhone(phone);

    if (times) return;

    if (phone && !validPhoneError) {
      this.setState({
        times: 60
      });
      fetchCode(phone);

      this.timer = setInterval(() => {
        const { times } = this.state;

        if (times === 0) {
          clearInterval(this.timer);
          this.timer = null;
        } else {
          this.setState({
            times: times - 1
          });
        }
      }, 1000);
    }
  }

  onSubmit(e) {
    if (e !== undefined) {
      e.preventDefault();
    }

    const {
      isRead,
      register,
      validName,
      validEmail,
      validPhone,
      validCode,
      validPassword,
      validRePassword,
      validRead
    } = this.props;
    const { company, username, email, phone, code, password, rePassword } = this.state;

    Promise.all([
      validName(username),
      validEmail(email),
      validPhone(phone),
      validCode(code),
      validPassword(password),
      validRePassword({ password, rePassword }),
      validRead(isRead)
    ])
      .then(() => {
        const {
          validNameError,
          validEmailError,
          validPhoneError,
          validCodeError,
          validPasswordError,
          validRePasswordError,
          validReadError
        } = this.props

        if (!validNameError && !validEmailError && !validPhoneError && !validCodeError && !validPasswordError && !validRePasswordError && !validReadError) {
          register({ company, username, email, phone, code, password, read });
        }
      });
  }

  closeModal() {
    this.setState({
      showModal: false
    })
  }

  toggleModal() {
    this.setState({
      showModal: !this.state.showModal
    })
  }

  render() {
    const {
      isRead,
      registerErrorArray,
      validNameError,
      validEmailError,
      validPhoneError,
      validCodeError,
      validPasswordError,
      validRePasswordError,
      validReadError,
      codeError,
      t
    } = this.props;
    const { showPassword, times, showRePassword, showModal } = this.state;

    return (
      <Fragment>
        <div className="title-box">
          <img className="icon" src='/title-logo.png' draggable="false" style={{ height: 50, width: 'auto' }} />
          <span style={{ fontSize: 20, verticalAlign: 'middle', color: 'rgb(55,108,223)', paddingTop: 12 }}>注册云扩账户</span>
          {/* <p className="title"><span style={{ fontWeight: '600' }}>{t('register.registertitle')}</span></p> */}
        </div>
        <div className="login">
          {
            registerErrorArray.length ?
              <span className="text-error" dangerouslySetInnerHTML={{ __html: registerErrorArray.length ? t(registerErrorArray[0]) : '' }}></span>
              :
              null
          }

          <form className="pure-form pure-form-stacked login-form">
            <fieldset style={{ paddingBottom: '2em' }}>
              <div className="position-relative">
                <input style={{ paddingLeft: '55px' }} className="input" id="company" type="text" placeholder="公司名称" autoComplete="off" onBlur={e => this.onHandleCompany(e.target.value.trim())} />
                <span className="icon-before"><img src={Icon_Company} draggable="false" /></span>
              </div>

              <div className="position-relative">
                <img className="icon-must-fillin" src={Icon_must_fillin} />
                <input style={{ paddingLeft: '55px' }} className={validNameError ? "input has-error" : "input"} id="userName" type="text" placeholder="姓名" autoComplete="off" onBlur={e => this.onHandleUserName(e.target.value.trim())} />
                <span className="icon-before"><img src={Icon_Username} draggable="false" /></span>
              </div>

              <div className="position-relative">
                <img className="icon-must-fillin" src={Icon_must_fillin} />
                <input style={{ paddingLeft: '55px' }} className={validEmailError ? "input has-error" : "input"} id="email" type="email" placeholder="邮箱" onBlur={e => this.onHandleEmail(e.target.value.trim())} />
                <span className="icon-before"><img src={Icon_Email} draggable="false" /></span>
              </div>

              <div className="position-relative">
                <img className="icon-must-fillin" src={Icon_must_fillin} />
                <input style={{ paddingLeft: '55px' }} className={validPhoneError ? "input has-error" : "input"} id="phone" type="text" placeholder="手机号码" onBlur={e => this.onHandlePhone(e.target.value.trim())} />
                <span className="icon-before"><img src={Icon_Phone} draggable="false" /></span>
              </div>

              <label className="position-relative input-box">
                <img className="icon-must-fillin" src={Icon_must_fillin} />
                <input style={{ paddingLeft: '15px' }} className={(validCodeError || codeError) ? "input input-mid has-error" : "input input-mid"} id="code" type="text" placeholder="请输入短信验证码" onBlur={e => this.onHandleCode(e.target.value.trim())} />
                <button className={times ? "pure-button pure-button-primary button-code disable" : "pure-button pure-button-primary button-code"} onClick={e => this.onFetchCode(e)}>
                  {times ? t('register.resend') + " (" + times + "S)" : t('register.code')}
                </button>
              </label>

              <div className="position-relative">
                <img className="icon-must-fillin" src={Icon_must_fillin} />
                <input style={{ paddingLeft: '55px' }} className={validPasswordError ? "input has-error" : "input"} id="password" type={showPassword ? "text" : "password"} placeholder="密码" onBlur={e => this.onHandlePassword(e.target.value.trim())} />
                <span className="icon-before"><img src={Icon_Password} draggable="false" /></span>
                <span className="icon-after"><img src={showPassword ? Icon_Pass_Visible : Icon_Pass_Hidden} draggable="false" onClick={e => this.togglePassVisible(e)} /></span>
              </div>

              <div className="position-relative">
                <img className="icon-must-fillin" src={Icon_must_fillin} />
                <input style={{ paddingLeft: '55px' }} className={validRePasswordError ? "input has-error" : "input"} id="rePassword" type={showRePassword ? "text" : "password"} placeholder="确认密码" onBlur={e => this.onHandleRePassword(e.target.value.trim())} />
                <span className="icon-before"><img src={Icon_Password} draggable="false" /></span>
                <span className="icon-after"><img src={showRePassword ? Icon_Pass_Visible : Icon_Pass_Hidden} draggable="false" onClick={e => this.toggleRePassVisible(e)} /></span>
              </div>

              <div className="pure-checkbox display-flex">
                <label>
                  <input className="checked" checked={isRead} id="read" type="checkbox" onChange={e => this.onHandleRead(e.target.checked)} /> <span className={validReadError ? "read has-error" : "read"}>{t('register.read')}</span>
                </label>
                <a className="privacy" onClick={e => this.toggleModal(e)}>{t('register.privacy')}</a>
              </div>

              <button type="submit" className="pure-button pure-button-primary button-submit" onClick={e => this.onSubmit(e)}>{t('register.register')}</button>
            </fieldset>

            <p className="subscript">{t('register.isuser')} <Link className="button-login" to="/auth/login">{t('register.login')}</Link></p>
          </form>
        </div>
        <MyModal showModal={showModal} modalTitle="云扩学院隐私条款" onHandleClose={this.closeModal} onHandleConfirme={this.onHandleReadChecked}>
          <MyPrivacy />
        </MyModal>
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  const {
    isRead,
    registerError,
    validNameError,
    validEmailError,
    validPhoneError,
    validCodeError,
    validPasswordError,
    validRePasswordError,
    validReadError
  } = state.auth;

  let codeError = false;

  if (registerError.indexOf('验证码') >= 0) {
    codeError = true;
  }

  let registerErrorArray = []
  if (registerError.startsWith('[')) {
    registerErrorArray = JSON.parse(registerError);
  } else if (registerError) {
    registerErrorArray.push(registerError);
  }

  if (validNameError) {
    registerErrorArray.push(validNameError)
  }
  if (validEmailError) {
    registerErrorArray.push(validEmailError)
  }
  if (validPhoneError) {
    registerErrorArray.push(validPhoneError)
  }
  if (validCodeError) {
    registerErrorArray.push(validCodeError)
  }
  if (validPasswordError) {
    registerErrorArray.push(validPasswordError)
  }
  if (validRePasswordError) {
    registerErrorArray.push(validRePasswordError)
  }
  if (validReadError) {
    registerErrorArray.push(validReadError)
  }

  return {
    isRead,
    registerErrorArray,
    validNameError,
    validEmailError,
    validPhoneError,
    validCodeError,
    validPasswordError,
    validRePasswordError,
    validReadError,
    codeError
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    togglePrivacy: isRead => dispatch(togglePrivacy(isRead)),
    register: para => dispatch(register(para)),
    fetchCode: phone => dispatch(fetchCode(phone)),
    validName: name => dispatch(validName(name)),
    validPhone: phone => dispatch(validPhone(phone)),
    validEmail: email => dispatch(validEmail(email)),
    validCode: code => dispatch(validCode(code)),
    validPassword: password => dispatch(validPassword(password)),
    validRePassword: para => dispatch(validRePassword(para)),
    validRead: read => dispatch(validRead(read))
  }
}

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(FormRegister)))
