import {ConstValues} from '../actions'
import update from 'immutability-helper'

const initialData = {
  breadscrumbs: []
}

export default (state = initialData, action) => {
  switch(action.type) {
    case ConstValues.SET_BREADSCRUMBS:
      return update(state, {breadscrumbs: {$set: action.breadscrumbs}})
    default:
      return state;
  }
}