import { ConstValues } from '../actions'
import update from 'immutability-helper'

const initialData = {
  newCourses: []
}

export default (state = initialData, action) => {
  switch(action.type) {
    case ConstValues.SEND_NEW_COURSES:
      return update(state, {
        newCourses: {$set: action.list}
      })
    default:
      return state;
  }
}