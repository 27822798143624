import ConstValues from './consts';
import { get, post } from '../tools/api';
import { runtimeConfig } from '../config';
const { api_domain } = runtimeConfig;

export function getLearnPathDetail(pathId) {
  return async function (dispatch) {
    const url = `${api_domain}api/learn-api/learnpath/${pathId}`;
    try {
      const data = await get(url);
      dispatch({
        type: ConstValues.LEARN_SET_PATH_DETAIL,
        data: data
      });
      return data;
    } catch (err) {
      console.error(err)
    }
  }
}

export function getLearnCourseDetail(courseId) {
  return async function (dispatch) {
    const url = `${api_domain}api/learn-api/course/${courseId}`;
    try {
      const data = await get(url);
      return data;
    } catch (err) {
      console.error(err)
    }
  }
}

export function likeLearnPath(pathId, ll = "") {
  return async function (dispatch) {
    const url = `${api_domain}api/learn-api/like-learnpath/${pathId}`;
    try {
      await post(url);
      if (ll === "learnLanding") {
        dispatch({
          type: ConstValues.REFRESH_LL_LIST,
          id: pathId
        });
      } else if (ll === "searchLearn") {
        dispatch({
          type: ConstValues.REFRESH_SL_LIST,
          id: pathId
        });
      }
    } catch (err) {
      throw new Error(err);
    }
  }
}

export function likeLearnCourse(courseId, ll = "") {
  return async function (dispatch) {
    const url = `${api_domain}api/learn-api/like-course/${courseId}`;
    try {
      await post(url);
      if (ll === "learnLanding") {
        dispatch({
          type: ConstValues.REFRESH_LL_LIST,
          id: courseId
        });
      } else if (ll === "searchLearn") {
        dispatch({
          type: ConstValues.REFRESH_SL_LIST,
          id: courseId
        });
      }
    } catch (err) {
      throw new Error(err);
    }
  }
}

export function openLearnPath(pathId) {
  return async function (dispatch) {
    const url = `${api_domain}api/learn-api/open-learnpath/${pathId}`;
    try {
      await post(url);
    } catch (err) {
      throw new Error(err);
    }
  }
}

export function openLearnCourse(courseId) {
  return async function (dispatch) {
    const url = `${api_domain}api/learn-api/open-course/${courseId}`;
    try {
      await post(url);
    } catch (err) {
      throw new Error(err);
    }
  }
}

export function startLearnPathCourse({ pathId, courseId }) {
  return async function (dispatch) {
    const url = `${api_domain}api/learn-api/start-course/${courseId}?learnPathId=${pathId}`;
    try {
      const data = await post(url);
      return data;
    } catch (err) {
      throw new Error(err);
    }
  }
}
