import ConstValues from './consts';
import { get, post } from '../tools/api';
import { runtimeConfig } from '../config';
const { api_domain } = runtimeConfig;

export function getLearnCrumbsByUnitId(unitId) {
  return async function (dispatch) {
    const url = `${api_domain}api/learn-api/getCrumbsByUnitId/${unitId}`;
    try {
      const data = await get(url)
      dispatch({
        type: ConstValues.LEARN_SET_UNIT_CRUMBS,
        data: data
      })
      return data
    } catch (err) {
      console.error(err)
    }
  }
}

export function getLearnCrumbsByCourseId(courseId) {
  return async function (dispatch) {
    const url = `${api_domain}api/learn-api/getCrumbsByCourseId/${courseId}`;
    try {
      const data = await get(url);
      dispatch({
        type: ConstValues.LEARN_SET_COURSE_CRUMBS,
        data: data
      })
      return data
    } catch (err) {
      console.error(err)
    }
  }
}