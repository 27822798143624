import React from 'react';

const Privacy = () => {
  return (
    <div className="privacy-container">
      <p className="content">
        云扩学院非常重视对您隐私的保护，请您仔细阅读如下声明。当您访问云扩学院网站或使用云扩学院提供的服务前，您需要同意隐私政策中具体解释的收集、使用、公布和以其它形式运用您个人信息的政策。如果您不同意隐私政策中的任何内容，请立即停止使用或访问云扩学院网站。
        为了给您提供更准确、更有针对性的服务，云扩学院可能会以如下方式，使用您提交的个人信息，但云扩学院会以高度的勤勉义务对待这些信息。如果您对我们的隐私保护条款有任何疑问或建议，请发邮件contact@encootech.com
          </p>

      <h4 className="subtitle">我们如何收集个人信息</h4>
      <p className="content">
        云扩学院会在您自愿选择服务或提供信息的情况下收集您的个人信息（简称“个人信息”），例如您的姓名、邮件地址、电话号码及其他身份信息等。我们有可能会保留一些用户的使用习惯信息（相关操作及数据），用于更好地了解和服务于用户。这些数据将有利于我们开发出更符合用户需求的功能、信息和服务。
        我们会记录您在云扩学院中的全部或部分操作行为（键盘及鼠标输入信息），此数据会用作云扩学院产品优化以及其他云扩学院及云扩学院合作方的数据分析应用。
          </p>

      <h4 className="subtitle">我们如何使用收集的个人信息</h4>
      <p className="content">
        我们利用从所有服务中收集的信息来提供、维护、保护和改进这些服务，同时开发新的服务为您带来更好的用户体验，并提高我们的总体服务品质。经您的许可，我们可能还会使用此类信息为您提供定制内容。
          </p>

      <h4 className="subtitle">我们会向谁披露您的信息</h4>
      <p className="content">
        用户在如下情况下，云扩学院会遵照您的意愿或法律的规定披露您的个人信息，由此引发的问题将由您个人承担：
          </p>
      <ul className="content">
        <li>
          （1）事先获得您的授权；
            </li>
        <li>
          （2）只有透露您的个人资料，才能使云扩学院或其合作商提供您所要求的产品和服务；
            </li>
        <li>
          （3）根据有关的法律法规要求；
            </li>
        <li>
          （4）按照相关政府主管部门的要求；
            </li>
        <li>
          （5）为维护云扩学院的合法权益；
            </li>
        <li>
          （6）您同意让第三方共享资料；
            </li>
        <li>
          （7）我们发现您违反了云扩学院公司服务条款或任何其他产品服务的使用规定。
            </li>
      </ul>

      <h4 className="subtitle">用户对个人信息的控权利</h4>
      <p className="content">
        云扩学院相信用户应该对他/她的个人信息拥有绝对的控制权，我们将尽全力保护您对于您个人信息的查询、访问、修改、删除、撤回同意授权、注销帐号、投诉举报以及设置隐私功能的相关权利，以使您有能力保障您的隐私和信息安全。
          </p>

      <h4 className="subtitle">我们如何保护用户的个人信息</h4>
      <p className="content">
        我们希望我们的用户放心的使用我们的产品和服务，所以我们承诺对您的个人信息予以保密，为此我们设置了安全程序保护您的信息不会被未经授权的访问所窃取。所有的个人信息被加密储存并放置于专业防火墙内，我们使用SSL加密技术对您所提供的个人信息传输通道进行保护，保证您的个人信息不会在传输途中被窃取。用户明确同意其使用网络服务所存在的风险将完全由其自己承担。
          </p>

      <h4 className="subtitle">Cookies和其他浏览器技术</h4>
      <p className="content">
        当用户访问云扩学院的时候，我们可能会保存用户的用户登录状态并且为用户分配一个或多个“Cookie”，使用此类技术可以帮助您省去重复您填写个人信息、输入搜索内容的步骤和流程（示例：记录搜索、表单填写）。我们可能会利用 Cookie 和同类技术了解您的偏好和使用习惯，进行数据分析，以改善产品服务、推荐用户感兴趣的信息等功能
          </p>

      <h4 className="subtitle">清除Cookie</h4>
      <p className="content">
        大多数浏览器均为用户提供了清除浏览器缓存数据的功能，您可以在浏览器设置功能中进行相应的数据清除操作。如您进行清除，可能因为这些修改，您可能无法使用依赖于Cookie由我们提供的服务或相应功能。
          </p>

      <h4 className="subtitle">关于隐私条款的变更</h4>
      <p className="content">
        本隐私条款自2020年4月7日起生效。云扩学院将根据法律、法规或政策的变更和修改，或云扩学院网站内容的变化和技术的更新，或其他云扩学院认为合理的原因，对本隐私政策修改并以网站公告、用户通知等合适的形式告知用户，若您不接受修订后的条款的，应立即停止使用本服务，若您继续使用本服务的，视为接受修订后的所有条款。
          </p>
    </div>
  )
}

export default Privacy;
