import React, {useState, useEffect} from 'react'

export function useDeviceType(winWidth) {
  const [isMobile, setIsMobile] = useState(winWidth < 768)
  useEffect(() => {
    if (winWidth < 768) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [winWidth])

  return isMobile
}