import { ConstValues } from '../actions'
import update from 'immutability-helper'

const initialData = {
  width: null,
  height: null,
  wikiLeft: null,
  wikiRight: null,
  wikiTop: null,
  wikiHeight: null,
  unitLeft: null,
  unitRight: null,
  unitTop: null,
  unitHeight: null,
  footerHeight: null,
}

export default (state = initialData, action) => {
  switch (action.type) {
    case ConstValues.SET_WINDOW_SIZE:
      return update(state, { width: { $set: action.size.width }, height: { $set: action.size.height } })
    case ConstValues.SET_WIKI_CONTAINER_SIZE:
      return update(state, {
        wikiLeft: { $set: action.left },
        wikiRight: { $set: action.right },
        wikiTop: { $set: action.top },
        wikiHeight: { $set: action.height }
      })
    case ConstValues.SET_UNIT_CONTAINER_SIZE:
      return update(state, {
        unitLeft: { $set: action.left },
        unitRight: { $set: action.right },
        unitTop: { $set: action.top },
        unitHeight: { $set: action.height }
      })
    case ConstValues.SET_FOOTER_SIZE:
      return update(state, {
        footerHeight: { $set: action.footerHeight }
      })
    default:
      return state;
  }
}