import { combineReducers } from 'redux'

import auth from './auth'
import wiki from './wiki'
import winsize from './winsize'
import widgets from './widgets'
import comment from './comment'
import global from './global'
import lang from './lang'
import courses from './courses'
import roadmap from './roadmap'
import search from './search'
import sm from './search-mobile'
import learnCourse from './learn-course'
import unit from './unit'
import LL from './learnLanding'
import profile from './profile'
import indexData from './indexData'
import learnCrumbs from './learnCrumbs'

export default combineReducers({
  auth, wiki, winsize, widgets, comment, global, lang,
  courses, roadmap, search, sm, learnCourse, unit, LL,
  profile, indexData, learnCrumbs
})
