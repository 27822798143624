import {ConstValues} from '../actions'
import update from 'immutability-helper'

const initialData = {
  loggedIn: null,
  visitorName: '',
  adminLoggedIn: false
}

export default (state = initialData, action) => {
  switch(action.type) {
    case ConstValues.SET_LOGGEDIN:
      return update(state, {
        loggedIn: {$set: action.value},
        visitorName: {$set: action.visitorName}
      })
    case ConstValues.SET_ADMIN_LOGGEDIN:
      return update(state, {
        adminLoggedIn: {$set: action.adminLoggedIn}
      })
    default:
      return state
  }
}