import ConstValues from './consts';
import {get, post} from '../tools/api'
import {runtimeConfig} from '../config'
const {api_domain} = runtimeConfig

export function getNewCourses() {
  return async function(dispatch) {
    const url = `${api_domain}api/learn-api/landing-list?markedNew=true&start=0&pageSize=10`
    try {
      const data = await get(url)
      dispatch({
        type: ConstValues.SEND_NEW_COURSES,
        list: data.list
      })
    } catch (err) {
      console.error(err)
    }
  }
}