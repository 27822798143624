export default {
  translation: {
    "header.index": "ホームページ",
    "header.courses": "カリキュラム",
    "header.wiki": "ぶんしょ",
    "header.community": "コミュニティー",
    "header.workshop": "RPA ワーキングセット",
    "header.official": "公式サイト",

    "header.nav": "Navigation",
    "header.signin": "サインイン / サインアップ",
    "header.lang": "Language",
    "header.lang-zh": "Chinese",
    "header.lang-en": "English",
    "header.lang-ja": "Japanese",
    "header.searchbycontent": "コンテンツで検索する",
    "header.searchcourses": "カリキュラム",
    "header.searchwiki": "ぶんしょ",
    "header.searchall": "ぜんぶ",

    "header.search": "Search Courses/Wiki",

    "footer.nav": "导航",
    "footer.slogan": "从零开始，打开BotTime的大门",
    "footer.address": "上海市徐汇区万科中心H座5楼",
    
    "footer.copyright": "上海云扩信息科技有限公司",
    "footer.academy": "云扩学院",
    "footer.help": "帮助",

    "latest": "Latest",
    "首页": "Home",
    "文档": "Encoo RPA Wiki",

    "wiki.search": "探索する",
    "wiki.inthisarticle": "この記事のコンテンツナビゲーション",
    "wiki.searchbytitle": "タイトルで検索",
    "wiki.editdoc": "ドキュメントを編集する",
    "wiki.feedback": "フィードバック",
    "wiki.relatedcourse": "大学関連のコース",
    "wiki.relatedpost": "フォーラム関連のディスカッション",
    "wiki.goto_forum_and_discuss_1": "ヘルプやフィードバックが必要ですか？と一緒に行きます",
    "wiki.goto_forum_and_discuss_2": "ENCOO フォーラム",
    "wiki.goto_forum_and_discuss_3": "ディスカッションに参加する",
    "wiki.downloadpdf": "PDFをダウンロード",

    "wikic.title1": "面向天匠RPA开发者的产品文档",
    "wikic.title2": "使用以下资源快速查找面向开发者的产品文档",
    "wikic.editor": "编辑器",
    "wikic.watching": "{{count}}人在看",
    "wikic.title3": "2分钟快速入门",
    "wikic.title4": "安装天匠编辑器、使用录制组件来完成自动化项目创建",
    "wikic.qs_intro": "产品介绍",
    "wikic.qs_intro_desc": "天匠编辑器、控制台、机器人",
    "wikic.qs_ui": "用户界面",
    "wikic.qs_ui_desc": "登录天匠智能控制台，选择BotTimeStudio安装包",
    "wikic.qs_install": "安装编辑器",
    "wikic.qs_install_desc": "由工具栏、工具窗口以及设计窗口三个区域组成",
    "wikic.qs_comp_market": "天匠组件市场",
    "wikic.qs_comp_market_desc": "使用的工作流程类型为流程图和序列",
    "wikic.qs_flow_type": "工作流程类型",
    "wikic.qs_flow_type_desc": "可对依赖项进行添加、更新和删除",
    "wikic.qs_auto": "创建自动化项目",
    "wikic.qs_auto_desc": "使用录制组件或拖拽其他组件来完成创建自动化项目",
    "wikic.title5": "热门课程",
    "wikic.cc_overview": "RPA概览",
    "wikic.cc_overview_desc": "开启RPA之旅",
    "wikic.cc_dev_beginner": "RPA开发者基础",
    "wikic.cc_dev_beginner_desc": "零基础迈向RPA开发者",
    "wikic.cc_dev_jun": "RPA开发者中级",
    "wikic.cc_dev_jun_desc": "成为RPA开发专家",
    "wikic.cc_dev_sen": "RPA开发者高级",
    "wikic.cc_dev_sen_desc": "进阶掌控全场的RPA开发大师",
    "wikic.title6": "天匠频道",
    "wikic.channel_market": "天匠市场",
    "wikic.channel_market_desc": "由云扩科技及合作伙伴共同开发的自动化组件可在编...",
    "wikic.channel_platform": "天匠开放平台",
    "wikic.channel_platform_desc": "完善的产品文档、培训课程、开发者社区及丰富线下活动",
    "wikic.channel_console": "天匠控制台",
    "wikic.channel_console_desc": "企业RPA系统的中央控制中心，提拱机器人和流程的...",

    "未知错误": "未知错误",

    "login.academy": "云扩学院",
    "login.desc": "从零开始，打开云扩RPA的大门",
    "login.logintitle": "登录云扩学院",
    "login.remember": "记住密码",
    "login.forgot": "忘记密码？",
    "login.login": "登录",
    "login.notuser": "还没有云扩账户？",
    "login.register": "注册 >",
    "请输入密码": "请输入密码",
    "请输入用户名/邮箱/手机号": "请输入用户名/邮箱/手机号",
    "用户不存在或密码不正确": "用户不存在或密码不正确",

    "register.registertitle": "注册云扩学院",
    "register.resend": "重新发送",
    "register.code": "获取验证码",
    "register.read": "阅读并接受",
    "register.privacy": "《云扩学院隐私条款》",
    "register.register": "注册",
    "register.isuser": "已有账户？",
    "register.login": "登录 >",
    "请输入用户名": "请输入用户名",
    "请输入邮箱": "请输入邮箱",
    "请输入手机号": "请输入手机号",
    "请输入验证码": "请输入验证码",
    "请输入密码": "请输入密码",
    "请再次输入密码": "请再次输入密码",
    "请阅读并接受《云扩学院隐私条款》": "请阅读并接受《云扩学院隐私条款》",
    "邮箱格式不正确": "邮箱格式不正确",
    "请输入有效邮箱": "请输入有效邮箱",
    "请输入正确的手机号": "请输入正确的手机号",
    "请输入有效国内手机号": "请输入有效国内手机号",
    "验证码发送次数太多，请稍后再试": "验证码发送次数太多，请稍后再试",
    "密码不一致": "密码不一致",
    "用户名已存在": "用户名已存在",
    "Email已存在": "邮箱已存在",
    "手机号已存在": "手机号已存在",
    "验证码不正确": "验证码不正确",
  }
}
