import ConstValues from './consts';
import {get, post} from '../tools/api'
import {runtimeConfig} from '../config'
import appInsights from '../tools/telemetry'
const {api_domain, logout_url} = runtimeConfig

export  function testLoggedIn() {
  return async function(dispatch, getState) {
    const url = `${api_domain}api/visitor/test-loggedin`
      try {
        const data = await get(url, true)
        dispatch({
          type: ConstValues.SET_LOGGEDIN,
          value: true,
          visitorName: data.visitorName
        })
        appInsights.context.user.id = data.xid
      } catch (err) {
        if (err.status === 401) {
          dispatch({
            type: ConstValues.SET_LOGGEDIN,
            value: false,
            visitorName: ''
          })
          throw new Error("用户未登录")
        } else {
          dispatch({
            type: ConstValues.SET_LOGGEDIN,
            value: null,
            visitorName: ''
          })
          console.error(err)
          throw new Error("未知错误")
        }
      }
  }
}

export function logout() {
  return async function(dispatch) {
    window.location = logout_url
    // const url = `${api_domain}api/UniversalAuth/logout`
    // try {
    //   await post(url, {}, true)
    // } catch (err) {
    //   console.error(err)
    // }
    // dispatch({
    //   type: ConstValues.SET_LOGGEDIN,
    //   value: false,
    //   visitorName: ''
    // })
  }
}

export function testAdminLoggedIn() {
  return async function(dispatch) {
    const url = `${api_domain}api/editor/test-auth`
    try {
      await get(url, true)
      dispatch({
        type: ConstValues.SET_ADMIN_LOGGEDIN,
        adminLoggedIn: true
      })
      return true
    } catch (err) {
      return false
    }
  }
}