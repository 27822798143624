import {ConstValues} from '../actions'
import update from 'immutability-helper'

const initialData = {
  locale: 'zh-cn',
  version: '',
  versions: []
}

export default (state = initialData, action) => {
  switch(action.type) {
    case ConstValues.SET_LANG:
      return update(state, {locale: {$set: action.lang}})
    case ConstValues.SET_DOC_VERSION:
      return update(state, {version: {$set: action.version}})
    case ConstValues.SET_DOC_VERSIONS:
      return update(state, {versions: {$set: action.versions}})
    default:
      return state
  }
}