import React, { Fragment } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { toast } from 'react-toastify';

//imgs
import ImgBanner from '../assets/index/banner.png'
import ImgDot from '../assets/index/dot.png'
import ImgNavIcon1 from '../assets/index/nav-icon-1.png'
import ImgNavIcon2 from '../assets/index/nav-icon-2.png'
import ImgNavIcon3 from '../assets/index/nav-icon-3.png'
import IconWhy1 from '../assets/index/icon-why-1.png'
import IconWhy2 from '../assets/index/icon-why-2.png'
import IconWhy3 from '../assets/index/icon-why-3.png'

import OnlineCourseContainer from '../components/OnlineCourseContainer'
// import LecturersContainer from '../components/LecturersContainer'
import LearningPathContainer from '../components/LearningPathContainer'

import { getNewCourses, testLoggedIn, openLearnPath, openLearnCourse, likeLearnPath, likeLearnCourse, initLearnLandingCondition } from '../actions'
import { connect } from 'react-redux'

import appInsights, { setGlobalParam, clearGlobalParams, getGlobalParams } from '../tools/telemetry'

import {runtimeConfig} from '../config'
const {login_url} = runtimeConfig

class Index extends React.Component {
  navWiki() {
    window.location.pathname = '/wiki'
  }
  outlinkNav(url) {
    window.open(url, "_blank")
  }
  navCerts() {
    this.props.history.push('/certs')
  }
  gotoLearn(roleName = '') {
    this.props.initLearnLandingCondition(roleName)
    this.props.history.push('/learn')
  }
  componentDidMount() {
    setGlobalParam("Url", window.location.href)
    setGlobalParam('PageName', 'IndexPage')
    appInsights.trackEvent({ name: 'Academy_VisitedPageEvent' }, getGlobalParams())
    this.StartVisitTime = new Date()
    this.props.getNewCourses();
    document.title = "云扩学院-RPA开发者中心"
  }

  componentWillUnmount() {
    const LeavePageTime = new Date()
    const StadyTime = LeavePageTime - this.StartVisitTime
    appInsights.trackEvent({ name: 'Academy_StadyTimeEvent' }, { StartVisitTime: this.StartVisitTime, LeavePageTime, StadyTime, ...getGlobalParams() })
    clearGlobalParams()
  }

  async testLoggedInThen(type, id) {
    const { testLoggedIn, history } = this.props;

    try {
      await testLoggedIn();
      this.likeLearn(type, id);
    } catch (err) {
      if (err.message === '用户未登录') {
        const returnUrl = window.encodeURIComponent(window.location.pathname)
        window.location = `${login_url}?returnUrl=${returnUrl}`
      } else if (err.message === '未知错误') {
        toast('未知错误');
      }
    }
  }

  likeLearn(type, id) {
    const { getNewCourses, openLearnPath, likeLearnPath, openLearnCourse, likeLearnCourse } = this.props;

    if (type === 1) {
      openLearnPath(id).then(() => {
        likeLearnPath(id).then(() => {
          getNewCourses();
        });
      });
    } else {
      openLearnCourse(id).then(() => {
        likeLearnCourse(id).then(() => {
          getNewCourses();
        });
      });
    }
  }

  render() {
    return (
      <Fragment>
        <div className="index-banner banner-demo">
          <img src={ImgBanner} alt="banner-certs" />
          <div className="placement">
            <h1>欢迎来到云扩学院</h1>
            <p>提供专业的RPA课程，通过系统的学习帮助每个人成为RPA专家</p>
            <button onClick={() => this.gotoLearn()}>马上学习</button>
          </div>
        </div>
        <div className="index-nav">
          <div className="index-nav-wrapper">
            <div className="index-nav-item" onClick={() => this.outlinkNav("https://console.encoo.com/#/download")}>
              <img src={ImgDot} className="dot" style={{ top: -23, left: -24 }} />
              <h3>获取云扩RPA社区版</h3>
              <p>端到端的企业级智能流程自动化平台</p>
              <img src={ImgNavIcon1} className="icon-1" />
            </div>
          </div>
          <div className="index-nav-wrapper">
            <div className="index-nav-item" onClick={() => this.navWiki()}>
              <img src={ImgDot} className="dot" style={{ bottom: -23, left: 41 }} />
              <h3>查阅产品文档</h3>
              <p>丰富详尽的产品文档，支持随时反馈产品问题</p>
              <img src={ImgNavIcon2} className="icon-2" />
            </div>
          </div>
          <div className="index-nav-wrapper">
            <div className="index-nav-item" onClick={() => this.navCerts()}>
              <img src={ImgDot} className="dot" style={{ bottom: -26, right: -24 }} />
              <h3>RPA认证</h3>
              <p>完成学习课程成为云扩认证的RPA专家</p>
              <img src={ImgNavIcon3} className="icon-3" />
            </div>
          </div>
        </div>
        <LearningPathContainer />

        <OnlineCourseContainer testLoggedIn={this.testLoggedInThen.bind(this)} />
        <div className="why-bottime">
          <div className="why-bottime-body">
            <h2>为什么选择云扩学院？</h2>
            <div className="why-bottime-item">
              <img src={IconWhy1} />
              <h3>专业的RPA知识体系</h3>
              <p>从0开始，掌握RPA体系化课程</p>
            </div>
            <div className="why-bottime-item">
              <div className="mark"><div className="mark-inner"></div></div>
              <img src={IconWhy2} />
              <h3>丰富的产品文档</h3>
              <p>随时可查阅详细的RPA产品文档</p>
            </div>
            <div className="why-bottime-item">
              <img src={IconWhy3} />
              <h3>真实的业务实践</h3>
              <p>培养创建和设计业务流程的能力</p>
            </div>
          </div>
        </div>
        {/* <LecturersContainer /> */}
      </Fragment>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  getNewCourses: () => dispatch(getNewCourses()),
  testLoggedIn: () => dispatch(testLoggedIn()),
  openLearnPath: id => dispatch(openLearnPath(id)),
  openLearnCourse: id => dispatch(openLearnCourse(id)),
  likeLearnPath: id => dispatch(likeLearnPath(id)),
  likeLearnCourse: id => dispatch(likeLearnCourse(id)),
  initLearnLandingCondition: roleName => dispatch(initLearnLandingCondition(roleName)),
})

export default connect(null, mapDispatchToProps)(withRouter(Index));
