import fetch from 'cross-fetch';

import {runtimeConfig} from '../config'

const {login_url} = runtimeConfig

function _checkResponseStatus(response, unauthorizedRedirect = true) {
  const { status, statusText } = response;
  // accept 2xx response 
  if (status >= 200 && status < 300) {
    return response.json();
  }
  // unauthorized, redirect to login
  if (status === 401) {
    if (unauthorizedRedirect) {
      window.location = login_url;
      // window.location.reload();
      return;
    } else {
      /* eslint-disable */ 
      throw {
        status, statusText
      }
    }
  }
  // error other than 2xx
  if (status < 200 || status >= 300) {
    /* eslint-disable */ 
    throw {
      status, statusText
    }
  }
}

const checkResponseStatus = (resp) => _checkResponseStatus(resp);
const checkResponseStatusNoRedirect = (resp) => _checkResponseStatus(resp, false);

function checkResponseJson(respJson) {
  if (!respJson.hasOwnProperty('success')) {
    return respJson;
  }
  if (!respJson.success) {
    let { errorMessage } = respJson;
    errorMessage || (errorMessage = '')
    /* eslint-disable */ 
    throw { errorMessage };
  }
  if (respJson.hasOwnProperty('data')) {
    return respJson.data;
  }
}

export async function get(url, noRedirect = false) {
  const crs = noRedirect? checkResponseStatusNoRedirect: checkResponseStatus;
  let respData = await fetch(encodeURI(url), {
    credentials: "include"
  }).then(crs).then(checkResponseJson);
  return respData;
}

export async function post(url, bodyJson, noRedirect = false) {
  const crs = noRedirect? checkResponseStatusNoRedirect: checkResponseStatus;
  let respData = await fetch(encodeURI(url), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyJson),
    credentials: "include"
  }).then(crs).then(checkResponseJson);
  return respData;
}

export async function put(url, bodyJson) {
  let respData = await fetch(encodeURI(url), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyJson),
    credentials: "include"
  }).then(checkResponseStatus).then(checkResponseJson);
  return respData;
}

export async function _delete(url) {
  let respData = await fetch(encodeURI(url), {
    method: 'DELETE',
    credentials: "include"
  }).then(checkResponseStatus).then(checkResponseJson);
  return respData;
}

export function getErrorMessage(err) {
  let errorMessage = ''
  if (err.hasOwnProperty('errorMessage')) {
    errorMessage = err.errorMessage
  } else {
    errorMessage = "未知错误"
  }
  return errorMessage;
}