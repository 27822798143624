import ConstValues from './consts';
import { get, post } from '../tools/api'
import { runtimeConfig } from '../config'
const { api_domain } = runtimeConfig

export function resetSearch(hard) {
  return {
    type: ConstValues.RESET_SEARCH,
    hard
  }
}

export function fulltextSearchDocs(word, start, pageSize = 6) {
  return async function (dispatch, getState) {
    if (!word) {
      return
    }
    const lang = getState().lang.locale;
    const url = `${api_domain}api/wiki/fulltext-search?lang=${lang}&searchWord=${word}&start=${start}&pageSize=${pageSize}`
    try {
      dispatch({
        type: ConstValues.START_SEARCH_DOCS,
        start: start,
      })
      const data = await get(url)
      dispatch({
        type: ConstValues.FINISH_SEARCH_DOCS,
        list: data.list,
        count: data.count,
        start: start,
        word
      })
    } catch (err) {
      dispatch({
        type: ConstValues.FINISH_SEARCH_DOCS,
        list: [],
        count: 0,
        start: 0,
        word
      })
    }
  }
}

export function fuzzySearchLearnList(word, start, pageSize = 6) {
  return async function (dispatch) {
    if (!word) {
      return
    }
    let url = `${api_domain}api/learn-api/landing-list?name=${word}&description=${word}&start=${start}&pageSize=${pageSize}`
    try {
      dispatch({
        type: ConstValues.START_SEARCH_LEARN,
        start: start,
      })
      const data = await get(url)
      dispatch({
        type: ConstValues.FINISH_SEARCH_LEARN,
        list: data.list,
        count: data.count,
        start: start,
        word
      })
    } catch (err) {
      dispatch({
        type: ConstValues.FINISH_SEARCH_LEARN,
        list: [],
        count: 0,
        start: 0,
        word
      })
    }
  }
}

export function fuzzySearch(word, pageSize = 6) {
  return async function (dispatch, getState) {
    if (!word) {
      return
    }
    const lang = getState().lang.locale;
    const url = `${api_domain}api/search/all?lang=${lang}&word=${word}&pageSize=${pageSize}`
    try {
      dispatch({
        type: ConstValues.START_SEARCH_DOCS,
        start: 0
      })
      dispatch({
        type: ConstValues.START_SEARCH_LEARN,
        start: 0
      })
      dispatch({
        type: ConstValues.START_FUZZY_SEARCH_GENERIC
      })
      const data = await get(url)
      dispatch({
        type: ConstValues.FINISH_SEARCH_DOCS,
        list: data.wikis.list,
        count: data.wikis.count,
        start: 0,
        word
      })
      dispatch({
        type: ConstValues.FINISH_SEARCH_LEARN,
        list: data.learns.list,
        count: data.learns.count,
        start: 0,
        word
      })
      dispatch({
        type: ConstValues.FINISH_FUZZY_SEARCH_GENERIC,
        docs: data.wikis.list,
        docsCount: data.wikis.count,
        learns: data.learns.list,
        learnsCount: data.learns.count,
      })
    } catch (err) {
      console.error(err)
      dispatch({
        type: ConstValues.FINISH_SEARCH_DOCS,
        list: [],
        count: 0,
        start: 0,
        word
      })
      dispatch({
        type: ConstValues.FINISH_FUZZY_SEARCH_GENERIC,
        docs: [],
        docsCount: 0,
        learns: [],
        learnsCount: 0,
      })
    }
  }
}

export function fuzzySearchUnitList(word, start, pageSize = 6) {
  return async function (dispatch, getState) {
    if (!word) {
      return
    }
    const url = `${api_domain}api/search/unit?word=${word}&start=${start}&pageSize=${pageSize}`
    try {
      dispatch({
        type: ConstValues.START_SEARCH_LEARN,
        start: 0
      })
      dispatch({
        type: ConstValues.START_FUZZY_SEARCH_GENERIC
      })
      const data = await get(url)
      dispatch({
        type: ConstValues.FINISH_SEARCH_LEARN,
        list: data.learns.list,
        count: data.learns.count,
        start: start,
        word
      })
    } catch (err) {
      console.error(err)
      dispatch({
        type: ConstValues.FINISH_FUZZY_SEARCH_GENERIC,
        docs: [],
        docsCount: 0,
        learns: [],
        learnsCount: 0,
      })
    }
  }
}

export function setFuzzySearchWord(word) {
  return {
    type: ConstValues.SET_FUZZY_SEARCH_WORD,
    word
  }
}

export function setSearchCategory(c) {
  return {
    type: ConstValues.SET_SEARCH_CATEGORY,
    c
  }
}

export function setMobileConfirmWord(word) {
  return {
    type: ConstValues.SET_MOBILE_CONFIRM_WORD,
    word
  }
}