import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import RelatedWiki from './RelatedWiki'
import RelatedPost from './RelatedPost'

class StickyUnitSideBar extends React.PureComponent {
  constructor(props) {
    super(props)
    this.onScroll = this.onScroll.bind(this);
    this.containerRef = React.createRef();
  }
  state = {
    fixed: false,
    dock: false,
    muted: false,
    reduceHeight: null,
    stateLabels: null
  }
  onResetHeight() {
    const pageYOffset = window.pageYOffset;
    const { unitTop } = this.props
    if (!unitTop) {
      return
    }
    if (pageYOffset < unitTop) {
      this.setState({
        reduceHeight: unitTop - pageYOffset
      })
    } else {
      this.setState({
        reduceHeight: null
      })
    }
  }
  onScroll() {
    this.onResetHeight();
    const pageYOffset = window.pageYOffset;
    if (!this.props.unitTop) {
      return;
    }
    const { unitHeight } = this.props
    const el = this.containerRef.current
    if (el) {
      if (unitHeight === el.clientHeight) {
        this.setState({
          muted: true
        })
      } else {
        this.setState({
          muted: false
        })
      }
    }
    if (pageYOffset > this.props.unitTop + 42) {
      let dock = false;
      if (!!el) {
        const yMaxThreshold = unitHeight - el.clientHeight + this.props.unitTop;
        if (pageYOffset > yMaxThreshold) {
          dock = true
        }
      }
      this.setState({
        fixed: true,
        dock
      })
    } else {
      this.setState({
        fixed: false,
        dock: false
      })
    }
  }
  componentDidMount() {
    this.onResetHeight();
    window.addEventListener('scroll', this.onScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }
  componentDidUpdate(prevProps) {
    const { unitTop, unitHeight } = this.props
    const { unitTop: prevUnitTop, unitHeight: prevUnitHeight } = prevProps
    if (unitTop !== prevUnitTop || unitHeight !== prevUnitHeight) {
      this.onScroll()
    }
  }
  render() {
    const { float, width, unitLeft, unitTop, unitRight, labels } = this.props
    const { fixed, dock, muted, reduceHeight, stateLabels } = this.state
    this.setState({
      stateLabels: labels
    })
    if (muted || !fixed || unitLeft === null || !width) {
      var style = {}
    } else if (!dock) {
      style = {
        position: 'fixed',
        top: 0,
        right: `${unitRight - 40}px`
      }
    } else {
      style = {
        position: 'absolute',
        bottom: 0
      }
      style.right = 0
    }
    // if (!!reduceHeight) {
    //   style.maxHeight = `calc(100vh - ${reduceHeight}px)`
    // }
    style.maxHeight = '500px'

    return (
      <div className="sticky-container" style={style} ref={this.containerRef}>
        <RelatedWiki labels={stateLabels} />
        <hr className="sidebar_hr" />
        <RelatedPost labels={stateLabels} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  unitTop: state.winsize.unitTop,
  unitLeft: state.winsize.unitLeft,
  unitRight: state.winsize.unitRight,
  unitHeight: state.winsize.unitHeight,
  width: state.winsize.width
})

export default connect(mapStateToProps)(StickyUnitSideBar)