import React from 'react';
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next';

import img from '../assets/apply-community/img.png';

class ApplyCommunity extends React.PureComponent {
  constructor(props) {
    super(props)
  }
  state = {
  }

  componentDidMount() {
  }

  render() {
    const { t } = this.props;

    return (
      <div className="apply-community">
        <div className="banner">
          <div className="container banner__container">
            <h3 className="banner__title">申请天匠智能RPA社区版免费试用</h3>
            <p className="banner__desc">从现在起开启您的RPA之旅</p>
          </div>
        </div>

        <div className="container content__container">
          <div className="left">
            <div className="box">
              <img src={img} className="img" />
            </div>
          </div>
          <div className="right">
            <h3 className="right__title">天匠智能RPA 社区版</h3>
            <p className="right__subtitle">BotTime Studio</p>
            <p className="right__desc">适用于个人RPA开发人员和小型团队，随时升级至企业版。</p>
            <p className="right__tips">免费试用期：永久免费 </p>
            <button className="pure-button pure-button-primary button">申请试用</button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ApplyCommunity)))
