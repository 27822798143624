import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {getLearnPathPath} from '../actions'

import CertBanner from '../assets/cert/cert-banner.jpg'

import ImgStrategy from '../assets/cert/策略师.png'
import ImgDev1 from '../assets/cert/初级开发者.png'
import ImgDev2 from '../assets/cert/中级开发者.png'
import ImgDev3 from '../assets/cert/高级开发者.png'
import ImgManager from '../assets/cert/管理者.png'
import ImgImplementor from '../assets/cert/实施专家.png'

class CertIntro extends React.Component {
  state = {
    block1Expand: false,
    block2Expand: false,
    block3Expand: false,
    block4Expand: false,
    block5Expand: false,
    block6Expand: false
  }
  handleClickStartLearn(learnPathName) {
    this.props.getLearnPathPath(learnPathName).then(path => {
      if (!path) {
        return
      }
      this.props.history.push(path)
    })
  }
  render() {
    const {block1Expand, block2Expand, block3Expand, block4Expand, block5Expand, block6Expand} = this.state
    const block1ListStyle = !block1Expand? {maxHeight: '78px'}: {}
    const block2ListStyle = !block2Expand? {maxHeight: '78px'}: {}
    const block3ListStyle = !block3Expand? {maxHeight: '78px'}: {}
    const block4ListStyle = !block4Expand? {maxHeight: '78px'}: {}
    const block5ListStyle = !block5Expand? {maxHeight: '78px'}: {}
    const block6ListStyle = !block6Expand? {maxHeight: '78px'}: {}
    return (
      <div className="cert-intro">
        <div className="banner">
          <img src={CertBanner} />
          <div className="placement">
            <h2>云扩学院RPA认证</h2>
            <p>根据不同需求，提供量身定制的课程，助您获取RPA专业资格认证，在数字化时代先人一步！</p>
          </div>
        </div>
        <div className="cert-block-container">
          <div className="cert-block">
            <div className="flex-container">
              <img src={ImgStrategy} className="left"/>
              <div className="right">
                <h2>RPA策略师认证</h2>
                <p className="intro"><span className="label">课程简介：</span>RPA概览以RPA基础知识为中心，从企业探索该技术的历程以及使用该技术所带来的收益出发，探索RPA之旅。该课程包含RPA基础介绍以及云扩RPA编辑器的相关信息。</p>
                <p><span className="label">课程定位：</span>开启RPA之旅<span className="sep">|</span><span className="label">受众：</span>RPA决策者<span className="sep">|</span><span className="label">课程时间：</span>1-2小时</p>
                <p><span className="label">学习目标：</span>让RPA项目经理、决策者和业务需求发起者了解流程自动化收益与能力边界。</p>
                <label className="arrange">课程安排：</label>
                <ol style={block1ListStyle}>
                  <li>RPA基础介绍</li>
                  <li>RPA适用场景</li>
                  <li>自动化拐点</li>
                  <li>云扩RPA简介</li>
                </ol>
                {/* <i className={!block1Expand? "expand": "collapse"} onClick={() => {this.setState({block1Expand: !this.state.block1Expand})}}/> */}
                <p><span className="label">先决条件：</span>无</p>
                <p><span className="label">推荐延伸学习：</span>RPA开发者基础</p>
                <button className="disabled">敬请期待</button>
                <div className="quoto">
                  <i />
                  <label>RPA策略师认证证书可证明持证人</label>
                  <p>能够熟练掌握RPA基础知识，对RPA的应用特点和自动化场景拥有清晰的了解；对云扩RPA平台及其功能有深入的了解；具备进阶RPA开发者的理论知识基础。</p>
                </div>
              </div>
            </div>
          </div>
          <div className="cert-block">
            <div className="flex-container">
              <img src={ImgDev1} className="left" />
              <div className="right">
                <h2>RPA初级开发者认证</h2>
                <p className="intro"><span className="label">课程简介：</span>RPA开发者基础面向RPA初学者，旨在指导用户掌握云扩RPA编辑器的基础使用方法和操作要点。本课程涵盖了理论概念及实践操作，即使零基础也能轻松上手。</p>
                <p><span className="label">课程定位：</span>零基础迈向RPA开发者<span className="sep">|</span><span className="label">受众：</span>RPA开发者<span className="sep">|</span><span className="label">课程时间：</span>3-4小时</p>
                <p><span className="label">学习目标：</span>让零基础用户掌握RPA组件与功能，录制与创建基本流程，自动化日常工作需求。</p>
                <label className="arrange">课程安排：</label>
                <ol style={block2ListStyle}>
                  <li>创建第一个自动化项目</li>
                  <li>Office Excel操作</li>
                  <li>桌面自动化</li>
                  <li>网站自动化</li>
                  <li>Email自动化</li>
                </ol>
                {/* <i className={!block2Expand? "expand": "collapse"} onClick={() => {this.setState({block2Expand: !this.state.block2Expand})}}/> */}
                <p><span className="label">先决条件：</span>无</p>
                <p><span className="label">推荐延伸学习：</span>RPA概览、RPA中级开发者认证</p>
                <button onClick={() => this.handleClickStartLearn('RPA初级开发者认证')}>开始学习</button>
                <div className="quoto">
                  <i />
                  <label>RPA初级开发者认证证书可证明持证人</label>
                  <p>对云扩RPA平台及其功能的基础使用方法有深入的了解；能够掌握基础的RPA组件与功能，创建基本的自动化流程；具备进阶RPA中级开发者的能力。</p>
                </div>
              </div>
            </div>
          </div>
          <div className="cert-block">
            <div className="flex-container">
              <img src={ImgDev2} className="left" />
              <div className="right">
                <h2>RPA中级开发者认证</h2>
                <p className="intro"><span className="label">课程简介：</span>本课程以掌握RPA开发者基础为前提，介绍了云扩RPA编辑器中的基本组件和功能；并通过实践案例加深用户体验。</p>
                <p><span className="label">课程定位：</span>成为RPA开发专家<span className="sep">|</span><span className="label">受众：</span>RPA开发者<span className="sep">|</span><span className="label">课程时间：</span>2-3小时</p>
                <p><span className="label">学习目标：</span>掌握基本的组件、功能。为企业业务自动化流程降本增效。</p>
                <label className="arrange">课程安排：</label>
                <ol style={block3ListStyle}>
                  <li>调试与日志</li>
                  <li>图像识别：定位元素的另一种方法</li>
                  <li>选择器编辑器</li>
                  <li>数据表操作自动化</li>
                  <li>数据库操作自动化</li>
                  <li>获取结构化数据</li>
                </ol>
                {/* <i className={!block3Expand? "expand": "collapse"} onClick={() => {this.setState({block3Expand: !this.state.block3Expand})}}/> */}
                <p><span className="label">先决条件：</span>RPA概览、RPA开发者基础</p>
                <p><span className="label">推荐延伸学习：</span>RPA高级开发者认证</p>
                <button onClick={() => this.handleClickStartLearn('RPA中级开发者认证')}>开始学习</button>
                <div className="quoto">
                  <i />
                  <label>RPA中级开发者认证证书可证明持证人</label>
                  <p>熟练掌握了云扩RPA平台所提供的基本组件和功能；能够创建基本的自动化流程，帮助企业优化业务流程；具备进阶RPA高级开发者的能力。</p>
                </div>
              </div>
            </div>
          </div>
          <div className="cert-block">
            <div className="flex-container">
              <img src={ImgDev3} className="left" />
              <div className="right">
                <h2>RPA高级开发者认证</h2>
                <p><span className="label">课程简介：</span>本课程以RPA初中级为基础，介绍了如何通过云扩RPA编辑器制定自动化流程解决方案处理企业流程中存在的问题点。该课程着重于实践，使用户具备建立流程自动化解决方案的能力。</p>
                <p><span className="label">课程定位：</span>进阶掌控全场的RPA开发大师<span className="sep">|</span><span className="label">受众：</span>RPA开发者<span className="sep">|</span><span className="label">课程时间：</span>4-5小时</p>
                <p><span className="label">学习目标：</span>具有构建端到端功能性和定制性自动化项目的实战能力。</p>
                <label className="arrange">课程安排：</label>
                <ol>
                  <li>通过UiObject API获取Property</li>
                  <li>执行C#代码</li>
                  <li>执行Javascript代码</li>
                  <li>密码处理</li>
                </ol>
                <p><span className="label">先决条件：</span>RPA开发者基础，RPA中级开发者认证，RPA平台管理</p>
                <p><span className="label">推荐延伸学习：</span>RPA实施方法论</p>
                <button className="disabled">敬请期待</button>
                <div className="quoto">
                  <i />
                  <label>RPA高级开发者认证证书可证明持证人</label>
                  <p>对云扩RPA平台及其功能有深入的了解，并能够熟练使用；具有构建端到端功能性和定制性自动化项目的实战能力；有能力在ROC、COE中担当RPA开发人员的角色。</p>
                </div>
              </div>
            </div>
          </div>
          <div className="cert-block">
            <div className="flex-container">
              <img src={ImgManager} className="left" />
              <div className="right">
                <h2>RPA管理者认证</h2>
                <p><span className="label">课程简介：</span>RPA平台管理介绍了云扩控制台的基本和高级功能，同时提供了可以在免费培训环境中进行的实践练习，帮助学习者熟练掌握控制台的功能和使用方法。</p>
                <p><span className="label">课程定位：</span>成为数字生产力管理者<span className="sep">|</span><span className="label">受众：</span>RPA平台管理者<span className="sep">|</span><span className="label">课程时间：</span>3-4小时</p>
                <p><span className="label">学习目标：</span>了解控制台的用途，能够部署流程，根据业务需要配置机器人。</p>
                <label className="arrange">课程安排：</label>
                <ol>
                  <li>如何部署和执行流程</li>
                  <li>如何将机器人链接到控制台</li>
                </ol>
                <p><span className="label">先决条件：</span>RPA技术概览，RPA开发者基础</p>
                <p><span className="label">推荐延伸学习：</span>RPA实施方法论</p>
                <button className="disabled">敬请期待</button>
                <div className="quoto">
                  <i />
                  <label>RPA管理者认证证书可证明持证人</label>
                  <p>对云扩控制台功能组成有深入的了解，并能够熟练使用；具有部署流程，配置机器人等数字生产力掌控能力。</p>
                </div>
              </div>
            </div>
          </div>
          <div className="cert-block">
            <div className="flex-container">
              <img src={ImgImplementor} className="left" />
              <div className="right">
                <h2>RPA实施专家认证</h2>
                <p className="intro"><span className="label">课程简介：</span>本课程涵盖了云扩RPA专家团队基于上百个成功RPA项目总结了专业的实施方法论。此方法论广泛适用于各种场景，能够帮助用户成功地实施RPA。</p>
                <p><span className="label">课程定位：</span>成为RPA实施咨询顾问和架构师<span className="sep">|</span><span className="label">受众：</span>RPA咨询实施者<span className="sep">|</span><span className="label">课程时间：</span>2-3小时</p>
                <p><span className="label">学习目标：</span>能够识别实施的不同步骤，选择适合企业情况的自动化流程，驱动RPA项目，并提高组织整体的RPA意识。</p>
                <label className="arrange">课程安排：</label>
                <ol style={block6ListStyle}>
                  <li>RPA准备</li>
                  <li>RPA技术支撑</li>
                  <li>Coe支持</li>
                  <li>机会评估</li>
                  <li>设计、创建、测试</li>
                </ol>
                {/* <i className={!block6Expand? "expand": "collapse"} onClick={() => {this.setState({block6Expand: !this.state.block6Expand})}}/> */}
                <p><span className="label">先决条件：</span>RPA技术概览</p>
                <p><span className="label">推荐延伸学习：</span>RPA平台管理</p>
                <button className="disabled">敬请期待</button>
                <div className="quoto">
                  <i />
                  <label>RPA实施专家认证证书可证明持证人</label>
                  <p>能够识别实施的不同步骤，选择适合自动化的流程；拥有能够推动RPA项目，实现RPA项目落地的能力。</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  getLearnPathPath: learnPathName => dispatch(getLearnPathPath(learnPathName))
})

export default connect(null, mapDispatchToProps)(withRouter(CertIntro))