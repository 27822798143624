import {ConstValues} from '../actions'
import update from 'immutability-helper'

const initialData = {
  courses: [],
  courseId: null,
  sortCondition: 'all', // all, latest, hotest, duration_asc, duration_desc
  loading: false,
  lessons: []
}

export default (state = initialData, action) => {
  switch(action.type) {
    case ConstValues.COURSES_SET_COURSE_ID:
      return update(state, {courseId: {$set: action.courseId}})
    case ConstValues.COURSES_SET_SORT_COND:
      return update(state, {sortCondition: {$set: action.sortCondition}})
    case ConstValues.COURSES_START_SEARCH:
      return update(state, {loading: {$set: true}})
    case ConstValues.COURSES_FINISH_SEARCH:
      return update(state, {loading: {$set: false}})
    case ConstValues.COURSES_COURSES_SET:
      return update(state, {courses: {$set: action.data}})
    case ConstValues.COURSES_LESSONS_SET:
      return update(state, {lessons: {$set: action.data}})
    default:
      return state
  }
}