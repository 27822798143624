import React from 'react'
import {Link} from 'react-router-dom'
import { withTranslation } from 'react-i18next';

// items: [{name, url, current}]
const Breadscrub = ({items, t}) => (
  <div className="breadscrub">
    {Array.from(items, (item, idx) => {
      let classes = ['breadscrub-item']
      if (item.current) {
        classes.push('active')
      }
      if (item.url) {
        return (
          <div className={classes.join(' ')} key={`${item.name}_${idx}`}>
            <Link to={item.url}>{item.trans? t(item.name): item.name}</Link>
          </div>
        )
      } else {
        return (
          <div className="breadscrub-item" key={`${item.name}_${idx}`}>
            {item.trans? t(item.name): item.name}
          </div>
        )
      }
    })}
  </div>
)

export default withTranslation()(Breadscrub)