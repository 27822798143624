import ConstValues from  './consts'
import {setCurrentStoredLang} from '../tools/lang'

export function setLang(lang) {
  return {
    type: ConstValues.SET_LANG,
    lang
  }
}

export function setDocVersion(v) {
  return {
    type: ConstValues.SET_DOC_VERSION,
    version: v
  }
}

export function userSetLang(lang, history, location, i18n) {
  return function(dispatch) {
    // localstorage
    setCurrentStoredLang(lang)
    // redux
    dispatch(setLang(lang))
    // i18n
    i18n.changeLanguage(lang)
    // process pathname
    const pathname = location.pathname
    const mat = /^(\/(?<lang>(zh-cn|en-us|ja-jp)))?(?<main>\/.+)$/.exec(pathname)
    if (!mat) {
      return
    }
    const newPath = `/${lang}${mat.groups.main}`
    history.push(newPath)
  }
}