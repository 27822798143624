import React, {useState} from 'react'
import { useTranslation } from 'react-i18next';

const defaultRenderItem = ({fullPath, wikiId, wikiName, folderHierarchy}) => {
  function onItemClick(fullPath) {
    window.location.href = `/wiki/${fullPath}`
  }
  return (
    <div className="auto-complete-form-dropdown-item" key={wikiId} onClick={() => onItemClick(fullPath)}>
      {wikiName}
      <span className="auto-complete-form-dropdown-aux">{!!folderHierarchy? folderHierarchy.join(' > '): ''}</span>
    </div>
  )
}

const AutoComplete = ({onTextChanged, onEnter, dataSource, renderItem = defaultRenderItem}) => {
  const [searchTxt, setSearchTxt] = useState('')
  const {t} = useTranslation()
  function inputOnChange(e) {
    let textVal = e.target.value
    if (textVal === null || textVal === undefined || textVal === '') {
      textVal = ''
    }
    if (searchTxt !== textVal) {
      setSearchTxt(textVal)
      onTextChanged && onTextChanged(textVal, searchTxt)
    }
  }
  function inputOnBlur(e) {
    // 技术债：这是为了避免当input失去焦点的时候dropdown消失，从而无法点中链接
    // 但是这个不好，如果200ms之后控件unmount了呢，虽然不大可能
    setTimeout(() => {
      setSearchTxt('')
    }, 200)
  }

  function inputOnKeyDown(e) {
    if (e.keyCode == "13") {
      let textVal = e.target.value
      if (textVal === null || textVal === undefined || textVal === '') {
        return
      }
      onEnter && onEnter()
    }
  }

  let dropdownClasses = ['auto-complete-form-dropdown']
  if (searchTxt && dataSource && dataSource.length > 0) {
    dropdownClasses.push('active')
  }

  return (
    <div className="pure-form auto-complete-form ac-input">
      <input type="text" placeholder={t('wiki.searchbytitle')} 
        onChange={inputOnChange} 
        onBlur={inputOnBlur} 
        onKeyDown={inputOnKeyDown}
        value={searchTxt}/>
      <div className={dropdownClasses.join(' ')}>
        {dataSource? Array.from(dataSource, item => renderItem(item)): null}
      </div>
    </div>
  )
}

export default AutoComplete