import ConstValues from './consts'
import { get, post } from '../tools/api';
import { runtimeConfig } from '../config';
const { api_domain } = runtimeConfig;

export function getRelatedPost(labelList) {
  return async function (_, getState) {
    const url = `${api_domain}api/forum/get-related-post?labels=${labelList.join(",")}`
    // console.log(`getRelatedUnit url ${url}`)
    try {
      const data = await get(url)
      // console.log(`getRelatedUnit data ${JSON.stringify(data)}`)
      return data
    } catch (err) {
      return ''
    }
  }
}