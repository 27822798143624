import React from "react";
import { Link } from 'react-router-dom';

import iconDropdown from '../assets/dropdown-course.png';
import yes from '../assets/icon-yes.png';

import appInsights, {getGlobalParams} from '../tools/telemetry'

class CourseCategory extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    showCategory: false
  }

  componentDidMount() {
    const { collect } = this.props;

    if (!!collect) {
      this.setState({
        showCategory: !!collect
      })
    }
  }

  toggleCategory() {
    this.setState({
      showCategory: !this.state.showCategory
    })
  }

  trackClickUnit(unit) {
    appInsights.trackEvent({name: 'Academy_UserActionEvent'}, {ButtonOrMenuName: 'Unit', UnitID: `${unit.id}`, UnitName: unit.name, ...getGlobalParams()})
  }

  render() {
    const { showCategory } = this.state;
    const { cusCls, title, dataSource } = this.props;

    let classes = ["course-category"];
    if (!!cusCls) {
      classes.push(cusCls);
    }

    return (
      <div className={classes.join(' ')}>
        <div className="category">
          <span onClick={e => this.toggleCategory()} className="category-title">
            {title}
            <img className={`category-icon${showCategory ? " active" : ""}`} src={iconDropdown} />
          </span>

          <ul className={`category-wrap${showCategory ? " active" : ""}`}>
            {
              !!dataSource && dataSource.map(data => {
                return (
                  <li key={data.id} className={`category-item${data.finished ? " complete" : ""}`}>
                    <Link to={`/learn/unit-detail/${data.id}`} className="category-subtitle" onClick={() => this.trackClickUnit(data)}><span>{data.name}</span></Link>
                    <div className="time-wrap">
                      <span className="category-time">{data.timeDesc}</span>
                      <img className="category-icon" src={yes} />
                    </div>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
    )
  }
}

export default CourseCategory;
