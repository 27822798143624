import React, { Fragment } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  setFuzzySearchWord, setSearchCategory, fuzzySearch,
  fulltextSearchDocs, fuzzySearchLearnList, fuzzySearchUnitList,
  testLoggedIn, openLearnPath, openLearnCourse, likeLearnPath, likeLearnCourse
} from '../actions'
import ReactLoading from 'react-loading'
import { toast } from 'react-toastify';

import { processCourseIconUrl } from '../tools/customUtil'

import {runtimeConfig} from '../config'
const {login_url} = runtimeConfig

const PageSize = 6

class SearchResult extends React.Component {
  state = {
    dataVersion: 0
  }

  componentDidMount() {
    const { word, cat } = this.props.match.params
    const { setFuzzySearchWord, setSearchCategory, fuzzySearch, lastSearchWord } = this.props
    setFuzzySearchWord(word)
    setSearchCategory(cat)

    if (lastSearchWord !== word) {
      fuzzySearch(word)
    }
  }

  componentDidUpdate(prevProps) {
    const { word, cat } = this.props.match.params
    const { word: prevWord, cat: prevCat } = prevProps.match.params
    if (word !== prevWord || cat !== prevCat) {
      const { setFuzzySearchWord, setSearchCategory, fuzzySearch, lastSearchWord } = this.props
      setFuzzySearchWord(word)
      setSearchCategory(cat)

      if (lastSearchWord !== word) {
        fuzzySearch(word)
      }
    }
  }

  renderTags() {
    const { category, docsCount, learnsCount, setSearchCategory } = this.props
    return (
      <ul className="tags">
        <li className={category === 'docs' ? ' active' : null}
          onClick={() => setSearchCategory('docs')}>文档（{docsCount}）</li>
        <li className={category === 'learn' ? ' active' : null}
          onClick={() => setSearchCategory('learn')}>课程（{learnsCount}）</li>
      </ul>
    )
  }

  onClickSearch() {
    const { word, category, history } = this.props
    history.push(`/search/${category}/${word}`)
  }

  onKeyDown(e) {
    if (e.keyCode == 13) {
      this.onClickSearch();
    }
  }

  async testLoggedInThen(type, id) {
    const { testLoggedIn, history } = this.props;
    try {
      await testLoggedIn();
      this.toggleLikeLearn(type, id);
    } catch (err) {
      if (err.message === '用户未登录') {
        const returnUrl = window.encodeURIComponent(window.location.pathname)
        window.location = `${login_url}?returnUrl=${returnUrl}`
      } else if (err.message === '未知错误') {
        toast('未知错误');
      }
    }
  }

  toggleLikeLearn(type, id) {
    const { openLearnPath, likeLearnPath, openLearnCourse, likeLearnCourse } = this.props;

    if (type === 1) {
      openLearnPath(id).then(() => {
        likeLearnPath(id, 'searchLearn').then(() => {
          this.setState({
            dataVersion: this.state.dataVersion + 1
          })
        });
      });
    } else {
      openLearnCourse(id).then(() => {
        likeLearnCourse(id, 'searchLearn').then(() => {
          this.setState({
            dataVersion: this.state.dataVersion + 1
          })
        });
      });
    }
  }



  render() {
    const { word, category, setFuzzySearchWord } = this.props
    // const fakeUnits = []
    return (
      <div className="search-result">
        <div className="banner-area">
          <div className="search-control-wrapper">
            <div className="search-control">
              <input type="text" value={word} onChange={(e) => setFuzzySearchWord(e.target.value)} onKeyDown={e => this.onKeyDown(e)} />
              <i className="search-label" />
              <button className="search-btn" onClick={() => this.onClickSearch()}>搜 索</button>
            </div>
          </div>

        </div>
        <div className="result-container">
          {this.renderTags()}
          {category === 'docs' ? <DocResult docs={this.props.docs}
            docsLoading={this.props.docsLoading}
            docsCount={this.props.docsCount}
            docsStart={this.props.docsStart}
            fulltextSearchDocs={this.props.fulltextSearchDocs}
            lastSearchWord={this.props.lastSearchWord} /> : null}
          {category === 'learn' ? <UnitResult learns={this.props.learns}
            learnsLoading={this.props.learnsLoading}
            learnsCount={this.props.learnsCount}
            learnsStart={this.props.learnsStart}
            fuzzySearchUnitList={this.props.fuzzySearchUnitList}
            lastSearchWord={this.props.lastSearchWord}
            testLoggedInThen={(type, id) => this.testLoggedInThen(type, id)} /> : null}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const {
    docs, docsLoading, docsCount, docsStart,
    learns, learnsLoading, learnsCount, learnsStart,
    word, category, lastSearchWord
  } = state.search

  return {
    docs, docsLoading, docsCount, docsStart,
    learns, learnsLoading, learnsCount, learnsStart,
    word, category, lastSearchWord
  }
}

const mapDispatchToProps = dispatch => ({
  setFuzzySearchWord: word => dispatch(setFuzzySearchWord(word)),
  setSearchCategory: c => dispatch(setSearchCategory(c)),
  fuzzySearch: word => dispatch(fuzzySearch(word)),
  fulltextSearchDocs: (word, start) => dispatch(fulltextSearchDocs(word, start)),
  fuzzySearchLearnList: (word, start) => dispatch(fuzzySearchLearnList(word, start)),
  fuzzySearchUnitList: (word, start) => dispatch(fuzzySearchUnitList(word, start)),
  testLoggedIn: () => dispatch(testLoggedIn()),
  openLearnPath: id => dispatch(openLearnPath(id)),
  openLearnCourse: id => dispatch(openLearnCourse(id)),
  likeLearnPath: (id, ll) => dispatch(likeLearnPath(id, ll)),
  likeLearnCourse: (id, ll) => dispatch(likeLearnCourse(id, ll)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchResult))

const DocResult = ({ docs, docsLoading, docsCount, docsStart, fulltextSearchDocs, lastSearchWord }) => {
  function gotoDoc(d) {
    window.open(`/${d.lang}/wiki/${d.fullPath}?_v=${d.version}`, '_blank')
  }
  const hasGoPrev = docsStart >= PageSize
  const hasGoNext = docsStart < docsCount - PageSize

  function goPrev() {
    fulltextSearchDocs(lastSearchWord, docsStart - PageSize)
  }
  function goNext() {
    fulltextSearchDocs(lastSearchWord, docsStart + PageSize)
  }
  return (
    <Fragment>
      <div className="result-body">
        {docsLoading ? <ReactLoading type="spinningBubbles" color="lightgray" /> : null}
        {Array.from(docs, d => (
          <div className="doc-item" key={d.id} onClick={() => gotoDoc(d)}>
            <h4 dangerouslySetInnerHTML={{ __html: d.localeName }}></h4>
            <p dangerouslySetInnerHTML={{ __html: d.highlights[0] + '...' }}></p>
          </div>
        ))}
      </div>
      <div className="pagination">
        {hasGoPrev ? <button onClick={goPrev}>上一页
        </button> : null}
        {hasGoNext ? <button onClick={goNext}>下一页</button> : null}
      </div>
    </Fragment>
  )
}

const UnitResult = ({ learns, learnsLoading, learnsCount, learnsStart,
  lastSearchWord, testLoggedInThen, fuzzySearchUnitList
}) => {
  function gotoLearn(d) {
    window.open(`/learn/unit-detail/${d.id}`, '_blank')
  }
  const hasGoPrev = learnsStart >= PageSize
  const hasGoNext = learnsStart < learnsCount - PageSize

  function goPrev() {
    fuzzySearchUnitList(lastSearchWord, learnsStart - PageSize)
  }
  function goNext() {
    fuzzySearchUnitList(lastSearchWord, learnsStart + PageSize)
  }
  return (
    <Fragment>
      <div className="result-body">
        {learnsLoading ? <ReactLoading type="spinningBubbles" color="lightgray" /> : null}
        {Array.from(learns, d => (
          <div className="unit-item" key={d.id} onClick={() => gotoLearn(d)}>
            <div><img src={d.iconUrl}/></div>
            <div className="unit-detail">
              <div className="unit-title">
                <h3 dangerouslySetInnerHTML={{ __html: d.courseName }}></h3>
                <h4 dangerouslySetInnerHTML={{ __html: '第' + d.order + '单元 ' + d.name }}></h4>
              </div>
              <p dangerouslySetInnerHTML={{ __html: d.highlights[0] + '...' }}></p>
            </div>
          </div>
        ))}
      </div>
      <div className="pagination">
        {hasGoPrev ? <button onClick={goPrev}>上一页
        </button> : null}
        {hasGoNext ? <button onClick={goNext}>下一页</button> : null}
      </div>
    </Fragment>
  )
}

const LearnsResult = ({ learns, learnsLoading, learnsCount, learnsStart,
  fuzzySearchLearnList, lastSearchWord,
  testLoggedInThen
}) => {
  const hasGoPrev = learnsStart >= PageSize
  const hasGoNext = learnsStart < learnsCount - PageSize

  function goPrev() {
    fuzzySearchLearnList(lastSearchWord, docsStart - PageSize)
  }
  function goNext() {
    fuzzySearchLearnList(lastSearchWord, docsStart + PageSize)
  }

  return (
    <Fragment>
      <div className="result-body">
        {learnsLoading ? <ReactLoading type="spinningBubbles" color="lightgray" /> : null}
        <div className="g-course-list" style={{ maxWidth: '100%', width: '100%', marginLeft: 0, marginRight: 0 }}>
          {Array.from(learns, cItem => (
            <div className="g-course" key={cItem.id}>
              <div className="g-course-inner">
                <div className={`bar${cItem.type === 1 ? ' lp' : ' c'}`}></div>
                <img src={processCourseIconUrl(cItem.iconUrl)} />
                <div className="content-body">
                  <label>{cItem.type === 1 ? '学习路径' : '课 程'}</label>
                  <Link className="name" to={`${cItem.type === 1 ? '/learn/path-detail/' : '/learn/course-detail/'}${cItem.id}`}>{cItem.name}</Link>
                  <div className="txt-line">
                    <i />{cItem.timeDesc}<span className="txt-line-split">|</span>{cItem.learningCount}人在学
                  </div>
                  <div className="role-line">
                    {cItem.roleNames.filter((_, rIdx) => rIdx < 2).map((rn, rIdx) => (
                      <div className="role" key={rIdx}>{rn}</div>
                    ))}
                  </div>
                  <div className="last-row">
                    {(cItem.progress !== null && cItem.childCount > 0) ? (
                      <Fragment>
                        <div className="progress"><div className="progress-inner" style={{ width: (Math.round(cItem.progress / cItem.childCount * 100)) + '%' }}></div></div>
                        <div className="perc">{Math.round(cItem.progress / cItem.childCount * 100)}%</div>
                      </Fragment>
                    ) : null}
                    <div className={cItem.isFavorite ? "star" : "no-star"} onClick={() => testLoggedInThen(cItem.type, cItem.id)}></div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="pagination">
        {hasGoPrev ? <button onClick={goPrev}>上一页
        </button> : null}
        {hasGoNext ? <button onClick={goNext}>下一页</button> : null}
      </div>
    </Fragment>
  )
}