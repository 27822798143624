import ConstValues from './consts';

export function setWinSize(width, height) {
  return {
    type: ConstValues.SET_WINDOW_SIZE,
    size: {width, height}
  }
}

export function setWikiContainerSize(left, right, top, height) {
  console.log(`setWikiContainerSize ${left} ${right} ${top} ${height}`)
  return function(dispatch, getState) {
    const {wikiLeft, wikiRight, wikiTop, wikiHeight} = getState().winsize
    if (wikiLeft === left && wikiRight === right && wikiTop === top && wikiHeight === height) {
      return
    }
    dispatch({
      type: ConstValues.SET_WIKI_CONTAINER_SIZE,
      left, right, top, height
    })
  }
}

export function setUnitContainerSize(left, right, top, height) {
  return function(dispatch, getState) {
    const {unitLeft, unitRight, unitTop, unitHeight} = getState().winsize
    if (unitLeft === left && unitRight === right && unitTop === top && unitHeight === height) {
      return
    }
    dispatch({
      type: ConstValues.SET_UNIT_CONTAINER_SIZE,
      left, right, top, height
    })
  }
}

export function setFooterSize(footerHeight) {
  return {
    type: ConstValues.SET_FOOTER_SIZE,
    footerHeight
  }
}