import React, { Fragment } from 'react';
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { testLoggedIn, setMyCoursesNavTab, getMyMourses, likeLearnCourse, likeLearnPath } from '../../actions';
import ContentLoader from "react-content-loader";
import { toast } from 'react-toastify';

import { processCourseIconUrl } from '../../tools/customUtil'

import { runtimeConfig } from '../../config'

const { login_url } = runtimeConfig

class MyCourses extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {};

  componentDidMount() {
    const { testLoggedIn, myCoursesNavTab, getMyMourses } = this.props;

    testLoggedIn().then(() => {
      getMyMourses({ path: myCoursesNavTab });
    }).catch(err => {
      if (err.message === '用户未登录') {
        const returnUrl = window.encodeURIComponent(window.location.pathname)
        window.location = `${login_url}?returnUrl=${returnUrl}`
      } else if (err.message === '未知错误') {
        toast('未知错误');
      }
    })
  };

  toggleNavTab(navtab) {
    const { setMyCoursesNavTab, getMyMourses } = this.props;
    setMyCoursesNavTab(navtab).then(() => {
      getMyMourses({ path: navtab });
    });
  }

  learnCourse({ type, id }) {
    let url = `${type === 1 ? '/learn/path-detail/' : '/learn/course-detail/'}${id}`;
    this.props.history.push(url);
  }

  deleteCourse({ type, id }) {
    const { myCoursesNavTab, getMyMourses, likeLearnCourse, likeLearnPath } = this.props;

    if (type === 1) {
      likeLearnPath(id).then(() => {
        getMyMourses({ path: myCoursesNavTab });
      });
    } else {
      likeLearnCourse(id).then(() => {
        getMyMourses({ path: myCoursesNavTab });
      });
    }
  }

  render() {
    const {
      loading,
      myCoursesNavTab,
      myCoursesList
    } = this.props;

    return (
      <div className="profile">
        <div className="profile-container">
          <ul className="navside-list">
            {/* <li className="navside-item">
              <Link className="nav" to="/profile/mycourses">个人信息</Link>
            </li> */}
            <li className="navside-item active">
              <Link className="nav" to="/profile/mycourses">我的课程</Link>
            </li>
            <li className="navside-item">
              <Link className="nav" to="/profile/mycerts">我的证书</Link>
            </li>
          </ul>

          <section className="my-course-content">
            <ul className="navtab-list">
              <li className={`navtab-item${myCoursesNavTab === "learning" ? " active" : ""}`} onClick={e => this.toggleNavTab("learning")}>学习中</li>
              <li className={`navtab-item${myCoursesNavTab === "finished" ? " active" : ""}`} onClick={e => this.toggleNavTab("finished")}>已学完</li>
              <li className={`navtab-item${myCoursesNavTab === "favorite" ? " active" : ""}`} onClick={e => this.toggleNavTab("favorite")}>收藏</li>
            </ul>

            <div className="g-course-list my-course-list">
              {
                myCoursesList.length ?
                  Array.from(myCoursesList, cItem => (
                    <div className="g-course" key={cItem.id}>
                      <div className="g-course-inner">
                        <div className={`bar${cItem.type === 1 ? ' lp' : ' c'}`}></div>
                        <img src={processCourseIconUrl(cItem.iconUrl)} draggable="false" />
                        <div className="content-body">
                          <label>{cItem.type === 1 ? '学习路径' : cItem.type === 2 ? '课 程' : '应用场景'}</label>
                          <Link className="name" to={`${cItem.type === 1 ? '/learn/path-detail/' : '/learn/course-detail/'}${cItem.id}`}>{cItem.name}</Link>
                          {
                            myCoursesNavTab === "favorite" ?
                              <Fragment>
                                <div className="txt-line">
                                  <i />{cItem.timeDesc}<span className="txt-line-split">|</span>{cItem.learningCount}人在学
                              </div>
                                <div className="role-line">
                                  {cItem.roleNames.filter((_, rIdx) => rIdx < 2).map((rn, rIdx) => (
                                    <div className="role" key={rIdx}>{rn}</div>
                                  ))}
                                </div>
                              </Fragment> :
                              null
                          }
                          {
                            myCoursesNavTab !== "favorite" ?
                              <button className="pure-button pure-button-primary button-continue" onClick={e => this.learnCourse({ type: cItem.type, id: cItem.id })}>
                                {myCoursesNavTab === "learning" ? "继续学习" : "再次学习"}
                              </button> :
                              null
                          }
                          <div className="last-row">
                            {
                              (cItem.progress !== null && cItem.childCount > 0) ?
                                (
                                  cItem.progress >= cItem.childCount ?
                                    <Fragment>
                                      <div className="progress">
                                        <div className="progress-inner" style={{ width: '100%' }}>
                                        </div>
                                      </div>
                                      <div className="perc">100%</div>
                                    </Fragment>
                                    :
                                    <Fragment>
                                      <div className="progress">
                                        <div className="progress-inner" style={{ width: (Math.round(cItem.progress / cItem.childCount * 100)) + '%' }}>
                                        </div>
                                      </div>
                                      <div className="perc">{Math.round(cItem.progress / cItem.childCount * 100)}%</div>
                                    </Fragment>
                                ) :
                                null
                            }
                            {
                              myCoursesNavTab === "learning" ?
                                null :
                                myCoursesNavTab === "finished" ?
                                  <div className="finished">已学完</div> :
                                  <div className="delete" onClick={e => this.deleteCourse({ type: cItem.type, id: cItem.id })}></div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  )) :
                  null
              }
              {
                loading ?
                  Array.from(Array.from({ length: 4 }).map((_, idx) => idx), idx => (
                    <div key={idx} className="g-course">
                      <div className="g-course-inner">
                        <ContentLoader viewBox="0 0 276 246">
                          <circle cx="55" cy="48" r="30" />
                          <rect x="25" y="91" width="55" height="14" />
                          <rect x="25" y="115" width="85" height="15" />
                          <rect x="25" y="145" width="145" height="12" />
                          <rect x="25" y="169" width="83" height="22" />
                          <rect x="25" y="203" width="227" height="1" />
                          <rect x="25" y="215" width="227" height="12" />
                        </ContentLoader>
                      </div>
                    </div>
                  )) :
                  null
              }
            </div>
          </section>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    loading,
    myCoursesNavTab,
    myCoursesList
  } = state.profile;
  return {
    loading,
    myCoursesNavTab,
    myCoursesList
  }
}

const mapDispatchToProps = dispatch => ({
  testLoggedIn: () => dispatch(testLoggedIn()),
  setMyCoursesNavTab: navtab => dispatch(setMyCoursesNavTab(navtab)),
  getMyMourses: path => dispatch(getMyMourses(path)),
  likeLearnCourse: courseId => dispatch(likeLearnCourse(courseId)),
  likeLearnPath: pathId => dispatch(likeLearnPath(pathId)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyCourses));
