import React from 'react'
import {connect} from 'react-redux'
import {getRoles, getLevels, getTags} from '../actions'

class InitializeData extends React.Component {
  render() {
    return null
  }
  componentDidMount() {
    const {getRoles, getLevels, getTags} = this.props
    getRoles()
    getLevels()
    getTags()
  }
}

const mapDispatchToProps = dispatch => ({
  getRoles: () => dispatch(getRoles()),
  getLevels: () => dispatch(getLevels()),
  getTags: () => dispatch(getTags())
})

export default connect(null, mapDispatchToProps)(InitializeData)