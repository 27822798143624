import ConstValues from './consts'
import { get, post } from '../tools/api';
import { runtimeConfig } from '../config';
const { api_domain } = runtimeConfig;

export function getUnitDetail(unitId) {
  return async function (dispatch, getState) {
    //const {unitId: prevUnitId} = getState().units
    try {
      const data = await get(`${api_domain}api/learn-api/unit/${unitId}`);
      dispatch({
        type: ConstValues.SET_UNIT_DETAIL,
        data,
      })
      return data;
    } catch (err) {
      throw new Error(err.errorMessage)
    }
  }
}

export function submitAnswer(pair) {
  return async function (dispatch) {
    try {
      const data = await post(`${api_domain}api/learn-api/submit-answer`, pair);
      // dispatch({
      //   type: ConstValues.COMBINING_ANSWERS,
      //   data,
      // })   
      return data;
    } catch (err) {
      throw new Error(err.errorMessage)
    }
  }
}

export function finishUnit(unitId) {
  return async function (dispatch) {
    try {
      const data = await post(`${api_domain}api/learn-api/finish-unit/${unitId}`);
      return data;
    } catch (err) {
      throw new Error(err.errorMessage)
    }
  }
}

export function getRelatedUnit(labelList) {
  return async function (_, getState) {
    const url = `${api_domain}api/learnunit/get-related-unit?labels=${labelList.join(",")}`
    // console.log(`getRelatedUnit url ${url}`)
    try {
      const data = await get(url)
      // console.log(`getRelatedUnit data ${JSON.stringify(data)}`)
      return data
    } catch (err) {
      return ''
    }
  }
}