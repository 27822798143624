import {ConstValues} from '../actions'
import update from 'immutability-helper'

const initialData = {
  tree: null, //[{name, folderId, orderNum, fullPath, items: [{name, folderId, orderNum, fullPath, items}, {name, wikiId, folderId, orderNum, fullPath}]}]
  openKeys: [],
  markdown: null,
  wikiId: null,
  navInPage: null, // [{name, id, top, active}],
  labels: [],
  comments: [], // [{content, author, commentId, authorId, createTime, likes, threadCount}]
  lastUpdateTime: null
}

export default (state = initialData, action) => {
  switch(action.type) {
    case ConstValues.UPDATE_WIKI_TREE:
      return update(state, {tree: {$set: action.items}});
    case ConstValues.INITIALIZE_WIKI_TREE_OPEN_KEYS:
      return update(state, {openKeys: {$set: action.openKeys}})
    case ConstValues.TOGGLE_WIKI_TREE_FOLDER:
    {
      const {openKeys} = state
      const {key} = action
      const keyIdx = openKeys.indexOf(key)
      if (keyIdx < 0) {
        return update(state, {openKeys: {$push: [key]}})
      } else {
        return update(state, {openKeys: {$splice: [[keyIdx, 1]]}})
      }
    }
    case ConstValues.SET_WIKI_TREE_PAGE:
    {
      const {openKeys} = state
      const {key} = action
      const wikiIdx = openKeys.findIndex(k => k.endsWith('.md'))
      let currentState = state
      if (wikiIdx >= 0) {
        return update(state, {openKeys: {$splice: [[wikiIdx, 1, key]]}})
      } else {
        return update(state, {openKeys: {$push: [key]}})
      }
    }
    case ConstValues.SET_WIKI_MARKDOWN:
      return update(state, {markdown: {$set: action.text}, wikiId: {$set: action.wikiId}, labels: {$set: action.labels}, lastUpdateTime: {$set: action.lastUpdateTime}})
    case ConstValues.SET_WIKI_NAVINPAGE:
      return update(state, {navInPage: {$set: action.navInPage}})
    case ConstValues.SET_COMMENT_DATA:
      return update(state, {comments: {$set: action.data}})
    default:
      return state;
  }
}