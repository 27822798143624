import ConstValues from  './consts'
import { get, post } from '../tools/api';
import { runtimeConfig } from '../config';
const { api_domain } = runtimeConfig;

export function setCoursesCourseId(courseId) {
  return async function(dispatch, getState) {
    const {courseId: prevCourseId} = getState().courses
    if (prevCourseId !== courseId) {
      dispatch({
        type: ConstValues.COURSES_SET_COURSE_ID,
        courseId
      })
      await dispatch(getLessons())
    }
  }
}

export function setCoursesSortCondition(sortCondition) {
  return async function(dispatch, getState) {
    const {sortCondition: prevSortCondition} = getState().courses
    if (prevSortCondition !== sortCondition) {
      dispatch({
        type: ConstValues.COURSES_SET_SORT_COND,
        sortCondition
      })
      await dispatch(getLessons())
    }
  }
}

export function getLessons() {
  return async function(dispatch, getState) {
    const {courseId, sortCondition} = getState().courses
    dispatch({
      type: ConstValues.COURSES_START_SEARCH
    })
    try {
      const data = await get(`${api_domain}api/course/lessons?courseId=${courseId}&sort=${sortCondition}`);
      dispatch({
        type: ConstValues.COURSES_LESSONS_SET,
        data
      })
    } catch (err) {
      console.error(err)
    } finally {
      dispatch({
        type: ConstValues.COURSES_FINISH_SEARCH
      })
    }
  }
}

export function initializeRoadMaps() {
  return async function(dispatch, getState) {
    var roadmaps = getState().roadmap
    if (roadmaps && roadmaps.length > 0) {
      return;
    }
    try {
      const data = await get(`${api_domain}api/course/roadmaps`);
      dispatch({
        type: ConstValues.ROADMAP_SET,
        data
      })
    } catch(err) {
      console.error(err);
    }
  }
}

export function initializeSimpleCourseList() {
  return async function(dispatch, getState) {
    var courses = getState().courses.courses;
    if (courses && courses.length > 0) {
      return;
    }
    try {
      const data = await get(`${api_domain}api/course/simple-courses`);
      dispatch({
        type: ConstValues.COURSES_COURSES_SET,
        data
      })
    } catch (err) {
      console.error(err);
    }
  }
}