import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next';

import Icon_Back from '../../assets/login/icon-back.png'

import { fetchCode } from '../../actions'

class FormForgetPass extends React.PureComponent {
  constructor(props) {
    super(props)
  }
  state = {
    nextStep: false,

    phone: '',
    phoneState: false,
    code: '',
    codeState: false,

    submitValidPhone: false,
    submitValidCode: false,

    times: null
  }

  componentDidMount() {
    this.timer = null;
  }

  componentWillUnmount() {
    this.timer && clearInterval(this.timer);
  }

  onHandlePhone(e) {
    const { } = this.props;
    let phone = e.target.value.trim();
    this.setState({ phone });
    let reg = /^[1]([3-9])[0-9]{9}$/;

    if (!!phone && reg.test(phone)) {
      this.setState({
        phoneState: true,
        codeState: false,
        submitValidPhone: true
      })
    } else {
      this.setState({ phoneState: false, submitValidPhone: false });
    }
  }
  onHandleCode(e) {
    this.setState(
      { code: e.target.value.trim() }
    )
  }
  onFetchCode(e) {
    e.preventDefault();
    const { phone, phoneState, times } = this.state;
    const { fetchCode } = this.props;

    if (times) return;

    if (phone && phoneState) {
      this.setState({
        codeState: false,
        times: 60
      });
      fetchCode(phone);

      this.timer = setInterval(() => {
        const { times } = this.state;

        if (times === 0) {
          clearInterval(this.timer);
          this.timer = null;
        } else {
          this.setState({
            times: times - 1
          });
        }
      }, 1000);
    } else {
      this.setState({
        codeState: true
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const { phone, code, phoneState } = this.state;
    const { errHtml } = this.props;

    if (phone && code && phoneState && !errHtml) {

    } else {

    }
  }

  render() {
    const { errHtml, codeError } = this.props;
    const { nextStep, codeState, times } = this.state;

    return (
      <Fragment>
        <div className="title-box">
          <Link to="/auth/login"><img style={{ width: '35px' }} className="icon" src={Icon_Back} draggable="false" /></Link>
          <p className="title" style={{ lineHeight: 40 + 'px', fontWeight: 600 }}>找回密码</p>
        </div>
        <div className="login">
          <span className="text-error" dangerouslySetInnerHTML={{ __html: errHtml }}></span>

          <form className="pure-form pure-form-stacked login-form">
            <fieldset>
              <input className={codeState ? "input has-error" : "input"} id="phone" type="text" placeholder="请输入手机号码" onBlur={e => this.onHandlePhone(e)} />

              <label className="input-box">
                <input className={codeError ? "input input-mid has-error" : "input input-mid"} id="code" type="text" placeholder="请输入短信验证码" onBlur={e => this.onHandleCode(e)} />
                <button className={times ? "pure-button pure-button-primary button-code disable" : "pure-button pure-button-primary button-code"} onClick={e => this.onFetchCode(e)}>
                  {times ? "重新发送 (" + times + "S)" : "获取验证码"}
                </button>
              </label>

              <button type="submit" className="pure-button pure-button-primary button-submit" onClick={e => this.onSubmit(e)}>下一步</button>
            </fieldset>
          </form>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  let errHtml = '';
  const {
  } = state.auth;

  let codeError = false;

  // if (registerError.indexOf('验证码') >= 0) {
  //   codeError = true;
  // }

  // if (registerError.startsWith('[')) {
  //   const registerErrorArray = JSON.parse(registerError);
  //   for (const registerErrorItem of registerErrorArray) {
  //     errHtml += `<div>${registerErrorItem}</div>`;
  //   }
  // } else {
  //   errHtml = registerError;
  // }

  return {
    errHtml,
    codeError
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchCode: para => dispatch(fetchCode(para)),
  }
}

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(FormForgetPass)))