import React, { Fragment } from "react";
import { connect } from 'react-redux';
import { Link, withRouter, Route } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import * as Showdown from "showdown"
import ContentLoader from "react-content-loader";
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { getUnitDetail, submitAnswer, testLoggedIn, finishUnit, getLearnCrumbsByUnitId } from '../../actions'
import Breadscrub from '../../components/Breadscrub';
import downArrow from '../../assets/learn-unit/down-arrow.png';
import closeIcon from '../../assets/learn-unit/close.png';
import rightArrow from '../../assets/learn-unit/right-arrow.png';
import ImgCertFinish from '../../assets/cert_finish.png';
import RelatedWiki from '../../components/RelatedWiki'
import RelatedPost from '../../components/RelatedPost'
import StickyUnitSideBar from '../../components/StickyUnitiSideBar'
import ImgModal from "../../components/ImgModal";

import appInsights, { setGlobalParam, clearGlobalParams, getGlobalParams } from '../../tools/telemetry'

import { runtimeConfig } from '../../config'

const { login_url } = runtimeConfig

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
  prefixHeaderId: 'header'
});

class LearnUnitDetail extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    questions: [],
    id: '',
    showToast: false,
    show: true,
    showBigImg: false,
    bigImgSrc: '',
  }

  getUnitInfo = (str) => {
    const { id } = this.state;
    const { testLoggedIn, finishUnit } = this.props;

    this.props.getUnitDetail(id).then(res => {
      setGlobalParam("Url", window.location.href)
      setGlobalParam('PageName', 'Unit')
      setGlobalParam("UnitID", `${res.unitId}`)
      setGlobalParam("UnitName", res.name)
      setGlobalParam("CourseID", `${res.courseId}`)
      appInsights.trackEvent({ name: 'Academy_VisitedPageEvent' }, getGlobalParams())
      this.StartVisitTime = new Date()
      if (res && res.questions) {
        this.setState({
          questions: res.questions || [],
        })
      }

      if (str === 'didMount') {
        testLoggedIn().then(() => {
          if (!res.lastUnitForCertLearnPathAndNoQuiz && !res.quizId) {
            finishUnit(id).then(() => {
              res.siblings.map(unit => {
                if (unit.unitId === id * 1) {
                  unit.finished = true;
                  this.getUnitInfo();
                }
              })
            });
          }
        }).catch(err => { })
      }
    }).catch(err => {
      toast(err.message || '未知错误');
    })
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const { unitBreadsCrumbs, getLearnCrumbsByUnitId } = this.props
    this.setState({
      id,
    }, () => this.getUnitInfo('didMount'));

    getLearnCrumbsByUnitId(id).then(data => {
      let names = new Array()
      if (data.unitName) {
        names.push(data.unitName)
      }
      if (data.courseName) {
        names.push(data.courseName)
      }
      if (data.pathName) {
        names.push(data.pathName)
      }
      names.push("RPA课程")
      names.push("云扩学院")
      document.title = names.join("_")
    })
  }

  componentWillUnmount() {
    const LeavePageTime = new Date()
    const StadyTime = LeavePageTime - this.StartVisitTime
    appInsights.trackEvent({ name: 'Academy_StadyTimeEvent' }, { StartVisitTime: this.StartVisitTime, LeavePageTime, StadyTime, ...getGlobalParams() })
    clearGlobalParams()
  }

  transNumToChinese = (num) => {

    const data = {
      '0': '零',
      '1': '一',
      '2': '二',
      '3': '三',
      '4': '四',
      '5': '五',
      '6': '六',
      '7': '七',
      '8': '八',
      '9': '九',
    };
    if (num < 10) {
      return data[num]
    }
    if (num < 100) {
      let str1 = parseInt(num / 10) > 1 ? data[parseInt(num / 10)] + '十' : '十';
      let str2 = num % 10 > 0 ? data[num % 10] : '';
      return str1 + str2
    }

  }

  goPrev = () => {
    const { unitIndex, hasPrev, siblings } = this.props.unit.unitDetail;
    if (!hasPrev) return;
    const id = siblings[unitIndex - 1].unitId
    this.trackClickSwitchUnit(id)
    window.location.href = `/learn/unit-detail/${id}`;
  }

  goNext = () => {
    const { unitIndex, hasNext, siblings } = this.props.unit.unitDetail;
    if (!hasNext) return;
    const id = siblings[unitIndex + 1].unitId
    this.trackClickSwitchUnit(id)
    window.location.href = `/learn/unit-detail/${id}`;
  }

  showImg = (e) => {
    if (e.target.getAttribute('contenteditable')) {
      return;
    }
    if (e.target.nodeName === 'IMG') {
      this.setState({
        showBigImg: true,
        bigImgSrc: e.target.src
      })
    }
  }

  hideImg = () => {
    this.setState({
      showBigImg: false,
      bigImgSrc: ''
    })
  }

  goOtherPage = (id) => {
    this.trackClickSwitchUnit(id)
    window.location.href = `/learn/unit-detail/${id}`;
  }

  trackClickSwitchUnit(unitId) {
    appInsights.trackEvent({ name: 'Academy_UserActionEvent' }, { ButtonOrMenuName: 'SwitchUnit', NewUnitID: `${unitId}`, ...getGlobalParams() })
  }

  deleteKey = (item) => {
    // item.hasOwnProperty('isRight') && delete item.isRight;
    item.hasOwnProperty('correct') && delete item.correct;
    item.hasOwnProperty('tooltip') && delete item.tooltip;
  }

  handleClickCert(learnPathId) {
    this.props.history.push(`/profile/singlecert/${learnPathId}`)
  }

  onUnitPass = (finishCert, finishedLearnPathName, finishedLearnPathId) => {
    const { unitIndex, siblings, hasNext, courseId } = this.props.unit.unitDetail;
    const phrase = finishCert ? "你已完成本课程的学习，并获得证书" : "你通过了单元测试，完成了本单元的学习。"
    confirmAlert({
      customUI: ({ onClose }) => (
        <div className='unit-alert'>
          <img className='close-icon' src={closeIcon} onClick={onClose}></img>
          <h1>恭喜!</h1>
          <p>{phrase}</p>
          {finishCert ? <div className="cert-name">{finishedLearnPathName}</div> : null}
          {finishCert ? <img className="finish-cert-img" src={ImgCertFinish} /> : null}
          <button className={finishCert ? 'finish-cert-btn-normal' : ''}
            onClick={() => {
              window.location.href = `/learn/course-detail/${courseId}`
              onClose();
            }}
          >
            返回课程
          </button>
          {
            hasNext && <button className={finishCert ? 'finish-cert-btn-normal' : ''}
              onClick={() => {
                const id = siblings[unitIndex + 1].unitId
                window.location.href = `/learn/unit-detail/${id}`;
                onClose();
              }}>
              下一单元</button>
          }
          {
            finishCert ? <button onClick={() => {
              onClose()
              this.handleClickCert(finishedLearnPathId)
            }}>查看证书</button> : null
          }
        </div>
      )
    })
  }

  checkAnswer = (index, childrenIndex) => {
    const { questions } = this.state;
    if (questions[index].type == 1) {
      questions[index].children.map((item, _childrenIndex) => {
        if (childrenIndex != _childrenIndex) {
          item.isChecked = false;
          this.deleteKey(item)
        }
      })
    }
    let currentCheck = questions[index].children[childrenIndex];
    currentCheck.isChecked = !currentCheck.isChecked;
    if (!currentCheck.isChecked) {
      this.deleteKey(currentCheck)
    }
    this.setState({
      questions,
    })
  }

  setQuestionData = () => {
    let flag = true;
    let { id, questions } = this.state;
    const { unitDetail } = this.props.unit;
    const { quizId, unitId } = unitDetail;
    const { siblings } = unitDetail;
    let isPassQuiz = true;
    let details = [];
    questions.forEach(item => {
      let obj = {
        questionId: '',
        answerIds: []
      }
      obj.questionId = item.questionId;
      item.children && item.children.forEach(childItem => {
        if (childItem.isChecked) {
          obj.answerIds.push(childItem.answerId)
        }
      })
      if (obj.answerIds.length == 0) {
        flag = false;
      }
      details.push(obj)
    })
    if (!flag) {
      this.setState({
        showToast: true,
      })
      setTimeout(() => {
        this.setState({
          showToast: false,
        })
      }, 3000)
      return;
    };
    const data = {
      quizId,
      unitId,
      details,
    }
    this.props.submitAnswer(data).then(res => {
      !!res.questions && res.questions.forEach(itemA => {
        questions.forEach(itemB => {
          if (itemA.questionId == itemB.questionId) {
            itemB.children.forEach(ansItem =>
              itemA.feedbacks.forEach(FeedBackItem => {
                if (!FeedBackItem.correct) {
                  isPassQuiz = false;
                }
                if (ansItem.answerId == FeedBackItem.id) {
                  if (FeedBackItem.hasOwnProperty('correct')) {
                    // ansItem.isRight = ansItem.isChecked == FeedBackItem.correct;
                    ansItem = Object.assign(ansItem, FeedBackItem)
                  }
                }
              })
            )
          }
        })
      })
      const { learnPathHasCert, finishedLearnPathId, finishedLearnPathName } = res
      const finishCert = learnPathHasCert && finishedLearnPathId !== 0
      appInsights.trackEvent({ name: 'Academy_UserActionEvent' }, { ButtonOrMenuName: 'SubmitUnitQuiz', IsSuccess: isPassQuiz, ...getGlobalParams() })
      isPassQuiz && siblings.map(item => {
        if (item.unitId == id) {
          item.finished = true;
          this.onUnitPass(finishCert, finishedLearnPathName, finishedLearnPathId)
        }
      })
      this.setState({
        questions
      })
    }).catch(err => {
      toast(err.message || '未知错误');
    })
  }

  submit = () => {
    const { testLoggedIn } = this.props;

    testLoggedIn().then(() => {
      this.setQuestionData();
    }).catch(err => {
      if (err.message === '用户未登录') {
        const returnUrl = window.encodeURIComponent(window.location.pathname)
        window.location = `${login_url}?returnUrl=${returnUrl}`
      } else if (err.message === '未知错误') {
        toast('未知错误');
      }
    })
  }

  finished = () => {
    const { id } = this.props.match.params;
    const { testLoggedIn, finishUnit, unit } = this.props;

    testLoggedIn().then(() => {
      finishUnit(id).then(res => {
        const { learnPathHasCert, finishedLearnPathId, finishedLearnPathName } = res;
        const finishCert = learnPathHasCert && finishedLearnPathId !== 0;
        unit.unitDetail.siblings.map(item => {
          if (item.unitId === id * 1) {
            item.finished = true;
            this.getUnitInfo();
            this.onUnitPass(finishCert, finishedLearnPathName, finishedLearnPathId)
          }
        })
      }).catch(err => { });
    }).catch(err => {
      if (err.message === '用户未登录') {
        const returnUrl = window.encodeURIComponent(window.location.pathname)
        window.location = `${login_url}?returnUrl=${returnUrl}`
      } else if (err.message === '未知错误') {
        toast('未知错误');
      }
    })
  }

  render() {
    //this.onUnitPass()
    const { unitDetail } = this.props.unit;
    const { unitBreadsCrumbs } = this.props
    const { questions, showToast, feedBackQuestion } = this.state;
    const markdown = unitDetail.content;
    const labels = unitDetail.labels;
    const BreadPath = [{
      name: '首页',
      url: '/',
      trans: true,
    }, {
      name: '课程',
      url: '/learn',
      trans: true,
    }];
    if (unitBreadsCrumbs != null) {
      if (unitBreadsCrumbs.pathName) {
        BreadPath.push({ name: unitBreadsCrumbs.pathName, url: `/learn/path-detail/${unitBreadsCrumbs.pathId}`, trans: true })
      }
      BreadPath.push({ name: unitBreadsCrumbs.courseName, url: `/learn/course-detail/${unitBreadsCrumbs.courseId}`, trans: true })
      BreadPath.push({ name: unitBreadsCrumbs.unitName, url: `/learn/unit-detail/${unitBreadsCrumbs.unitId}`, trans: false })
    }

    return (
      <div style={{ backgroundColor: '#F5F6F7' }}>
        {
          !!Object.keys(unitDetail).length ? (
            <div className="learn-unit-detail">
              <div className="unit-middle">
                <Breadscrub items={BreadPath} />
                <div className="unit-container">
                  <div className={`${showToast ? 'active' : ''} toast`}>请将题目填写完整</div>
                  <div className="unit-container-action-bar">
                    <span
                      className={unitDetail.hasPrev ? "active preUnit" : "preUnit"}
                      onClick={this.goPrev}>上一单元</span>
                    <div className="current-unit">第{this.transNumToChinese(unitDetail.unitIndex + 1)}单元（共{unitDetail.siblings && this.transNumToChinese(unitDetail.siblings.length)}单元）
                <img className='down-arrow' src={downArrow} />
                      <div className="menu">
                        <p className="menu-title">{unitDetail.name}</p>
                        <ul className="item-box">
                          {
                            unitDetail.siblings && unitDetail.siblings.map(item => {
                              return (
                                <li
                                  className={item.finished ? "active" : "otherPage"}
                                  key={item.unitId}
                                  onClick={() => { this.goOtherPage(item.unitId) }}>
                                  <span>{item.name}</span><span className={`item-time ${item.finished ? 'active' : ''} `}>{item.timeDesc}</span></li>
                              )
                            })
                          }
                        </ul>
                      </div>
                    </div>
                    <span className={unitDetail.hasNext ? "active nextUnit" : "nextUnit"} onClick={this.goNext}>下一单元</span>
                  </div>
                  <div className="unit-main">
                    <h1 className="unit-main-title">{unitDetail.name}</h1>
                    <p className="unit-main-time">{unitDetail.timeDesc}</p>
                    <div className="mde-preview">
                      <div className="mde-preview-content">
                        <div className="unit-main-content" dangerouslySetInnerHTML={{ __html: converter.makeHtml(markdown) }} onClick={e => this.showImg(e)}></div>
                      </div>
                    </div>
                  </div>
                  {
                    unitDetail.lastUnitForCertLearnPathAndNoQuiz ?
                      (
                        <div className="unit-test">
                          <h1 className="title">完成单元测试</h1>
                          <p className="sumbit-button" onClick={this.finished}>完成测试</p>
                        </div>
                      ) :
                      null
                  }
                  {
                    unitDetail.quizId ?
                      (
                        <div className="unit-test">
                          <h1 className="title">单元测试</h1>
                          {
                            questions.map((item, index) =>
                              <div className="question-box" key={item.questionId}>
                                <div className="quesetion">
                                  {index + 1}. <div style={{ marginLeft: "4px" }} dangerouslySetInnerHTML={{ __html: item.text }}></div>
                                  {
                                    item.type == 2 && <span style={{ color: '#1263ea', marginLeft: '10px' }}>多选</span>
                                  }
                                </div>
                                {
                                  !!item.children && item.children.map((childrenItem, childrenIndex) =>
                                    <div className="answer-box" key={childrenItem.answerId}>
                                      <div className={`${childrenItem.isChecked ? 'active' : ''}  ${childrenItem.hasOwnProperty('correct') && (item.type == 2 || childrenItem.isChecked) ? 'showCorrect-' + childrenItem.correct : ''} answer`} onClick={() => { this.checkAnswer(index, childrenIndex) }}>
                                        <span className='lable'></span>
                                        <div style={{ marginLeft: "4px", maxWidth: "95%" }} dangerouslySetInnerHTML={{ __html: childrenItem.text }}></div>
                                        {/* <span className="text">{childrenItem.text}</span> */}
                                      </div>
                                      {
                                        childrenItem.hasOwnProperty('correct') && (item.type == 2 || childrenItem.isChecked) && <p className={`tooltip ${'tooltip-' + childrenItem.correct}`}>{childrenItem.correct ? '正确' : '不正确'}：{childrenItem.tooltip}</p>
                                      }
                                    </div>
                                  )
                                }

                              </div>
                            )
                          }
                          <p className="sumbit-button" onClick={this.submit}>提交测试</p>
                        </div>
                      ) :
                      null
                  }
                  <div className="unit-container-action-footer">
                    <span className={unitDetail.hasNext ? "active nextUnit" : "nextUnit"} onClick={this.goNext}>下一单元</span>
                  </div>
                </div>
              </div>
              <div className="unit-right">
                <StickyUnitSideBar labels={labels}>
                </StickyUnitSideBar>
              </div>
              <ImgModal showModal={this.state.showBigImg} modalTitle="图片预览" onHandleClose={this.hideImg} src={this.state.bigImgSrc} />
            </div>
          ) : (
            <div className="learn-unit-detail">
              <div className="course-container" style={{ display: 'block', height: '30' }}>
                <ContentLoader viewBox="0 0 960 40">
                  <rect x="0" y="0" width="100" height="18" />
                  <rect x="430" y="0" width="200" height="18" />
                  <rect x="860" y="0" width="100" height="18" />
                </ContentLoader>
              </div>
              <div className="unit-main">
                <ContentLoader viewBox="0 0 960 1000">
                  <rect x="0" y="0" width="400" height="28" />
                  <rect x="0" y="45" width="80" height="18" />
                  <rect x="0" y="80" width="960" height="50" />
                  <rect x="0" y="150" width="180" height="18" />
                  <rect x="0" y="185" width="960" height="100" />
                  <rect x="0" y="305" width="200" height="18" />
                  <rect x="0" y="340" width="960" height="100" />
                  <rect x="0" y="460" width="150" height="18" />
                  <rect x="0" y="500" width="960" height="50" />
                  <rect x="0" y="570" width="250" height="18" />
                  <rect x="0" y="610" width="960" height="200" />
                  <rect x="0" y="830" width="100" height="18" />
                  <rect x="0" y="865" width="960" height="150" />
                </ContentLoader>
              </div>
            </div>
          )
        }

      </div>
    )
  }
}

const mapStateToProps = state => ({
  unit: state.unit,
  loggedIn: state.global.loggedIn,
  unitBreadsCrumbs: state.learnCrumbs.unitBreadsCrumbs
})

const mapDispatchToProps = (dispatch) => ({
  finishUnit: unitId => dispatch(finishUnit(unitId)),
  getUnitDetail: id => dispatch(getUnitDetail(id)),
  submitAnswer: pair => dispatch(submitAnswer(pair)),
  testLoggedIn: pair => dispatch(testLoggedIn(pair)),
  getLearnCrumbsByUnitId: unitId => dispatch(getLearnCrumbsByUnitId(unitId))
})

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LearnUnitDetail)))