import { ConstValues } from '../actions';
import update from 'immutability-helper';

const initialData = {
  loading: false,
  profile: {
    username: "",
    avatar: ""
  },
  myCoursesNavTab: "learning", // learning finished favorite
  myCoursesList: [],
  certs: []
}

export default (state = initialData, action) => {
  switch (action.type) {
    case ConstValues.PROFILE_SET:
      return update(state, { profile: { $set: action.data } });
    case ConstValues.PROFILE_SET_COURSES_NAVTAB:
      return update(state, { myCoursesNavTab: { $set: action.navtab } });
    case ConstValues.PROFILE_START_LOADING_COURSES_LIST:
      return update(state, { myCoursesList: { $set: [] } }, { loading: { $set: true } });
    case ConstValues.PROFILE_SET_COURSES_LIST:
      return update(state, { myCoursesList: { $set: action.list } });
    case ConstValues.PROFILE_FINISHED_LOADING_COURSES_LIST:
      return update(state, { loading: { $set: false } });
    case ConstValues.GET_CERTS:
      return update(state, {certs: {$set: action.data}})
    default:
      return state;
  }
}
