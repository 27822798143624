import ConstValues from './consts'
import { get, post } from '../tools/api';
import { runtimeConfig } from '../config';
const { api_domain } = runtimeConfig;


export function getRoles() {
  return async function (dispatch, getState) {
    const { roles } = getState().LL
    if (roles && roles.length > 0) {
      return
    }
    const url = `${api_domain}api/learn-api/roles`
    try {
      const roles = await get(url)
      dispatch({
        type: ConstValues.LEARN_GET_ROLES,
        roles
      })
    } catch (err) {
      console.error(err)
    }
  }
}

export function getLevels() {
  return async function (dispatch, getState) {
    const { levels } = getState().LL
    if (levels && levels.length > 0) {
      return
    }
    const url = `${api_domain}api/learn-api/levels`
    try {
      const levels = await get(url)
      dispatch({
        type: ConstValues.LEARN_GET_LEVELS,
        levels
      })
    } catch (err) {
      console.error(err)
    }
  }
}

export function getTags() {
  return async function (dispatch, getState) {
    const { tags } = getState().LL
    if (tags && tags.length > 0) {
      return
    }
    const url = `${api_domain}api/learn-api/tags`
    try {
      const tags = await get(url)
      dispatch({
        type: ConstValues.LEARN_GET_TAGS,
        tags
      })
    } catch (err) {
      console.error(err)
    }
  }
}

export function setLearnRoleIds(roleIds) {
  return {
    type: ConstValues.LEARN_SET_ROLEIDS,
    roleIds
  }
}

export function setLearnLevel(level) {
  return {
    type: ConstValues.LEARN_SET_LEVEL,
    level
  }
}

export function setLearnType(type) {
  return {
    type: ConstValues.LEARN_SET_TYPE,
    _type: type
  }
}

export function setLearnTagIds(tagIds) {
  return {
    type: ConstValues.LEARN_SET_TAGIDS,
    tagIds
  }
}

export function setLearnName(name) {
  let innerSearch = name ? true : false;

  return {
    type: ConstValues.LEARN_SET_NAME,
    name,
    innerSearch
  }
}

export function getLearnList(name, roleIds, level, type, tagIds, pageIdx, pageSize) {
  return async function (dispatch, getState) {
    const { loading } = getState().LL
    if (loading) {
      return
    }
    dispatch({
      type: ConstValues.LEARN_START_LOADING_LIST
    })
    // compose url
    let url = `${api_domain}api/learn-api/landing-list?name=${name}&level=${level}&type=${type}&start=${pageIdx * pageSize}&pageSize=${pageSize}`
    if (roleIds.length > 0) {
      for (const roleId of roleIds) {
        url += `&roleIds=${roleId}`
      }
    }
    if (tagIds.length > 0) {
      for (const tagId of tagIds) {
        url += `&tagIds=${tagId}`
      }
    }
    // request
    try {
      const data = await get(url)
      dispatch({
        type: ConstValues.LEARN_FINISH_LOADING_LIST,
        pageIdx,
        count: data.count,
        list: data.list
      })
    } catch (err) {
      console.error('req', err)
      dispatch({
        type: ConstValues.LEARN_FINISH_LOADING_LIST,
        pageIdx,
        count: 0,
        list: []
      })
    }
  }
}

export function makeSureLearnListInitialized(pageSize) {
  return async function (dispatch, getState) {
    const { name, roleIds, level, type, tagIds, pageIdx, dataByPage } = getState().LL
    const idx = dataByPage.findIndex(p => p.pageIdx === pageIdx)
    if (idx < 0) {
      await dispatch(getLearnList(name, roleIds, level, type, tagIds, pageIdx, pageSize))
    }
  }
}

export function loadNextLearnList(pageSize) {
  return async function (dispatch, getState) {
    const { name, roleIds, level, type, tagIds, pageIdx, count } = getState().LL
    if ((pageIdx + 1) * pageSize >= count) {
      return
    }
    await dispatch(getLearnList(name, roleIds, level, type, tagIds, pageIdx + 1, pageSize))
  }
}

export function reloadLearnListByConditionChange(pageSize) {
  return async function (dispatch, getState) {
    dispatch({
      type: ConstValues.LEARN_CLEAR_LIST
    })
    const { name, roleIds, level, type, tagIds, pageIdx } = getState().LL
    await dispatch(getLearnList(name, roleIds, level, type, tagIds, pageIdx, pageSize))
  }
}

export function getLearnPathPath(learnPathName) {
  return async function(dispatch, getState) {
    let url = `${api_domain}api/learn-api/learnpath-path?learnPathName=${learnPathName}`
    try {
      const data = await get(url)
      return data
    } catch (err) {
      console.error(err)
      return null
    }
  }
}

export function initLearnLandingCondition(roleName) {
  return {
    type: ConstValues.INIT_LEARN_LANDING_CONDITION,
    roleName
  }
}