export default {
  SET_LOGGED_IN: 'SET_LOGGED_IN',
  SET_LOG_OUT: 'SET_LOG_OUT',
  LOGIN_START: 'LOGIN_START',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILED: 'LOGIN_FAILED',
  LOGIN_VALID_USERNAME: "LOGIN_VALID_USERNAME",

  REGISTER_START: 'REGIST_START',
  REGISTER_SUCCESS: 'REGIST_SUCCESS',
  REGISTER_FAILED: 'REGIST_FAILED',
  CODE_START: 'CODE_START',
  CODE_SUCCESS: 'CODE_SUCCESS',
  CODE_FAILED: 'CODE_FAILED',
  REGISTER_VALID_USERNAME_SUCCESS: 'REGISTER_VALID_USERNAME_SUCCESS',
  REGISTER_VALID_USERNAME_FAILED: 'REGISTER_VALID_USERNAME_FAILED',
  REGISTER_VALID_EMAIL_SUCCESS: 'REGISTER_VALID_EMAIL_SUCCESS',
  REGISTER_VALID_EMAIL_FAILED: 'REGISTER_VALID_EMAIL_FAILED',
  REGISTER_VALID_PHONE_SUCCESS: 'REGISTER_VALID_PHONE_SUCCESS',
  REGISTER_VALID_PHONE_FAILED: 'REGISTER_VALID_PHONE_FAILED',
  REGISTER_VALID_CODE: 'REGISTER_VALID_CODE',
  REGISTER_VALID_PASSWORD: 'REGISTER_VALID_PASSWORD',
  REGISTER_VALID_REPASSWORD: 'REGISTER_VALID_REPASSWORD',
  REGISTER_VALID_READ: 'REGISTER_VALID_READ',
  TOGGLE_PRIVACY: 'TOGGLE_PRIVACY',

  UPDATE_WIKI_TREE: "UPDATE_WIKI_TREE",
  INITIALIZE_WIKI_TREE_OPEN_KEYS: "INITIALIZE_WIKI_TREE_OPEN_KEYS",
  TOGGLE_WIKI_TREE_FOLDER: "TOGGLE_WIKI_TREE_FOLDER",
  SET_WIKI_TREE_PAGE: "SET_WIKI_TREE_PAGE",
  SET_WIKI_MARKDOWN: "SET_WIKI_MARKDOWN",
  SET_WIKI_NAVINPAGE: "SET_WIKI_NAVINPAGE",

  SET_WINDOW_SIZE: "SET_WINDOW_SIZE",
  SET_WIKI_CONTAINER_SIZE: "SET_WIKI_CONTAINER_SIZE",
  SET_UNIT_CONTAINER_SIZE: "SET_UNIT_CONTAINER_SIZE",
  SET_FOOTER_SIZE: "SET_FOOTER_SIZE",

  SET_BREADSCRUMBS: "SET_BREADSCRUMBS",

  SET_COMMENT_DATA: "SET_COMMENT_DATA",

  SET_THREADS: "SET_THREADS",
  SET_LOGGEDIN: "SET_LOGGEDIN",
  SET_ADMIN_LOGGEDIN: "SET_ADMIN_LOGGEDIN",

  SET_LANG: "SET_LANG",
  SET_DOC_VERSION: "SET_DOC_VERSION",
  SET_DOC_VERSIONS: "SET_DOC_VERSIONS",

  COURSES_COURSES_SET: "COURSES_COURSES_SET",
  COURSES_SET_COURSE_ID: "COURSES_SET_COURSE_ID",
  COURSES_SET_SORT_COND: "COURSES_SET_SORT_COND",
  COURSES_START_SEARCH: "COURSES_START_SEARCH",
  COURSES_FINISH_SEARCH: "COURSES_FINISH_SEARCH",
  COURSES_LESSONS_SET: "COURSES_LESSONS_SET",

  ROADMAP_SET: "ROADMAP_SET",

  START_SEARCH_DOCS: "START_SEARCH_DOCS",
  FINISH_SEARCH_DOCS: "FINISH_SEARCH_DOCS",
  START_SEARCH_LEARN: "START_SEARCH_LEARN",
  FINISH_SEARCH_LEARN: "FINISH_SEARCH_LEARN",
  SET_FUZZY_SEARCH_WORD: "SET_FUZZY_SEARCH_WORD",
  SET_SEARCH_CATEGORY: "SET_SEARCH_CATEGORY",
  START_FUZZY_SEARCH_GENERIC: "START_FUZZY_SEARCH_GENERIC",
  FINISH_FUZZY_SEARCH_GENERIC: "FINISH_FUZZY_SEARCH_GENERIC",

  RESET_SEARCH: "RESET_SEARCH",
  SET_MOBILE_CONFIRM_WORD: "SET_MOBILE_CONFIRM_WORD",

  SET_UNIT_DETAIL: "SET_UNIT_DETAIL",
  LEARN_GET_ROLES: "LEARN_GET_ROLES",
  LEARN_GET_LEVELS: "LEARN_GET_LEVELS",
  LEARN_GET_TAGS: "LEARN_GET_TAGS",
  LEARN_SET_ROLEIDS: "LEARN_SET_ROLEIDS",
  LEARN_SET_LEVEL: "LEARN_SET_LEVEL",
  LEARN_SET_TYPE: "LEARN_SET_TYPE",
  LEARN_SET_TAGIDS: "LEARN_SET_TAGIDS",
  LEARN_SET_NAME: "LEARN_SET_NAME",
  LEARN_START_LOADING_LIST: "LEARN_START_LOADING_LIST",
  LEARN_FINISH_LOADING_LIST: "LEARN_FINISH_LOADING_LIST",
  LEARN_CLEAR_LIST: "LEARN_CLEAR_LIST",
  SET_QUESTIONS: "SET_QUESTIONS",
  COMBINING_ANSWERS: "COMBINING_ANSWERS",
  REFRESH_LL_LIST: "REFRESH_LL_LIST",
  REFRESH_SL_LIST: "REFRESH_SL_LIST",

  LEARN_SET_UNIT_CRUMBS: "LEARN_SET_UNIT_CRUMBS",
  LEARN_SET_COURSE_CRUMBS: "LEARN_SET_COURSE_CRUMBS",
  LEARN_SET_PATH_DETAIL: "LEARN_SET_PATH_DETAIL",

  PROFILE_SET: "PROFILE_SET",
  PROFILE_SET_COURSES_NAVTAB: "PROFILE_SET_COURSES_NAVTAB",
  PROFILE_START_LOADING_COURSES_LIST: "PROFILE_START_LOADING_COURSES_LIST",
  PROFILE_SET_COURSES_LIST: "PROFILE_SET_COURSES_LIST",
  PROFILE_FINISHED_LOADING_COURSES_LIST: "PROFILE_FINISHED_LOADING_COURSES_LIST",

  SEND_NEW_COURSES: "SEND_NEW_COURSES",
  GET_CERTS: "GET_CERTS",

  INIT_LEARN_LANDING_CONDITION: "INIT_LEARN_LANDING_CONDITION",
}