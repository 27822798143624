import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next';

import Welcome from '../../assets/login/login-welcome-log.png';
import Icon_Username from '../../assets/login/email.png';
import Icon_Password from '../../assets/login/password.png';
import Icon_open_pwd from '../../assets/login/open-pwd.png';
import Icon_close_pwd from '../../assets/login/close-pwd.png';

import { login, validUserName } from '../../actions';
import { runtimeConfig } from '../../config'

import appInsights, { setGlobalParam, clearGlobalParams, getGlobalParams } from '../../tools/telemetry'

const prod_env = runtimeConfig.prod_env

class FormLogin extends React.PureComponent {
  constructor(props) {
    super(props)
    this.returnUrl = '/'
  }
  state = {
    username: null,
    password: null,
    remember: true,
    showPwd: false,
    passwordType: 'password'
  }

  componentDidMount() {
    setGlobalParam("Url", window.location.href)
    setGlobalParam('PageName', 'Login')
    appInsights.trackEvent({ name: 'Academy_VisitedPageEvent' }, getGlobalParams())
    this.StartVisitTime = new Date()
    const { search } = this.props.location;
    if (search) {
      if (search.startsWith('?')) {
        const searchList = search.substr(1).split('&');
        const query = {};
        for (const item of searchList) {
          const itemArray = item.split('=');
          if (itemArray.length != 2) {
            continue;
          }
          query[itemArray[0]] = itemArray[1];
        }
        if (query.returnUrl) {
          this.returnUrl = window.decodeURIComponent(query.returnUrl);
        }
      }
    }

    document.addEventListener("keydown", this.handleEnter);
  }

  componentWillUnmount() {
    const LeavePageTime = new Date()
    const StadyTime = LeavePageTime - this.StartVisitTime
    appInsights.trackEvent({ name: 'Academy_StadyTimeEvent' }, { StartVisitTime: this.StartVisitTime, LeavePageTime, StadyTime, ...getGlobalParams() })
    clearGlobalParams()
    document.removeEventListener("keydown", this.handleEnter);
  }

  handleEnter = (e) => {
    if (e.keyCode === 13) {
      this.onSubmit();
    }
  }

  onHandleUserName(e) {
    let string = e.target.value.trim();
    this.setState({ username: string });
    this.props.validUserName(string);
  }
  onHandlePassword(e) {
    this.setState({ password: e.target.value.trim() });
  }
  onHandleRememberMe(e) {
    this.setState({
      remember: e.target.checked
    });
  }

  onSubmit(e) {
    if (e !== undefined) {
      e.preventDefault();
    }

    const { login } = this.props;
    const { username, password, remember } = this.state;

    if (!username) {
      this.setState({ username: '' });
    }
    if (!password) {
      this.setState({ password: '' });
    }
    login({ username, password, remember, returnUrl: this.returnUrl });
  }

  changePwdDisplayed = () => {
    this.setState(state => ({
      showPwd: !state.showPwd
    }))
  }

  trackClickForget() {
    appInsights.trackEvent({ name: 'Academy_UserActionEvent' }, { ButtonOrMenuName: 'Forget', ...getGlobalParams() })
  }

  render() {
    const { loginErrorArray, t } = this.props;
    const { username, password, remember, showPwd } = this.state;
    let _loginErrorArray = [...loginErrorArray];

    if (password === '') {
      _loginErrorArray.unshift(t('请输入密码'));
    }
    if (username === '') {
      _loginErrorArray.unshift(t('请输入邮箱/手机号'));
    }

    return (
      <Fragment>
        <div className="title-box">
          <img className="icon" src={Welcome} draggable="false" />
          {/* <p className="title"><span style={{ fontWeight: '600' }}>{t('login.logintitle')}</span></p> */}
        </div>
        <div className="login">
          {
            _loginErrorArray.length ?
              <span className="text-error" dangerouslySetInnerHTML={{ __html: _loginErrorArray.length ? t(_loginErrorArray[0]) : '' }}></span>
              :
              null
          }

          <form className="pure-form pure-form-stacked login-form">
            <fieldset>
              <div className="position-relative">
                <input style={{ paddingLeft: '55px' }} className={(username === '') ? "input has-error" : "input"} id="username" type="text" placeholder="邮箱/手机号" autoComplete="off" onChange={e => this.onHandleUserName(e)} />
                <span className="icon-before"><img src={Icon_Username} draggable="false" /></span>
              </div>
              <div className="position-relative">
                <input style={{ paddingLeft: '55px' }} className={password === '' ? "input has-error" : "input"} id="password" type={showPwd ? 'text' : 'password'} placeholder="密码" onChange={e => this.onHandlePassword(e)} onKeyDown={this.handleEnter} />
                <img src={showPwd ? Icon_open_pwd : Icon_close_pwd} className='pwd-icon' onClick={this.changePwdDisplayed} />
                <span className="icon-before"><img src={Icon_Password} draggable="false" /></span>
              </div>
              <div className="pure-checkbox display-flex space-between">
                <label>
                  <input className="checked" checked={remember} id="remember" type="checkbox" onChange={e => this.onHandleRememberMe(e)} /> <span className="remember">自动登录</span>
                </label>
                {/* <Link className="forget" to="/auth/forget">{t('login.forgot')}</Link> */}
                <a className="forget" href={prod_env ? `https://console.encoo.com/#/user/retrievePassword` : `https://preview-console.encoo.com/#/user/retrievePassword`} target="_blank" onClick={() => this.trackClickForget()}>{t('login.forgot')}</a>
              </div>

              <button type="submit" className="pure-button pure-button-primary button-submit" onClick={(e) => this.onSubmit(e)}>{t('login.login')}</button>
            </fieldset>

            <p className="subscript">{t('login.notuser')}<Link className="button-register" to="/auth/register">{t('login.register')}</Link></p>
          </form>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  const { loginError } = state.auth;
  let loginErrorArray = [];
  if (loginError.startsWith('[')) {
    loginErrorArray = JSON.parse(loginError);
  } else if (loginError) {
    loginErrorArray.push(loginError);
  }

  return {
    loginLoading: state.auth.loginLoading,
    loginErrorArray
  }
}

const mapDispatchToProps = (dispatch) => ({
  login: pair => dispatch(login(pair)),
  validUserName: username => dispatch(validUserName(username))
})

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(FormLogin)))
