import { ConstValues } from '../actions'
import update from 'immutability-helper'

const initialData = {
  courseBreadsCrumbs: {},
  unitBreadsCrumbs: {}
}

export default (state = initialData, action) => {
  switch (action.type) {
    case ConstValues.LEARN_SET_COURSE_CRUMBS:
      {
        return update(state, { courseBreadsCrumbs: { $set: action.data } })
      }
    case ConstValues.LEARN_SET_UNIT_CRUMBS:
      {
        return update(state, { unitBreadsCrumbs: { $set: action.data } })
      }
    default:
      return state;
  }
}