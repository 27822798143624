import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from './i18n/index'
import {getCurrentStoredLang, applyStoredLangIfEmpty} from './tools/lang'

let lang = 'zh-cn'
if (typeof window !== 'undefined') {
  localStorage.removeItem('lang') // TODO: remove this line to enable localization
  applyStoredLangIfEmpty()
  lang = getCurrentStoredLang()
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: lang.substr(0, 2),

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;
