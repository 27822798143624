import React from 'react'

class EventBus extends React.Component {
  constructor(props) {
    super(props)
    this.listenClick = this.listenClick.bind(this)
  }
  menuClasses = ['hamburger-menu', 'adv-search']
  listenClick(e) {
    const event = new CustomEvent('menu-close', {})
    for (const menuCls of this.menuClasses) {
      const els = document.getElementsByClassName(menuCls)
      if (els.length === 0) {
        continue
      }
      els[0].dispatchEvent(event)
    }
  }

  render() {
    return <div onClick={this.listenClick} className="event-bus">
      {this.props.children}
    </div>
  }
}

export default EventBus