import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import RelatedUnit from '../components/RelatedUnit'
import RelatedPost from '../components/RelatedPost'

class StickyWikiSideBar extends React.PureComponent {
  constructor(props) {
    super(props)
    this.onScroll = this.onScroll.bind(this);
    this.containerRef = React.createRef();
  }
  state = {
    fixed: false,
    dock: false,
    muted: false,
    reduceHeight: null,
    stateLabels: null
  }
  onResetHeight() {
    const pageYOffset = window.pageYOffset;
    const { wikiTop } = this.props
    if (!wikiTop) {
      return
    }
    if (pageYOffset < wikiTop) {
      this.setState({
        reduceHeight: wikiTop - pageYOffset
      })
    } else {
      this.setState({
        reduceHeight: null
      })
    }
  }
  onScroll() {
    this.onResetHeight();
    const pageYOffset = window.pageYOffset;
    if (!this.props.wikiTop) {
      return;
    }
    const { wikiHeight } = this.props
    const el = this.containerRef.current
    if (el) {
      if (wikiHeight === el.clientHeight) {
        this.setState({
          muted: true
        })
      } else {
        this.setState({
          muted: false
        })
      }
    }
    if (pageYOffset > this.props.wikiTop) {
      let dock = false;
      if (!!el) {
        const yMaxThreshold = wikiHeight - el.clientHeight + this.props.wikiTop;
        if (pageYOffset > yMaxThreshold) {
          dock = true
        }
      }
      this.setState({
        fixed: true,
        dock
      })
    } else {
      this.setState({
        fixed: false,
        dock: false
      })
    }
  }
  componentDidMount() {
    this.onResetHeight();
    window.addEventListener('scroll', this.onScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }
  componentDidUpdate(prevProps) {
    const { wikiTop, wikiHeight } = this.props
    const { wikiTop: prevWikiTop, wikiHeight: prevWikiHeight } = prevProps
    if (wikiTop !== prevWikiTop || wikiHeight !== prevWikiHeight) {
      this.onScroll()
    }
  }
  render() {
    const { float, children, width, wikiLeft, wikiRight, labels } = this.props
    const { fixed, dock, muted, reduceHeight, stateLabels } = this.state
    this.setState({
      stateLabels:labels
    })
    if (muted || !fixed || wikiLeft === null || !width) {
      var style = {}
    } else if (!dock) {
      style = float === 'right' ? {
        position: 'fixed',
        top: 0,
        right: `${wikiRight}px`
      } : {
          position: 'fixed',
          top: 0,
          left: `${wikiLeft}px`
        }
    } else {
      style = {
        position: 'absolute',
        bottom: 0
      }
      if (float === 'right') {
        style.right = 0
      } else {
        style.left = 0
      }
    }
    if (!!reduceHeight) {
      style.maxHeight = `calc(100vh - ${reduceHeight}px)`
    }

    return (
      <div className="sticky-container" style={style} ref={this.containerRef}>
        {children}
        {
          float === 'right' ?
            <Fragment>
              <hr className="sidebar_hr" />
              <RelatedUnit labels={stateLabels} />
              <hr className="sidebar_hr" />
              <RelatedPost labels={stateLabels} />
            </Fragment>
            : null
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  wikiTop: state.winsize.wikiTop,
  wikiLeft: state.winsize.wikiLeft,
  wikiRight: state.winsize.wikiRight,
  wikiHeight: state.winsize.wikiHeight,
  width: state.winsize.width
})

export default connect(mapStateToProps)(StickyWikiSideBar)