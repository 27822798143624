import React from 'react';
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next';
import Breadscrub from '../components/Breadscrub'

import team1 from '../assets/team-list/team1.png';
import team2 from '../assets/team-list/team2.png';
import team3 from '../assets/team-list/team3.png';
import team4 from '../assets/team-list/team4.png';
import team5 from '../assets/team-list/team5.png';
import team6 from '../assets/team-list/team6.png';
import team7 from '../assets/team-list/team7.png';
import team8 from '../assets/team-list/team8.png';
import team9 from '../assets/team-list/team9.png';
import team10 from '../assets/team-list/team10.png';

class TeamList extends React.PureComponent {
  constructor(props) {
    super(props)
  }
  state = {
  }

  componentDidMount() {
  }

  render() {
    const { t } = this.props;

    return (
      <div style={{ backgroundColor: '#F6F7F8' }}>
        <div className="clp-content-area">
          <Breadscrub items={[{ name: '首页', url: '/', trans: true }, { name: '讲师团队', url: '/teamlist', trans: false }]} />
          <div className="clp-container">
            <div className="clp-attachment">
              <ul className="attachment">
                <li className="attachment__item">
                  <div className="attachment__icon icon_certification"></div>
                  <Link className="attachment__title" to="/courses">课程库</Link>
                </li>
                <li className="attachment__item">
                  <div className="attachment__icon icon_learning_path"></div>
                  <Link className="attachment__title" to="/roadmap">学习路径</Link>
                </li>
                <li className="attachment__item">
                  <div className="attachment__icon icon_lecturers"></div>
                  <Link className="attachment__title" to="/teamlist">讲师团队</Link>
                </li>
                <li className="attachment__item">
                  <div className="attachment__icon icon_certification"></div>
                  <Link className="attachment__title" to="/certs">认证体系</Link>
                </li>
              </ul>
            </div>
            <div className="team-list-content">
              <div className="team__item">
                <div className="left">
                  <p className="title">讲师 · 无忌</p>
                  <p className="description">
                    RPA 布道者<br />
                    致力于 RPA 让每个企业更有效率<br />
                    让每个人更有价值
                  </p>
                </div>
                <div className="right">
                  <div className="shadow">
                    <img src={team1} className="team_people"></img>
                  </div>
                </div>
              </div>
              <div className="team__item">
                <div className="left">
                  <p className="title">讲师 · Q</p>
                  <p className="description">
                    资深软件自动化专家<br />
                    具有丰富的软件自动化<br />
                    分布式调度<br />
                    与高可靠性企业云服务研发经验
                  </p>
                </div>
                <div className="right">
                  <div className="shadow">
                    <img src={team2} className="team_people"></img>
                  </div>
                </div>
              </div>
              <div className="team__item">
                <div className="left">
                  <p className="title">讲师 · 世超</p>
                  <p className="description">
                    全球知名的 RPA 与 AI 研发专家<br />
                    赋予 RPA 以大脑<br />
                    打造更具智慧性的 RPA 产品
                  </p>
                </div>
                <div className="right">
                  <div className="shadow">
                    <img src={team3} className="team_people"></img>
                  </div>
                </div>
              </div>
              <div className="team__item">
                <div className="left">
                  <p className="title">讲师 · 缘督</p>
                  <p className="description">
                    云扩高级研发总监<br />
                    原微软 HPC<br />
                    阿里云 E-HPC 核心开发团队成员
                  </p>
                </div>
                <div className="right">
                  <div className="shadow">
                    <img src={team4} className="team_people"></img>
                  </div>
                </div>
              </div>
              <div className="team__item">
                <div className="left">
                  <p className="title">讲师 · Robin</p>
                  <p className="description">
                    RPA 梦想家<br />
                    致力于让技术为人类创造更多价值
                  </p>
                </div>
                <div className="right">
                  <div className="shadow">
                    <img src={team5} className="team_people"></img>
                  </div>
                </div>
              </div>
              <div className="team__item">
                <div className="left">
                  <p className="title">讲师 · 小豆</p>
                  <p className="description">
                    天匠智能控制台核心开发人员<br />
                    运维负责人
                  </p>
                </div>
                <div className="right">
                  <div className="shadow">
                    <img src={team6} className="team_people"></img>
                  </div>
                </div>
              </div>
              <div className="team__item">
                <div className="left">
                  <p className="title">讲师 · 明阳</p>
                  <p className="description">
                    云扩科技流程交付负责人<br />
                    拥有丰富的 RPA 流程设计<br />
                    与开发经验
                  </p>
                </div>
                <div className="right">
                  <div className="shadow">
                    <img src={team7} className="team_people"></img>
                  </div>
                </div>
              </div>
              <div className="team__item">
                <div className="left">
                  <p className="title">讲师 · 飞飞</p>
                  <p className="description">
                    负责云扩科技多个项目咨询<br />
                    及实施工作<br />
                    拥有丰富的项目落地经验
                  </p>
                </div>
                <div className="right">
                  <div className="shadow">
                    <img src={team8} className="team_people"></img>
                  </div>
                </div>
              </div>
              <div className="team__item">
                <div className="left">
                  <p className="title">讲师 · Amanda</p>
                  <p className="description">
                    负责天匠 RPA 产品设计开发<br />
                    方案构思与搭建优化
                  </p>
                </div>
                <div className="right">
                  <div className="shadow">
                    <img src={team9} className="team_people"></img>
                  </div>
                </div>
              </div>
              <div className="team__item">
                <div className="left">
                  <p className="title">讲师 · 甘霖</p>
                  <p className="description">
                    资深软件测试专家<br />
                    具有丰富的软件测试<br />
                    与流程自动化经验
                  </p>
                </div>
                <div className="right">
                  <div className="shadow">
                    <img src={team10} className="team_people"></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TeamList)))
