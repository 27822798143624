import ConstValues from './consts'
import { get } from '../tools/api';
import { runtimeConfig } from '../config';
const { api_domain } = runtimeConfig;

export function setMyCoursesNavTab(navtab) {
  // learning finished favorite

  return async function (dispatch) {
    dispatch({
      type: ConstValues.PROFILE_SET_COURSES_NAVTAB,
      navtab
    })
  }
}

export function getMyMourses({ path }) {
  return async function (dispatch, getState) {
    const { loading } = getState().profile;
    if (loading) {
      return;
    }
    dispatch({
      type: ConstValues.PROFILE_START_LOADING_COURSES_LIST
    })

    // compose url
    let url = `${api_domain}api/learn-api/${path}-list?start=0&pageSize=100`

    // request
    try {
      const data = await get(url);

      dispatch({
        type: ConstValues.PROFILE_SET_COURSES_LIST,
        list: data.list
      })
    } catch (err) {
      console.error(err);
    }

    dispatch({
      type: ConstValues.PROFILE_FINISHED_LOADING_COURSES_LIST
    })
  }
}

export function getMyCerts() {
  return async function(dispatch) {
    const url = `${api_domain}api/learn-api/my-certs`
    try {
      const data = await get(url)
      dispatch({
        type: ConstValues.GET_CERTS,
        data
      })
    } catch (err) {
      console.error(err)
    }
  }
}

export function getMySingleCert(learnPathId) {
  return async function(dispatch) {
    const url = `${api_domain}api/learn-api/my-single-cert?learnPathId=${learnPathId}`
    try {
      const data = await get(url)
      return data
    } catch (err) {
      console.error(err)
      return null      
    }
  }
}