import React, { useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next';

const TabletSearch = ({ history, t }) => {

  const inpRef = useRef(null)

  function onKeyDown(e) {
    if (e.keyCode == 13) {
      const word = e.target.value
      if (!word) {
        return
      }
      history.push(`/search/docs/${word}`)
    }
  }

  function eraseWord() {
    inpRef.current.value = ''
  }

  return (
    <div className="search-ipad">
      <div className="search-ipad-inner">
        <input type="text" onKeyDown={onKeyDown} ref={inpRef} placeholder={t('header.searchbycontent')} />
        <i className="icon-search" />
        <i className="icon-cancel" onClick={eraseWord} />
      </div>
    </div>
  )
}

export default withRouter(withTranslation()(TabletSearch))