import React from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroller';
import {fulltextSearchDocs} from '../../actions'
import ReactLoading from "react-loading"

class MobileSearchDocs extends React.Component {
  constructor(props) {
    super(props)
    this.loadFunc = this.loadFunc.bind(this)
  }
  loadItems(docs) {
    return Array.from(docs, docItem => (
      <div className="result-item doc-item" key={docItem.id} onClick={() => window.open(`/${docItem.lang}/wiki/${docItem.fullPath}?_v=${docItem.version}`, '_blank')}>
        <h4 dangerouslySetInnerHTML={{__html: docItem.localeName}}></h4>
        <p dangerouslySetInnerHTML={{__html: docItem.highlights[0]}}></p>
      </div>
    ))
  }
  loadFunc(page) {
    const {mobileConfirmWord, fulltextSearchDocs} = this.props
    fulltextSearchDocs(mobileConfirmWord, page * 6)
  }
  render() {
    const {isGeneric, docs, generic} = this.props 
    if (isGeneric) {
      return (
        <div className="result-container">
          <div className="result-label">文档</div>
          {this.loadItems(generic.docs)}
          {
            generic.docsHasMore? (
              <Link className="goto-spec-search" to="/search-m/cat/docs">
                <i/><div className="txt">更多文档条目</div>
              </Link>
            ): null
          }
        </div>
      )
    }
    const hasMore = docs.start + 6 < docs.count
    return (
      <div className="result-container">
          <div className="result-label">文档</div>
          <InfiniteScroll
              pageStart={0}
              loadMore={this.loadFunc}
              hasMore={hasMore}
              initialLoad={false}
          >
              {this.loadItems(docs.list)}
          </InfiniteScroll>
          {docs.isLoading? (
            <div className="loading-container">
              <ReactLoading type="bars" color="lightgray" className="loading-element"/>
            </div>
          ): null}
        </div>
    )
  }
}

const mapStateToProps = state => {
  const {mobileConfirmWord, generic, docs} = state.sm
  return {mobileConfirmWord, generic, docs}
}

const mapDispatchToProps = dispatch => ({
  fulltextSearchDocs: (word, start) => dispatch(fulltextSearchDocs(word, start))
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileSearchDocs)