import React from 'react';

class ImgModal extends React.Component {
  constructor(props) {
    super(props);
    this.bigImg = React.createRef();
    this.state = {
      styleTop: 20,
      styleLeft: 10,
    }
  }

  //计算是否超出屏幕
  InWindow = (left, top, startPosX, startPosY) => {
    let H = document.body.clientHeight;
    let W = document.body.clientWidth;
    if ((left < 20 && startPosX > left) || (left > W - 20 && startPosX < left) ||
      (top < 20 && startPosY > top) || ((top > H - 20 && startPosY < top))) {
      return false
    }
    return true
  }
  onMouseDown = e => {
    e.preventDefault();
    let startPosX = e.clientX;
    let startPosY = e.clientY;
    const { styleLeft, styleTop } = this.state;
    document.body.onmousemove = e => {
      let left = e.clientX - startPosX + styleLeft;
      let top = e.clientY - startPosY + styleTop;
      if (this.InWindow(e.clientX, e.clientY, startPosX, startPosY)) {
        this.setState({
          styleLeft: left,
          styleTop: top,
        })
      } else {
        document.body.onmousemove = null;
        document.body.onmouseup = null;
      }
    };
    document.body.onmouseup = function () {
      document.body.onmousemove = null;
      document.body.onmouseup = null;
    };
  };

  onHandleClose(e) {
    if (e !== undefined) {
      e.preventDefault();
    }
    this.bigImg.current.removeAttribute("width")
    this.bigImg.current.removeAttribute("height")
    const { onHandleClose } = this.props;
    onHandleClose();
  }

  onZoomIn() {
    const scale_h = window.innerHeight * 0.75 / this.bigImg.current.height
    const scale_w = window.innerWidth * 0.9 / this.bigImg.current.width
    let scale = Math.min(scale_h, scale_w, 1.25)
    console.log(`${window.innerHeight} ${window.innerWidth} ${this.bigImg.current.height} ${this.bigImg.current.width} scaleH:${scale_h} scaleW:${scale_w} scale:${scale}`)
    if (scale < 1) {
      return
    }
    this.bigImg.current.width = Math.round(this.bigImg.current.width * scale)
  }

  onZoomOut() {
    const scale_h = 100 / this.bigImg.current.height
    const scale_w = 100 / this.bigImg.current.width
    const scale = Math.max(scale_h, scale_w, 0.8)
    console.log(`scaleH:${scale_h} scaleW:${scale_w} scale:${scale}`)
    if (scale > 1) {
      return
    }
    this.bigImg.current.width = Math.round(this.bigImg.current.width * scale)
  }

  render() {
    const { styleLeft, styleTop } = this.state
    const { showModal, src, modalTitle, footer } = this.props;

    return <div className="modal-root">
      <div className={`modal-mask${showModal ? " zoom-in" : " zoom-out"}`}></div>
      <div className="modal-wrap" style={showModal ? { display: "block" } : { display: "none" }}>
        <div className={`modal${showModal ? " scale-in" : " scale-out"}`}
          style={{
            maxWidth: '95%',
            minWidth: '300px',
            left: styleLeft + 'px',
            top: styleTop + 'px',
          }}>
          <div className="modal-content" style={{ margin: 'auto' }} onMouseDown={this.onMouseDown}>
            <button type="button" className="modal-zoom-in" onClick={e => this.onZoomIn()}>
              <span>
                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M528 455.36h96c42.688 0 42.688 64 0 64h-96v96.256c0 42.624-64 42.624-64 0V519.36h-96c-42.688 0-42.688-64 0-64h96v-96c0-42.688 64-42.688 64 0v96z m217.728 274.368c7.616-7.68 14.784-15.552 21.568-23.68l136.832 136.768c30.08 30.208-15.104 75.456-45.312 45.312l-136.768-136.832a352 352 0 1 1 45.248-45.248l136.832 136.768-45.312 45.312-136.768-136.832a356.48 356.48 0 0 0 23.68-21.568z m-45.248-45.248A288 288 0 1 0 293.12 277.12a288 288 0 0 0 407.296 407.296z"></path></svg>
              </span>
            </button>
            <button type="button" className="modal-zoom-out" onClick={e => this.onZoomOut()}>
              <span>
                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M592 400l-320 0c-19.2 0-32 12.8-32 32s12.8 32 32 32l320 0c19.2 0 32-12.8 32-32S611.2 400 592 400zM950.4 905.6l-236.8-236.8c54.4-64 86.4-147.2 86.4-236.8C800 227.2 636.8 64 432 64 227.2 64 64 227.2 64 432 64 636.8 227.2 800 432 800c89.6 0 172.8-32 236.8-86.4l236.8 236.8c6.4 6.4 16 9.6 22.4 9.6s16-3.2 22.4-9.6C963.2 937.6 963.2 918.4 950.4 905.6zM432 736C265.6 736 128 598.4 128 432 128 265.6 265.6 128 432 128c166.4 0 304 137.6 304 304C736 598.4 598.4 736 432 736z"></path></svg>
              </span>
            </button>
            <button type="button" className="modal-close" onClick={e => this.onHandleClose(e)}>
              <span>
                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg>
              </span>
            </button>
            <div className="modal-header">
              <div className="modal-title">{modalTitle ? modalTitle : "图片预览"}</div>
            </div>
            <div className="modal-body">
              <div className="modal_big_img" >
                <img className="big_img" style={{ maxWidth: '100%' }} src={src} ref={this.bigImg} />
              </div>
            </div>
            {
              footer === null || footer !== undefined ? footer
                :
                (
                  <div className="modal-footer">
                    {/* <button className="button-confirme" onClick={e => this.onHandleClose(e)}>关闭</button> */}
                  </div>
                )
            }
          </div>
        </div>
      </div>
    </div>
  }
}

export default ImgModal;