import React, { useState, useEffect, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import ContentLoader from "react-content-loader";
import ReactLoading from 'react-loading';
import {
  setLearnRoleIds, setLearnLevel, setLearnType, setLearnTagIds, setLearnName,
  makeSureLearnListInitialized, loadNextLearnList, reloadLearnListByConditionChange,
  testLoggedIn, openLearnPath, openLearnCourse, likeLearnPath, likeLearnCourse
} from '../../actions';
import { toast } from 'react-toastify';

import ImgBannerBg from '../../assets/learn/banner-bg.jpg';
import { processCourseIconUrl } from '../../tools/customUtil'

import { runtimeConfig } from '../../config'
const { login_url } = runtimeConfig

const LearnLanding = ({ history, pageSize, roles, levels, tags,
  roleIds, level, type, tagIds, name, innerSearch,
  setLearnRoleIds, setLearnLevel, setLearnType, setLearnTagIds, setLearnName,
  list, count, pageIdx, loading, reloadLearnListByConditionChange,
  testLoggedIn, openLearnPath, openLearnCourse, likeLearnPath, likeLearnCourse
}) => {

  const innerSearchRef = useRef(null);

  useEffect(() => {
    reloadLearnListByConditionChange(pageSize)
  }, [roleIds, level, type, tagIds, name])

  //#region taglike conditions
  function toggleRoleId (id) {
    if (id === 0) {
      setLearnRoleIds([]);
      return;
    } else {
      if (roleIds.length && roleIds[0] === id) {
        setLearnRoleIds([]);
      } else {
        setLearnRoleIds([id]);
      }
      return;
    }
  }
  function renderRoles () {
    return (
      <div className="options">
        <div className={`option-item all${roleIds.length === 0 ? ' active' : ''}`}
          onClick={() => toggleRoleId(0)}>全部</div>
        {
          Array.from(roles, ({ id, name }) => (
            <div className={`option-item${roleIds.indexOf(id) >= 0 ? ' active' : ''}`}
              key={id}
              onClick={() => toggleRoleId(id)}>{name}</div>
          ))
        }
      </div>
    )
  }
  function toggleLevel (id) {
    if (level === id) {
      setLearnLevel(0);
    } else {
      setLearnLevel(id);
    }
  }
  function renderLevels () {
    return (
      <div className="options">
        <div className={`option-item all${level === 0 ? ' active' : ''}`}
          onClick={() => toggleLevel(0)}>全部</div>
        {
          Array.from(levels, ({ id, name }) => (
            <div className={`option-item${level === id ? ' active' : ''}`}
              key={id}
              onClick={() => toggleLevel(id)}>{name}</div>
          ))
        }
      </div>
    )
  }
  function toggleTagId (id) {
    if (id === 0) {
      setLearnTagIds([]);
      return;
    } else {
      if (tagIds.length && tagIds[0] === id) {
        setLearnTagIds([]);
      } else {
        setLearnTagIds([id]);
      }
      return;
    }
  }
  function renderTags () {
    return (
      <div className="options">
        <div className={`option-item all${tagIds.length === 0 ? ' active' : ''}`}
          onClick={() => toggleTagId(0)}>全部</div>
        {
          Array.from(tags, ({ id, name }) => (
            <div className={`option-item${tagIds.indexOf(id) >= 0 ? ' active' : ''}`}
              key={id}
              onClick={() => toggleTagId(id)}>{name}</div>
          ))
        }
      </div>
    )
  }

  function renderCourseList (list, type) {
    return (
      <div className="g-course-list">
        <div className="g-course-title"><h3>{type == 1 ? '学习路径' : type == 2 ? '教程' : '应用场景'}</h3></div>
        {Array.from(list, cItem => (
          cItem.type == type ?
            <div className="g-course" key={cItem.id}>
              <div className="g-course-inner">
                <div className={`bar${cItem.type == 1 ? ' lp' : cItem.type == 2 ? ' c' : ' a'}`}></div>
                <img src={processCourseIconUrl(cItem.iconUrl)} />
                <div className="content-body">
                  <label>{type == 1 ? '学习路径' : type == 2 ? '教程' : '应用场景'}</label>
                  <Link className="name" to={`${cItem.type == 1 ? '/learn/path-detail/' : '/learn/course-detail/'}${cItem.id}`}>{cItem.name}</Link>
                  <div className="txt-line">
                    <i />{cItem.timeDesc}<span className="txt-line-split">|</span>{cItem.learningCount}人在学
                  </div>
                  <div className="role-line">
                    {
                      cItem.roleNames.filter((_, rIdx) => rIdx < 2).map((rn, rIdx) => (
                        <div className="role" key={rIdx}>{rn}</div>
                      ))
                    }
                  </div>
                  <div className="last-row">
                    {
                      (cItem.progress !== null && cItem.childCount > 0) ?
                        (
                          <Fragment>
                            <div className="progress"><div className="progress-inner" style={{ width: (Math.round(cItem.progress / cItem.childCount * 100)) + '%', maxWidth: '100%' }}></div></div>
                            <div className="perc">
                              {
                                Math.round(cItem.progress / cItem.childCount * 100) > 100 ?
                                  100 :
                                  Math.round(cItem.progress / cItem.childCount * 100)
                              }%</div>
                          </Fragment>
                        ) :
                        null
                    }
                    <div className={cItem.isFavorite ? "star" : "no-star"} onClick={(e) => testLoggedInThen(cItem.type, cItem.id)}></div>
                  </div>
                </div>
              </div>
            </div>
            : null
        ))}
        {
          loading ?
            Array.from(Array.from({ length: 4 }).map((_, idx) => idx), idx => (
              <div key={idx} className="g-course">
                <div className="g-course-inner">
                  <ContentLoader viewBox="0 0 276 246">
                    <circle cx="55" cy="48" r="30" />
                    <rect x="25" y="91" width="55" height="14" />
                    <rect x="25" y="115" width="85" height="15" />
                    <rect x="25" y="145" width="145" height="12" />
                    <rect x="25" y="169" width="83" height="22" />
                    <rect x="25" y="203" width="227" height="1" />
                    <rect x="25" y="215" width="227" height="12" />
                  </ContentLoader>
                </div>
              </div>
            )) :
            null
        }
      </div>
    )
  }

  function toggleType (id) {
    if (type === id) {
      setLearnType(0);
    } else {
      setLearnType(id);
    }
  }
  //#endregion

  function onNameInputKeyDown (e) {
    if (e.keyCode == 13) {
      let word = e.target.value;
      if (word === null || word === undefined) {
        word = '';
      }
      setLearnName(word);
    }
  }

  async function testLoggedInThen (type, id) {
    try {
      await testLoggedIn();
      toggleLikeLearn(type, id);
    } catch (err) {
      if (err.message === '用户未登录') {
        const returnUrl = window.encodeURIComponent(window.location.pathname)
        window.location = `${login_url}?returnUrl=${returnUrl}`
      } else if (err.message === '未知错误') {
        toast('未知错误');
      }
    }
  }

  function toggleLikeLearn (type, id) {
    if (type === 1) {
      openLearnPath(id).then(() => {
        likeLearnPath(id, 'learnLanding');
      });
    } else {
      openLearnCourse(id).then(() => {
        likeLearnCourse(id, 'learnLanding');
      });
    }
  }

  function handleSearch () {
    let word = innerSearchRef.current.value;
    if (word === null || word === undefined) {
      word = '';
    }
    setLearnName(word);
  }

  return (
    <div className="learn-landing">
      <div className="banner">
        <img src={ImgBannerBg} />
        <div className="content">
          <div className="content-label">课程</div>
          <div className="learn-search">
            <div className={`learn-search-inner${innerSearch ? ' active' : ''}`}>
              <input type="text" placeholder="请输入关键字搜索RPA课程" onKeyDown={onNameInputKeyDown} ref={innerSearchRef} />
              <button className="search-btn" onClick={handleSearch}></button>
            </div>
          </div>
        </div>
      </div>
      <div className="tag-container">
        <div className="container">
          <div className="line">
            <label>学习角色</label>{renderRoles()}
          </div>
          <div className="line">
            <label>课程级别</label>{renderLevels()}
          </div>
          <div className="line">
            <label>课程类型</label><div className="options">
              <div className={`option-item all${type === 0 ? ' active' : ''}`}
                onClick={() => toggleType(0)}>全部</div>
              <div className={`option-item${type === 1 ? ' active' : ''}`}
                onClick={() => toggleType(1)}>学习路径</div>
              <div className={`option-item${type === 2 ? ' active' : ''}`}
                onClick={() => toggleType(2)}>教程</div>
              <div className={`option-item${type === 3 ? ' active' : ''}`}
                onClick={() => toggleType(3)}>应用场景</div>
            </div>
          </div>
          <div className="line">
            <label>标签</label>{renderTags()}
          </div>
        </div>
      </div>
      {renderCourseList(list, 1)}
      {renderCourseList(list, 2)}
      <div className="g-course-load-more">
        {(pageIdx + 1) * pageSize >= count ? '' : !loading ? '滑动查看更多' : (
          <Fragment>滑动查看更多<ReactLoading type="spinningBubbles" style={{ display: 'inline-block', marginLeft: '12px', width: "12px", height: "12px", fill: "#2867E2" }} /></Fragment>
        )}
      </div>
      {renderCourseList(list, 3)}
    </div>
  )
}

const mapStateToProps = state => {
  const { roles, levels, tags, roleIds, level, type, tagIds, name, dataByPage, count, pageIdx, loading, innerSearch } = state.LL
  let _dataByPage = [...dataByPage]
  _dataByPage.sort((a, b) => a.pageIdx - b.pageIdx)
  let list = []
  for (const dataByPageItem of _dataByPage) {
    list.push(...dataByPageItem.list)
  }
  if (list.length > 0) {
    let rmvIdx = [];
    let existedIdx = [];
    for (let i = 0; i < list.length; ++i) {
      const item = list[i];
      if (existedIdx.indexOf(item.id) > -1) {
        rmvIdx.push(i)
      } else {
        existedIdx.push(item.id)
      }
    }
    for (let i = 0; i < rmvIdx.length; ++i) {
      list.splice(rmvIdx[i] - i, 1);
    }
  }
  return {
    roles,
    levels,
    tags,
    roleIds,
    level,
    type,
    tagIds,
    name,
    list,
    count,
    pageIdx,
    loading,
    innerSearch
  }
}

const mapDispatchToProps = dispatch => ({
  setLearnRoleIds: roleIds => dispatch(setLearnRoleIds(roleIds)),
  setLearnLevel: level => dispatch(setLearnLevel(level)),
  setLearnType: type => dispatch(setLearnType(type)),
  setLearnTagIds: tagIds => dispatch(setLearnTagIds(tagIds)),
  setLearnName: name => dispatch(setLearnName(name)),
  reloadLearnListByConditionChange: pageSize => dispatch(reloadLearnListByConditionChange(pageSize)),
  testLoggedIn: () => dispatch(testLoggedIn()),
  openLearnPath: id => dispatch(openLearnPath(id)),
  openLearnCourse: id => dispatch(openLearnCourse(id)),
  likeLearnPath: (id, ll) => dispatch(likeLearnPath(id, ll)),
  likeLearnCourse: (id, ll) => dispatch(likeLearnCourse(id, ll)),
})

const LearnLandingContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(LearnLanding));

const PAGE_SIZE = 20;

class LearnLandingWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.onCheckScroll = this.onCheckScroll.bind(this);
  }
  componentDidMount () {
    this.initPromise = this.props.makeSureLearnListInitialized(PAGE_SIZE);
    this.initPromise.then(() => {
      if (!!this.initPromise) {
        this.checkScrollHandle = setInterval(this.onCheckScroll, 500);
      }
    })
    document.title = "RPA课程-RPA教程-云扩学院"
  }
  componentWillUnmount () {
    this.initPromise = null;
    this.checkScrollHandle && clearInterval(this.checkScrollHandle);
  }
  onCheckScroll () {
    const y = window.scrollY;
    const { footerHeight } = this.props;
    const totalHeight = document.body.scrollHeight;
    const winHeight = window.innerHeight;
    if (y >= totalHeight - footerHeight - winHeight) {
      this.callLoadMore();
    }
  }
  callLoadMore () {
    console.log('enter loading area...');
    this.props.loadNextLearnList(PAGE_SIZE);
  }
  render () {
    return <LearnLandingContainer pageSize={PAGE_SIZE} />
  }
}

const wMapStateToProps = state => {
  const { footerHeight } = state.winsize;
  return {
    footerHeight
  }
}

const wMapDispatchToProps = dispatch => ({
  makeSureLearnListInitialized: pageSize => dispatch(makeSureLearnListInitialized(pageSize)),
  loadNextLearnList: pageSize => dispatch(loadNextLearnList(pageSize))
})

export default connect(wMapStateToProps, wMapDispatchToProps)(LearnLandingWrapper);
