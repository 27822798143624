import React, {Fragment} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroller';
import {fuzzySearchLearnList} from '../../actions'
import ReactLoading from "react-loading"
import {processCourseIconUrl} from '../../tools/customUtil'

class MobileSearchLearns extends React.Component {
  constructor(props) {
    super(props)
    this.loadFunc = this.loadFunc.bind(this)
  }
  loadItems(learns) {
    return Array.from(learns, cItem => (
      <div className="g-course" key={cItem.id}>
        <div className="g-course-inner">
          <div className={`bar${cItem.type === 1 ? ' lp' : ' c'}`}></div>
          <img src={processCourseIconUrl(cItem.iconUrl)} />
          <div className="content-body">
            <label>{cItem.type === 1 ? '学习路径' : '课 程'}</label>
            <Link className="name" to={`${cItem.type === 1 ? '/learn/path-detail/' : '/learn/course-detail/'}${cItem.id}`}>{cItem.name}</Link>
            <div className="txt-line">
              <i />{cItem.timeDesc}<span className="txt-line-split">|</span>{cItem.learningCount}人在学
            </div>
            <div className="role-line">
              {cItem.roleNames.filter((_, rIdx) => rIdx < 2).map((rn, rIdx) => (
                <div className="role" key={rIdx}>{rn}</div>
              ))}
            </div>
            <div className="last-row">
              {(cItem.progress !== null && cItem.childCount > 0) ? (
                <Fragment>
                  <div className="progress"><div className="progress-inner" style={{ width: (Math.round(cItem.progress / cItem.childCount * 100)) + '%' }}></div></div>
                  <div className="perc">{Math.round(cItem.progress / cItem.childCount * 100)}%</div>
                </Fragment>
              ) : null}
              {cItem.isFavorite ? <div className="star"></div> : <div className="no-star"></div>}
            </div>
          </div>
        </div>
      </div>
    ))
  }
  loadFunc(page) {
    const {mobileConfirmWord, fuzzySearchLearnList} = this.props
    fuzzySearchLearnList(mobileConfirmWord, page * 6)
  }
  render() {
    const {isGeneric, learns, generic} = this.props 
    if (isGeneric) {
      return (
        <div className="result-container">
          <div className="result-label">课程</div>
          {this.loadItems(generic.learns)}
          {
            generic.learnsHasMore? (
              <Link className="goto-spec-search" to="/search-m/cat/learn">
                <i/><div className="txt">更多课程条目</div>
              </Link>
            ): null
          }
        </div>
      )
    }
    const hasMore = learns.start + 6 < learns.count
    return (
      <div className="result-container">
          <div className="result-label">课程</div>
          <InfiniteScroll
              pageStart={0}
              loadMore={this.loadFunc}
              hasMore={hasMore}
              initialLoad={false}
          >
              {this.loadItems(learns.list)}
          </InfiniteScroll>
          {learns.isLoading? (
            <div className="loading-container">
              <ReactLoading type="bars" color="lightgray" className="loading-element"/>
            </div>
          ): null}
        </div>
    )
  }
}

const mapStateToProps = state => {
  const {mobileConfirmWord, generic, learns} = state.sm
  return {mobileConfirmWord, generic, learns}
}

const mapDispatchToProps = dispatch => ({
  fuzzySearchLearnList: (word, start) => dispatch(fuzzySearchLearnList(word, start))
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileSearchLearns)