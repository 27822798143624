import {ConstValues} from '../actions'
import update from 'immutability-helper'

const initialData = [] // {roadMapId, roadMapName, courses: [{courseId, courseName, courseDescription, imgUrl, durationDesc, learnTimeDesc, linkUrl}]}

export default (state = initialData, action) => {
  switch(action.type) {
    case ConstValues.ROADMAP_SET:
      return update(state, {$set: action.data})
    default:
      return state;
  }
}