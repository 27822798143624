import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { runtimeConfig } from '../config'
import { getRelatedWiki } from '../actions'
const { api_domain } = runtimeConfig

class RelatedWiki extends React.PureComponent {

  constructor(props) {
    super(props)
    this._getRelatedWiki = this._getRelatedWiki.bind(this)
  }
  state = {
    wikis: [],
  }
  _getRelatedWiki() {
    const { getRelatedWiki, labels } = this.props;
    const { wikis } = this.state
    getRelatedWiki(labels).then((data) => {
      if (wikis.toString() != data.toString()) {
        this.setState({
          wikis: data
        })
      }
      // console.log(`_getRelatedWiki data ${JSON.stringify(data)}`)
    }).catch(() => {
      console.log(`_getRelatedWiki error`)
    })
  }

  componentDidMount() {
  }
  componentDidUpdate() {
    this._getRelatedWiki()
  }

  renderWikis() {
    const { wikis } = this.state
    return Array.from(wikis, (wiki, key) => (
      <li key={wiki.id} className="related-li"><a className="related_a" href={`/wiki/${wiki.fullPath}` + (wiki.uuid ? `?uuid=${wiki.uuid}` : '')}>{`${wiki.localeName}`}</a></li>
    ))
  }

  render() {
    return (
      <div className="related-wiki">
        <h4>学院相关文档</h4>
        <ol>{this.renderWikis()}</ol>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  getRelatedWiki: p => dispatch(getRelatedWiki(p)),
})

export default connect(null, mapDispatchToProps)(RelatedWiki)