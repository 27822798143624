import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import {runtimeConfig} from '../config'

console.log(runtimeConfig.aiInstrumentationKey)

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: runtimeConfig.aiInstrumentationKey,
    endpointUrl: 'https://dc.applicationinsights.azure.cn/v2/track',
    maxBatchInterval: 0,
    disableAjaxTracking: true
  }
})

let defaultParams = {}

if (typeof window !== 'undefined') {
  appInsights.loadAppInsights()
  defaultParams = {
    IP: window.currentIP,
    ModuleID: "Course"
  }
}

export default appInsights

let globalParams = {}

export function setGlobalParam(name, value) {
  globalParams[name] = value
}

export function clearGlobalParams() {
  globalParams = {}
}

export function getGlobalParams() {
  return {...defaultParams, ...globalParams}
}