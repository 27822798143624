import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import ReactLoading from "react-loading"

import {getMySingleCert} from '../../actions'
import CertTemplate from '../../assets/cert-template.png'

import { runtimeConfig } from '../../config'
const { api_domain } = runtimeConfig

class SingleCert extends React.Component {
  state = {
    certData: null
  }
  componentDidMount() {
    const {learnPathId} = this.props.match.params
    this.props.getMySingleCert(learnPathId).then(data => {
      this.setState({
        certData: data
      })
    })
  }
  handleClickContinueLearn(learnPathId) {
    this.props.history.push(`/learn/path-detail/${learnPathId}`)
  }
  handleDownload() {
    const {learnPathId} = this.props.match.params
    window.open(`${api_domain}api/learn-api/download-cert-pdf?learnPathId=${learnPathId}`)
  }
  render() {
    const {learnPathId} = this.props.match.params
    const {certData} = this.state
    if (certData === null) {
      return (
        <div className="single-cert-container">
          <div className="single-cert-box">
            <ReactLoading type="bars" color="lightgray" />
          </div>
        </div>
      )
    }
    return (
      <div className="single-cert-container">
        <div className="single-cert-box">
          <div className="cert-item" style={{marginLeft: 'auto', marginRight: 'auto', width: 668}}>
            <img src={CertTemplate} />
            <div className="cert-text-item" style={{
              fontSize: 26,
              fontWeight: 'bold',
              top: 202,
              left: 48,
              color: '#36393A'
            }}>{certData.visitorName}</div>
            <div className="cert-text-item" style={{
              fontSize: 40,
              fontWeight: 'bold',
              top: 282,
              left: 48,
              color: '#1263EA'
            }}>{certData.learnPathName}</div>
            <div className="cert-text-item" style={{
              fontSize: 12,
              top: 398,
              left: 110,
              color: '#36393A'
            }}>{certData.certNo}</div>
            <div className="cert-text-item" style={{
              fontSize: 12,
              top: 420,
              left: 123,
              color: '#36393A'
            }}>{certData.issueDate}</div>
            <div className="button-container">
              <button className="continue-learn" 
                onClick={() => this.handleClickContinueLearn(learnPathId)}>继续学习</button>
              <button className="down" onClick={() => this.handleDownload()}>下载证书</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  getMySingleCert: (learnPathId) => dispatch(getMySingleCert(learnPathId))
})

export default connect(null, mapDispatchToProps)(withRouter(SingleCert))