import React, { Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
//layouts
import BasicLayout from './layouts/BasicLayout';
import AuthLayout from './layouts/AuthLayout';
//pages
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import ForgetPass from './pages/auth/ForgetPass';
import Wiki from './pages/Wiki';
import Page404 from './pages/404';
import Index from './pages/Index'
// import WikiEdit from './pages/WikiEdit'
import WikiCover from './pages/WikiCover'
import LearningPath from './pages/LearningPath'
import TeamList from './pages/TeamList'
import CourseList from './pages/CourseList'
import CertIntro from './pages/CertIntro'
import ApplyCommunity from './pages/ApplyCommunity'
import SearchResult from './pages/SearchResult'
import MobileSearchLayout from './layouts/MobileSearchLayout'
import MobileGenericSearch from './pages/search-m/MobileGenericSearch'
import MobileSpecDocsSearch from './pages/search-m/MobileSpecDocsSearch'
import MobileSpecLearnsSearch from './pages/search-m/MobileSpecLearnsSearch'
import LearnLanding from './pages/learn/LearnLanding'
import LearnCourseDetail from './pages/learn/LearnCourseDetail'
import LearnPathDetail from './pages/learn/LearnPathDetail'
import LearnUnitDetail from './pages/learn/LearnUnitDetail'
import ProfileMyCourses from './pages/profile/MyCourses'
import ProfileMyCerts from './pages/profile/MyCerts'
import EventBus from './components/EventBus'
import SingleCert from './pages/profile/SingleCert'

import './scss/app.scss';
import "react-mde/lib/styles/css/react-mde-all.css";
import 'react-confirm-alert/src/react-confirm-alert.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => (
  <Fragment>
    <Switch>
      <Route strict path="/auth/">
        <AuthLayout>
          <Route exact path="/auth/login">
            <Login />
          </Route>
          <Route exact path="/auth/register">
            <Register />
          </Route>
          <Route exact path="/auth/forget">
            <ForgetPass />
          </Route>
        </AuthLayout>
      </Route>
      <EventBus>
        <Route path="/search-m">
          <MobileSearchLayout>
            <Route exact path="/search-m">
              <MobileGenericSearch />
            </Route>
            <Route exact path="/search-m/cat/docs">
              <MobileSpecDocsSearch />
            </Route>
            <Route exact path="/search-m/cat/learn">
              <MobileSpecLearnsSearch />
            </Route>
          </MobileSearchLayout>
        </Route>
        <Route path="/">
          <BasicLayout>
            <Route exact path="/">
              <Index />
            </Route>
            <Route path="/profile/mycourses">
              <ProfileMyCourses />
            </Route>
            <Route path="/profile/mycerts">
              <ProfileMyCerts />
            </Route>
            <Route path="/profile/singlecert/:learnPathId">
              <SingleCert />
            </Route>
            {/* <Route exact path="/wikis">
              <WikiCover />
            </Route> */}
            {/* <Route exact path="/:lang/wikis">
              <WikiCover />
            </Route> */}
            <Switch>
              <Redirect to="/wiki/Index.md" from="/wiki" exact />
              <Redirect to="/:lang/wiki/Index.md" from="/:lang/wiki" exact />
            </Switch>
            <Route path="/wiki/">
              <Wiki />
            </Route>
            <Route path="/:lang/wiki/">
              <Wiki />
            </Route>
            {/* <Route exact path="/wiki-edit/:wikiId">
              <WikiEdit />
            </Route> */}
            <Route path="/roadmap">
              <LearningPath />
            </Route>
            <Route path="/teamlist">
              <TeamList />
            </Route>
            <Route path="/courses">
              <CourseList />
            </Route>
            <Route path="/certs">
              <CertIntro />
            </Route>
            {/* <Route path="/applycommunity">
              <ApplyCommunity />
            </Route> */}
            <Route path="/search/:cat/:word">
              <SearchResult />
            </Route>
            <Route path="/learn" exact>
              <LearnLanding />
            </Route>
            <Route path="/learn/path-detail/:pathId">
              <LearnPathDetail />
            </Route>
            <Route path="/learn/course-detail/:courseId">
              <LearnCourseDetail />
            </Route>
            <Route path="/learn/unit-detail/:id">
              <LearnUnitDetail />
            </Route>
            <Route exact path="/404">
              <Page404 />
            </Route>
          </BasicLayout>
        </Route>
      </EventBus>

    </Switch>
    <ToastContainer />
  </Fragment>
);

export default App;