import React, {Fragment, useState} from 'react'
import {connect} from 'react-redux'
import { Link } from 'react-router-dom'
import Breadscrub from '../components/Breadscrub'
import {setCoursesCourseId, setCoursesSortCondition, initializeSimpleCourseList, getLessons} from '../actions'
import IconDropdown from '../assets/dropdown-course.png'

const CourseLibNav = ({courseId, loading, setCoursesCourseId, simpleCourses}) => {
  const [collapsed, setCollapsed] = useState(true)

  function onClickCourse(_courseId) {
    if (loading) {
      return
    }
    if (_courseId === courseId) {
      setCoursesCourseId(null)
    } else {
      setCoursesCourseId(_courseId)
    }
  }

  function getItemClass(_courseId) {
    return `attachment__item${_courseId === courseId? ' active': ''}`
  }

  let subMenuStyle = {}
  if (!collapsed) {
    subMenuStyle.height = 44 * simpleCourses.length
  }
  return (
    <Fragment>
      <li className={`attachment__item${collapsed? '': ' active'}`}>
        <div className="attachment__icon icon_course_lib"></div>
        <p className="attachment__title" onClick={() => {
          setCollapsed(!collapsed)
        }}>课程库</p>
      </li>
      <li className={`attachment__submenu${collapsed? ' collapsed': ' active'}`} style={subMenuStyle}>
        <ul className="attachment">
          {Array.from(simpleCourses, item => (
            <li className={getItemClass(item.courseId)} onClick={() => onClickCourse(item.courseId)} key={item.courseId}>
              <p className="attachment__title">{item.courseName}</p>
            </li>
          ))}
        </ul>
      </li>
    </Fragment>
  )
}

const mapStateToPropsCourseLibNav = state => ({
  courseId: state.courses.courseId,
  loading: state.courses.loading,
  simpleCourses: state.courses.courses
})
const mapDispatchToPropsCourseLibNav = dispatch => ({
  setCoursesCourseId: (courseId) => dispatch(setCoursesCourseId(courseId))
})
const CourseLibNavPart = connect(mapStateToPropsCourseLibNav, mapDispatchToPropsCourseLibNav)(CourseLibNav)

const DurationTool = ({sortCondition, loading, setCoursesSortCondition}) => {
  const [active, setActive] = useState(false)
  function sortAsc() {
    if (loading) {
      return
    }
    setActive(false)
    setCoursesSortCondition('duration_asc')
  }
  function sortDesc() {
    if (loading) {
      return
    }
    setActive(false)
    setCoursesSortCondition('duration_desc')
  }
  function getItemActiveClass(cond) {
    if (cond === sortCondition) {
      return ' active'
    }
    return ''
  }
  return (
    <Fragment>
      <li className={`tool-time-length`}>
        <p className="tool-item" onClick={() => setActive(!active)}>
          <span>时长</span>
          <img src={IconDropdown}/>
        </p>
      </li>
      <li className={`submenu-tool-time${active? ' active': ' collapsed'}`}>
        <ul>
          <li className={`term${getItemActiveClass('duration_asc')}`} onClick={sortAsc}>按时长正序</li>
          <li className={`term${getItemActiveClass('duration_desc')}`} onClick={sortDesc}>按时长倒序</li>
        </ul>
      </li>
    </Fragment>
    
  )
}

class CourseList extends React.Component {

  componentDidMount() {
    const {initializeSimpleCourseList, lessons, getLessons} = this.props
    initializeSimpleCourseList();
    if (!lessons || lessons.length === 0) {
      getLessons()
    }
  }

  getItemActiveClass(cond) {
    if (cond === this.props.sortCondition) {
      return ' active'
    }
    return ''
  }

  gotoLesson(linkUrl) {
    if (!linkUrl) {
      return
    }
    window.open(linkUrl, '_blank')
  }

  render() {
    const {sortCondition, loading, setCoursesSortCondition, lessons} = this.props
    return (
      <div style={{backgroundColor: '#F6F7F8'}}>
        <div className="clp-content-area">
          <Breadscrub items={[{ name: '首页', url: '/', trans: true }, { name: '课程库', url: '/courses', trans: false }]} />
          <div className="clp-container">
            <div className="clp-attachment">
              <ul className="attachment">
                <CourseLibNavPart />
                <li className="attachment__item">
                  <div className="attachment__icon icon_learning_path"></div>
                  <Link className="attachment__title" to="/roadmap">学习路径</Link>
                </li>
                <li className="attachment__item">
                  <div className="attachment__icon icon_lecturers"></div>
                  <Link className="attachment__title" to="/teamlist">讲师团队</Link>
                </li>
                <li className="attachment__item">
                  <div className="attachment__icon icon_certification"></div>
                  <Link className="attachment__title" to="/certs">认证体系</Link>
                </li>
              </ul>
            </div>
            <div className="course-lib-content">
              <ul className="course-toolbox">
                <li className={`tool-all term${this.getItemActiveClass('all')}`} onClick={() => setCoursesSortCondition('all')}><p className="tool-item">全部课程</p></li>
                <li className={`tool-latest term${this.getItemActiveClass('latest')}`} onClick={() => setCoursesSortCondition('latest')}><p className="tool-item">最新</p></li>
                <li className={`tool-hotest term${this.getItemActiveClass('hotest')}`} onClick={() => setCoursesSortCondition('hotest')}><p className="tool-item">最热</p></li>
                <DurationTool sortCondition={sortCondition} loading={loading} setCoursesSortCondition={setCoursesSortCondition}/>
                <li className="tool-empty-padding"></li>
              </ul>
              <div className="lesson-container">
                {Array.from(lessons, item => (
                  <div className="lesson-wrapper" key={item.lessonId}>
                    <div className="lesson" onClick={() => this.gotoLesson(item.linkUrl)}>
                      <img className="course-bg" src={item.courseImgUrl}/>
                      <div className="lesson-icon-container">
                        <img className="lesson-icon" src={item.iconUrl}/>
                      </div>
                      <div className="txt-container">
                        <h3>{item.lessonName}</h3>
                        <h4>{item.courseName}</h4>
                        <p><i/>{item.duration}分钟<span className="view-times">{item.viewTimes}人在学</span></p>
                      </div>
                    </div>
                  </div>
                ))}
                
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  sortCondition: state.courses.sortCondition,
  loading: state.courses.loading,
  lessons: state.courses.lessons
})

const mapDispatchToProps = dispatch => ({
  setCoursesSortCondition: sortCondition => dispatch(setCoursesSortCondition(sortCondition)),
  initializeSimpleCourseList: () => dispatch(initializeSimpleCourseList()),
  getLessons: () => dispatch(getLessons()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CourseList)