import React from 'react'
import {connect} from 'react-redux'
import MobileSearchLearns from '../../components/search-m/MobileSearchLearns'
import ReactLoading from "react-loading"
import {fuzzySearchLearnList} from '../../actions'

class MobileSpecLearnsSearch extends React.Component {
  componentDidMount() {
    const {mobileConfirmWord, fuzzySearchLearnList} = this.props
    if (!!mobileConfirmWord) {
      fuzzySearchLearnList(mobileConfirmWord, 0)
    }
  }
  componentDidUpdate(prevProps) {
    const {mobileConfirmWord, fuzzySearchLearnList} = this.props
    const {mobileConfirmWord: prevMobileConfirmWord} = prevProps
    if (!!mobileConfirmWord && mobileConfirmWord !== prevMobileConfirmWord) {
      fuzzySearchLearnList(mobileConfirmWord, 0)
    }
  }

  render() {
    const {mobileConfirmWord, learns} = this.props
    if (!mobileConfirmWord) {
      return null
    }
    if (learns.start === 0 && learns.isLoading) {
      return (
        <div className="loading-container">
          <ReactLoading type="bars" color="lightgray" className="loading-element"/>
        </div>
      )
    }
    if (learns.length === 0) {
      return <div className="no-result">
        Oooops<br/>没有相关的搜索结果！
      </div>
    }
    return <MobileSearchLearns />
  }
}

const mapStateToProps = state => {
  const {mobileConfirmWord, learns} = state.sm
  return {mobileConfirmWord, learns}
}

const mapDispatchToProps = dispatch => ({
  fuzzySearchLearnList: (word, start) => dispatch(fuzzySearchLearnList(word, start))
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileSpecLearnsSearch)