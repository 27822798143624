import React, {useState, useRef, useEffect} from 'react'
import {withRouter} from 'react-router-dom'
import { withTranslation } from 'react-i18next';



const AdvancedSearch = ({history,t}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [category, setCategory] = useState(null)
  const inpRef = useRef(null)
  const advsRef = useRef(null)

  const categories = [{
    dn: t('header.searchwiki'),
    id: 'docs'
  }, {
    dn: t('header.searchcourses'),
    id: 'learn'
  }]

  function closeMenu() {
    setDropdownOpen(false)
  }

  useEffect(() => {
    advsRef.current.addEventListener('menu-close', closeMenu)
    return () => {
      advsRef.current.removeEventListener('menu-close', closeMenu)
    }
  })

  function getCurrentDropdownText() {
    if (category === null) {
      return t('header.searchall')
    }
    const idx = categories.findIndex(p => p.id === category);
    return categories[idx].dn
  }

  function getCategoryItemClass(id) {
    return category === id? ' active': null
  }

  function renderDropdownMenuItems() {
    return Array.from(categories, c => (
      <li key={c.id} 
        className={getCategoryItemClass(c.id)}
        onClick={() => {
          setCategory(c.id)
          setDropdownOpen(false)
        }}>{c.dn}</li>
    ))
  }

  function onConfirm() {
    let c = 'docs'
    if (!!category) {
      c = category
    }
    let w = inpRef.current.value
    if (!w) {
      return
    }
    history.push(`/search/${c}/${w}`)
  }

  function onKeyDown(e) {
    if(e.keyCode == 13) {
      onConfirm();
    }
  }

  return (
    <div className="adv-search" ref={advsRef} onClick={e => e.stopPropagation()}>
      <input onFocus={() => {setDropdownOpen(false)}} ref={inpRef} onKeyDown={onKeyDown} placeholder={t('header.searchbycontent')}/>
      <button className="search-btn" onClick={() => onConfirm()}></button>
      <div className="dropdown" onClick={() => {
        setDropdownOpen(!dropdownOpen)
        setCategory(null)
      }}>{getCurrentDropdownText()}</div>
      {dropdownOpen? (
        <ul className="dropdown-menu">
          {renderDropdownMenuItems()}
        </ul>
      ): null}
    </div>
  )
}

export default withRouter(withTranslation()(AdvancedSearch))