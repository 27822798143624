import React from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'

import {getMyCerts} from '../../actions'
import CertTemplate from '../../assets/cert-template.png'

import { runtimeConfig } from '../../config'
const { api_domain } = runtimeConfig

class MyCerts extends React.Component {
  componentDidMount() {
    this.props.getMyCerts()
  }
  handleDownload(learnPathId) {
    window.open(`${api_domain}api/learn-api/download-cert-pdf?learnPathId=${learnPathId}`)
  }
  render() {
    return (
      <div className="profile">
        <div className="profile-container">
          <ul className="navside-list">
            <li className="navside-item">
              <Link className="nav" to="/profile/mycourses">我的课程</Link>
            </li>
            <li className="navside-item active">
              <Link className="nav" to="/profile/mycerts">我的证书</Link>
            </li>
          </ul>
          <section className="my-cert-content">
            {
              Array.from(this.props.certs, (c, idx) => (
                <div className="cert-item" style={{width: 668}} key={idx}>
                  <img src={CertTemplate}/>
                  <div className="cert-text-item" style={{
                    fontSize: 26,
                    fontWeight: 'bold',
                    top: 202,
                    left: 48,
                    color: '#36393A'
                  }}>{c.visitorName}</div>
                  <div className="cert-text-item" style={{
                    fontSize: 40,
                    fontWeight: 'bold',
                    top: 282,
                    left: 48,
                    color: '#1263EA'
                  }}>{c.learnPathName}</div>
                  <div className="cert-text-item" style={{
                    fontSize: 12,
                    top: 398,
                    left: 110,
                    color: '#36393A'
                  }}>{c.certNo}</div>
                  <div className="cert-text-item" style={{
                    fontSize: 12,
                    top: 420,
                    left: 123,
                    color: '#36393A'
                  }}>{c.issueDate}</div>
                  <div className="button-container">
                    <button className="down" onClick={() => this.handleDownload(c.learnPathId)}>下载证书</button>
                  </div>
                </div>
              ))
            }
          </section>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  certs: state.profile.certs
})

const mapDispatchToProps = dispatch => ({
  getMyCerts: () => dispatch(getMyCerts())
})

export default connect(mapStateToProps, mapDispatchToProps)(MyCerts)