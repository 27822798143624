export const runtimeConfig =
  typeof window !== 'undefined' ? {
    // client
    api_domain: window.env.api_domain,
    is_prod: window.env.is_prod,
    prod_env: window.env.prod_env,
    aiInstrumentationKey: window.env.prod_env ? '2698d9c1-8fca-c899-a683-e710cc8602da' : 'a5c1fb51-0616-cca9-8721-f6436188ac31',
    console_url: window.env.console_url,
    login_url: window.env.login_url,
    logout_url: window.env.logout_url
  } : {
      // server
      api_domain: process.env.RAZZLE_ssr_api_domain,
      is_prod: process.env.RAZZLE_is_prod === 'true',
      PORT: process.env.PORT,
      prod_env: process.env.RAZZLE_prod_env === 'true' ? true : false,
      console_url: process.env.RAZZLE_prod_env === 'true'? 'https://academy.encoo.com/console': 'https://dev-academy.bottime.com/console',
      login_url: process.env.RAZZLE_prod_env === 'true'? 'https://academy.encoo.com/api/auth/login': 'https://dev-academy.bottime.com/api/auth/login',
      logout_url: process.env.RAZZLE_prod_env === 'true'? 'https://academy.encoo.com/api/auth/logout': 'https://dev-academy.bottime.com/api/auth/logout'
    };