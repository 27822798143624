import { ConstValues } from '../actions';
import update from 'immutability-helper';

const initialData = {
  version: 0,
  pathDetail: {}
}

export default (state = initialData, action) => {
  switch (action.type) {
    case ConstValues.SET_LEARN_VERSION:
      return update(state, { version: { $set: action.data } });
    case ConstValues.LEARN_SET_PATH_DETAIL:
      return update(state, { pathDetail: { $set: action.data } });
    default:
      return state
  }
}