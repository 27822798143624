import ConstValues from './consts';
import { get, post } from '../tools/api'
import { runtimeConfig } from '../config'
const { api_domain, prod_env } = runtimeConfig

import appInsights, { getGlobalParams } from '../tools/telemetry'

export const login = ({ username, password, remember, returnUrl = '/' }) => async (dispatch) => {
  if (!username || !password) {
    dispatch({
      type: ConstValues.LOGIN_FAILED,
      errorMessage: ''
    });
    return;
  }

  dispatch({
    type: ConstValues.LOGIN_START
  });

  let loginInput = {
    userIdentity: username.indexOf("@") > 0 ? username : "86" + username,
    password: password,
    isPersistent: remember
  };

  let url = `${api_domain}api/universalauth/login`;

  try {
    await post(url, loginInput, true);
    dispatch({
      type: ConstValues.LOGIN_SUCCESS
    });
    appInsights.trackEvent({ name: 'Academy_UserActionEvent' }, { IsSuccess: true, ButtonOrMenuName: 'Submit', ...getGlobalParams() })
    window.location.assign(returnUrl)
  } catch (err) {
    let errorMessage = '';
    if (err.hasOwnProperty('errorMessage')) {
      errorMessage = err.errorMessage;
    } else {
      errorMessage = '未知错误';
    }
    appInsights.trackEvent({ name: 'Academy_UserActionEvent' }, { IsSuccess: false, ButtonOrMenuName: 'Submit', ...getGlobalParams() })
    dispatch({
      type: ConstValues.LOGIN_FAILED,
      errorMessage
    });
  }
}

export const validUserName = (username) => async (dispatch) => {
  if (!username) {
    dispatch({
      type: ConstValues.LOGIN_VALID_USERNAME,
      errorMessage: ''
    });
    return;
  }

  if (!validateReg(username)) {
    dispatch({
      type: ConstValues.LOGIN_VALID_USERNAME,
      errorMessage: '请输入正确的邮箱/手机号'
    });
  } else {
    dispatch({
      type: ConstValues.LOGIN_VALID_USERNAME,
      errorMessage: ''
    });
  }
}

export const register = ({ company, username, email, phone, code, password, read }) => async (dispatch) => {
  if (!code || !password || !read) {
    dispatch({
      type: ConstValues.REGISTER_FAILED,
      errorMessage: ''
    });
    return;
  }

  dispatch({
    type: ConstValues.REGISTER_START
  });

  let registInput = {
    companyName: company,
    fullName: username,
    email: email,
    phoneNumber: "86" + phone,
    phoneToken: code,
    timeOffset: new Date().getTimezoneOffset(),
    language: navigator.language,
    password: password
  };

  let url = `${api_domain}api/universalauth/register`;

  try {
    await post(url, registInput, true);
    dispatch({
      type: ConstValues.REGISTER_SUCCESS
    });
    appInsights.trackEvent({ name: 'Academy_UserActionEvent' }, { IsSuccess: true, ButtonOrMenuName: 'Submit', ...getGlobalParams() })

    if (prod_env) {
      let crm = window.trackXsyForm.submitCreateLeadFormByObj({
        username: username,
        mobile: phone,
        email: email,
        formName: '官网-云扩学院注册',
        customObject: { campaignId: 922030500627156 }
      }, 1);
    }

    window.location.assign('/');
  } catch (err) {
    let errorMessage = '';
    if (err.hasOwnProperty('errorMessage')) {
      errorMessage = err.errorMessage;
    } else {
      errorMessage = '未知错误';
    }
    appInsights.trackEvent({ name: 'Academy_UserActionEvent' }, { IsSuccess: false, ButtonOrMenuName: 'Submit', ...getGlobalParams() })
    dispatch({
      type: ConstValues.REGISTER_FAILED,
      errorMessage
    });
  }
}

export const validName = (username) => async (dispatch) => {
  if (!username) {
    dispatch({
      type: ConstValues.REGISTER_VALID_USERNAME_FAILED,
      errorMessage: '请输入用户名'
    });

    return;
  }

  dispatch({
    type: ConstValues.REGISTER_VALID_USERNAME_SUCCESS,
  });

  return;

  let url = `${api_domain}api/visitor/reg-name-validate?name=${username}`;

  try {
    await get(url);
    dispatch({
      type: ConstValues.REGISTER_VALID_USERNAME_SUCCESS,
    });
  } catch (err) {
    let errorMessage = '';
    if (err.hasOwnProperty('errorMessage')) {
      errorMessage = err.errorMessage;
    } else {
      errorMessage = '未知错误';
    }
    dispatch({
      type: ConstValues.REGISTER_VALID_USERNAME_FAILED,
      errorMessage
    });
  }
}

export const validEmail = (email) => async (dispatch) => {
  if (!email) {
    dispatch({
      type: ConstValues.REGISTER_VALID_EMAIL_FAILED,
      errorMessage: '请输入邮箱'
    });
    return;
  }

  if (!validateReg(email)) {
    dispatch({
      type: ConstValues.REGISTER_VALID_EMAIL_FAILED,
      errorMessage: '邮箱格式不正确'
    });
    return;
  }

  let url = `${api_domain}api/universalauth/validate-email`;
  try {
    await post(url, { email });
    dispatch({
      type: ConstValues.REGISTER_VALID_EMAIL_SUCCESS
    });
  } catch (err) {
    let errorMessage = '';
    if (err.hasOwnProperty('errorMessage')) {
      errorMessage = err.errorMessage;
    } else {
      errorMessage = '未知错误';
    }
    dispatch({
      type: ConstValues.REGISTER_VALID_EMAIL_FAILED,
      errorMessage
    });
  }
}

export const validPhone = (phone) => async (dispatch) => {
  if (!phone) {
    dispatch({
      type: ConstValues.REGISTER_VALID_PHONE_FAILED,
      errorMessage: '请输入手机号'
    });
    return;
  }

  if (!validateReg(phone)) {
    dispatch({
      type: ConstValues.REGISTER_VALID_PHONE_FAILED,
      errorMessage: '请输入正确的手机号'
    });
    return;
  }

  let url = `${api_domain}api/universalauth/validate-mobile`;
  try {
    await post(url, { phoneNumber: "86" + phone });
    dispatch({
      type: ConstValues.REGISTER_VALID_PHONE_SUCCESS,
    });
  } catch (err) {
    let errorMessage = '';
    if (err.hasOwnProperty('errorMessage')) {
      errorMessage = err.errorMessage;
    } else {
      errorMessage = '未知错误';
    }
    dispatch({
      type: ConstValues.REGISTER_VALID_PHONE_FAILED,
      errorMessage
    });
  }
}

export const validCode = (code) => async (dispatch) => {
  if (!code) {
    dispatch({
      type: ConstValues.REGISTER_VALID_CODE,
      errorMessage: '请输入验证码'
    });
  } else {
    dispatch({
      type: ConstValues.REGISTER_VALID_CODE,
      errorMessage: ''
    });
  }
}

export const validPassword = (password) => async (dispatch) => {
  if (!password) {
    dispatch({
      type: ConstValues.REGISTER_VALID_PASSWORD,
      errorMessage: '请输入密码'
    });
  } else {
    dispatch({
      type: ConstValues.REGISTER_VALID_PASSWORD,
      errorMessage: ''
    });
  }
}

export const validRePassword = ({ password, rePassword }) => async (dispatch) => {
  if (!password) {
    dispatch({
      type: ConstValues.REGISTER_VALID_REPASSWORD,
      errorMessage: ''
    });
    return;
  }
  if (!rePassword) {
    dispatch({
      type: ConstValues.REGISTER_VALID_REPASSWORD,
      errorMessage: '请输入确认密码'
    });
  } else if (password !== rePassword) {
    dispatch({
      type: ConstValues.REGISTER_VALID_REPASSWORD,
      errorMessage: '密码不一致'
    });
  } else {
    dispatch({
      type: ConstValues.REGISTER_VALID_REPASSWORD,
      errorMessage: ''
    });
  }
}

export const validRead = (read) => async (dispatch) => {
  if (!read) {
    dispatch({
      type: ConstValues.REGISTER_VALID_READ,
      errorMessage: '请阅读并接受《云扩学院隐私条款》'
    });
  } else {
    dispatch({
      type: ConstValues.REGISTER_VALID_READ,
      errorMessage: ''
    });
  }
}

export const fetchCode = (phone) => async (dispatch) => {
  dispatch({
    type: ConstValues.CODE_START
  });

  let body = {
    phoneNumber: '86' + phone
  };

  let url = `${api_domain}api/universalauth/send-captcha`;

  try {
    await post(url, body, true);
    dispatch({
      type: ConstValues.CODE_SUCCESS
    });
  } catch (err) {
    let errorMessage = '';
    if (err.hasOwnProperty('errorMessage')) {
      errorMessage = err.errorMessage;
    } else {
      errorMessage = '未知错误';
    }
    dispatch({
      type: ConstValues.CODE_FAILED,
      errorMessage
    });
  }
}

export const togglePrivacy = (isRead) => async (dispatch) => {
  dispatch({
    type: ConstValues.TOGGLE_PRIVACY,
    isRead
  });
}

function validateReg(str = '') {
  let regPhone = /^[1]([3-9])[0-9]{9}$/;

  if (regPhone.test(str)) {
    return true;
  }

  if (str.indexOf("@") > 0 && str.lastIndexOf("@") !== str.length - 1) {
    return true;
  }

  return false;
}
