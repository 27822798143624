import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Logo from '../assets/academy-logo/academy-logo-new.png';
import LogoFooter from '../assets/academy-logo/logo-footer-new.png';
import IconUserNone from '../assets/profile/user.png';
import RemSizeSetter from '../components/RemSizeSetter';
import AdvancedSearch from '../components/AdvancedSearch';
import TabletSearch from '../components/TabletSearch';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { userSetLang } from '../actions';
import IconCN from '../assets/academy-logo/cn.svg';
import IconUS from '../assets/academy-logo/us.svg';
import IconJP from '../assets/academy-logo/jp.svg';

import { getCurrentStoredLang } from '../tools/lang'
import { setLang, testLoggedIn, logout, testAdminLoggedIn } from '../actions'

import {runtimeConfig} from '../config'
// const {is_prod} = runtimeConfig
const {login_url} = runtimeConfig
function getQueryParams() {
  var v = {}
  var url = window.location.search.substring(1);
  var varUrl = url.split('&');
  for (var i = 0; i < varUrl.length; i++) {
    var parameter = varUrl[i].split('=');
    if (parameter.length != 2) {
      continue
    }
    var k = decodeURIComponent(parameter[0]).trim()
    var _v = decodeURIComponent(parameter[1]).trim()
    v[k] = _v
  }
  return v
}

const is_prod = false

class BasicLayout extends React.Component {
  constructor(props) {
    super(props)
    this.hamburgerMenuRef = React.createRef()
    this.closeHamburgerMenu = this.closeHamburgerMenu.bind(this)
  }
  componentDidMount() {
    const { location, setLang, testLoggedIn, testAdminLoggedIn } = this.props
    if (!/^\/(zh-cn|en-us|ja-jp)/.test(location.pathname)) {
      let lang = 'zh-cn'
      const persistLang = getCurrentStoredLang();
      if (!!persistLang) {
        lang = persistLang
      }
      setLang(lang)
    }
    testLoggedIn().catch(() => { })
    testAdminLoggedIn()
    this.hamburgerMenuRef.current.addEventListener('menu-close', this.closeHamburgerMenu)
    let qp = getQueryParams()
    let entry_url = qp['entry_url']
    if (entry_url) {
      sessionStorage.setItem('entry_url', entry_url)
      sessionStorage.setItem('entry_url_time', Date.now())
      delete qp.entry_url
      window.history.replaceState(null, '', Object.keys(qp).length >0?`${window.location.origin}${window.location.pathname}?${Object.keys(qp).map(k => `${k}=${encodeURIComponent(qp[k])}`).join('&')}`: `${window.location.origin}${window.location.pathname}`  )
    } else {
      entry_url = sessionStorage.getItem('entry_url')
    }
    this.setState({
      fullLoginUrl: `${login_url}?entry_url=${encodeURIComponent(entry_url)}`,
      extUrlSuffix: `?entry_url=${encodeURIComponent(entry_url)}`
    })
  }
  componentDidUpdate(prvProps) {
    if (!prvProps.adminLoggedIn && this.props.adminLoggedIn) {
      const el = document.createElement('div')
      el.classList.add('admin-tag')
      el.innerHTML = '管理员'
      document.body.prepend(el)
    }
  }
  componentWillUnmount() {
    this.hamburgerMenuRef.current.removeEventListener('menu-close', this.closeHamburgerMenu)
  }

  renderNav() {
    const t = this.props.t
    return (
      <Fragment>
        <div className="nav-item"><a href="/">{t('header.index')}</a></div>
        <div className="nav-item"><a href="/learn">{t('header.courses')}</a></div>
        <div className="nav-item"><a href="/wiki">{t('header.wiki')}</a></div>
        <div className="nav-item"><a href={`https://forum.encoo.com${this.state.extUrlSuffix}`} target="_blank">{t('header.community')}</a></div>
        {/* <div className="nav-item disabled"><a href="#">{t('header.community')}</a></div> */}
        {/* <div className="nav-item disabled"><a href="#">{t('header.workshop')}</a></div> */}
        <div className="nav-item"><a href={`//www.encoo.com${this.state.extUrlSuffix}`} target="_blank">{t('header.official')}</a></div>
      </Fragment>
    )
  }

  state = {
    hamburgerVisible: false,
    fullLoginUrl: login_url,
    extUrlSuffix: ''
  }
  toggleHamburgerMenu(e) {
    e.stopPropagation()
    this.setState((state) => ({
      hamburgerVisible: !state.hamburgerVisible
    }))
  }

  closeHamburgerMenu() {
    console.log('close menu')
    this.setState({
      hamburgerVisible: false
    })
  }

  setLanguage(lang) {
    this.props.userSetLang(lang)
  }

  onHamburgerMenuClicked(e) {
    e.stopPropagation()
    if (e.target.tagName.toLowerCase() === 'a') {
      this.closeHamburgerMenu();
    }
  }

  render() {
    const { children, lang, t, logout, loggedIn, username, history } = this.props;
    let hamClasses = ["hamburger-menu"]
    if (this.state.hamburgerVisible) {
      hamClasses.push("active")
    }
    let hamburgerMenu = (
      <div className={hamClasses.join(' ')} onClick={(e) => this.onHamburgerMenuClicked(e)} ref={this.hamburgerMenuRef}>
        <nav className="collapsed">
          <label>{t('header.nav')}</label>
          <div className="nav-group">
            {this.renderNav()}
          </div>
        </nav>
        {/* <div className="pure-menu pure-menu-horizontal lang-bar">
          <ul className="pure-menu-list">
            <li className="pure-menu-item" onClick={() => this.setLanguage('zh-cn')}>
              <a className="pure-menu-link">{t('header.lang-zh')}<img src={IconCN} /></a>
            </li>
            <li className="pure-menu-item" onClick={() => this.setLanguage('en-us')}>
              <a className="pure-menu-link">{t('header.lang-en')}<img src={IconUS} /></a>
            </li>
            <li className="pure-menu-item" onClick={() => this.setLanguage('ja-jp')}>
              <a className="pure-menu-link">{t('header.lang-ja')}<img src={IconJP} /></a>
            </li>
          </ul>
        </div> */}
        <div className="hamburger-item">
          {loggedIn ? <a onClick={logout}>退出</a> : <a href={this.state.fullLoginUrl}>{t('header.signin')}</a>}
        </div>
      </div>
    )
    let flexibleHeaderPart = (
      <Fragment>
        <nav className="expanded">
          {this.renderNav()}
        </nav>
        <Link className="search-mobile" to="/search-m"></Link>
        <TabletSearch />
        <AdvancedSearch />
        {/* <div className="pure-menu pure-menu-horizontal lang-bar">
          <ul className="pure-menu-list">
            <li className="pure-menu-item pure-menu-has-children pure-menu-allow-hover">
              <a className="pure-menu-link">{t('header.lang')} {lang === 'zh-cn'? <img src={IconCN} />: lang === 'en-us'? <img src={IconUS} />: lang == 'ja-jp'? <img src={IconJP} />: null}</a>
              <ul className="pure-menu-children">
                <li className="pure-menu-item" onClick={() => this.setLanguage('zh-cn')}>
                  <a className="pure-menu-link">{t('header.lang-zh')}<img src={IconCN} /></a>
                </li>
                <li className="pure-menu-item" onClick={() => this.setLanguage('en-us')}>
                  <a className="pure-menu-link">{t('header.lang-en')}<img src={IconUS} /></a>
                </li>
                <li className="pure-menu-item" onClick={() => this.setLanguage('ja-jp')}>
                  <a className="pure-menu-link">{t('header.lang-ja')}<img src={IconJP} /></a>
                </li>
              </ul>
            </li>
          </ul>
        </div> */}
        <div className="reg-login">
          {
            loggedIn ?
              <Fragment>
                <img width="30px" className="avatar" src={IconUserNone} alt="avatar" draggable="false" />
                <div className="clearboth"></div>
                <ul className="profile">
                  <li className="profile-item">
                    <img width="30px" src={IconUserNone} alt="avatar" draggable="false" />
                    <a className="username">{username}</a>
                  </li>
                  <li className="profile-item" onClick={e => history.push("/profile/mycourses")}>
                    <div className="icon-my-courses"></div>
                    <a>我的课程</a>
                  </li>
                  <li className="profile-item" onClick={e => history.push("/profile/mycerts")}>
                    <div className="icon-menu-cert"></div>
                    <a>我的证书</a>
                  </li>
                  <li className="profile-item" onClick={logout}>
                    <div className="icon-logout"></div>
                    <a>退出登录</a>
                  </li>
                </ul>
              </Fragment>
              :
              <a href={this.state.fullLoginUrl}>{t('header.signin')}</a>
          }
        </div>
        <div className="bars-icon" onClick={(e) => this.toggleHamburgerMenu(e)}></div>
        {hamburgerMenu}
      </Fragment>
    )

    return (
      <RemSizeSetter>
        <div className="app-header-wrapper">
          <header className="app-header">
            <div className="logo">
              <img src={Logo} alt="academy logo" width="167" height="51" />
            </div>
            {flexibleHeaderPart}
          </header>
        </div>

        <div>
          {children}
          <div className="footer-widgets">
            <div className="container">
              <div className="row">
                <div className="column col-md-3">
                  <img src={LogoFooter} className="mb-14 footer-logo" />
                  <p>从零开始，打开RPA的大门</p>
                </div>
                <div className="column col-md-3">
                  <h3 className="mb-38">{t('footer.nav')}</h3>
                  <ul className="menu-footer-links-container">
                    <li><Link to="/">首页</Link></li>
                    <li><Link to="/learn">课程</Link></li>
                    <li><Link to="/wiki">文档</Link></li>
                    <li><a href={`https://www.encoo.com${this.state.extUrlSuffix}`} target="_blank">官网</a></li>
                    <li><a href={`https://forum.encoo.com${this.state.extUrlSuffix}`} target="_blank">社区</a></li>
                  </ul>
                </div>
                <div className="column col-md-2">
                  <h3 className="mb-38">产品快速访问</h3>
                  <div className="mb-25"><a href={`https://console.encoo.com${this.state.extUrlSuffix}`} target="_blank">云扩RPA平台</a></div>
                </div>
                <div className="column col-md-4">
                  <h3 className="mb-36">关于我们</h3>
                  <i className="icon-wechat mb-12"><i className="qrcode"></i></i>
                  <div className="mb-12"><i className="icon-phone"></i><span>400-639-2198</span></div>
                  <div className="mb-12"><i className="icon-email"></i><span>contact@encootech.com</span></div>
                  <div className="mb-12"><i className="icon-location"></i><span>上海市徐汇区万科中心H座5楼</span></div>
                </div>
              </div>
            </div>
            <div className="copy-right">Copyright © 2020 <a href="http://www.encoo.com/" target="_blank">上海云扩信息科技有限公司</a></div>
          </div>
        </div>
      </RemSizeSetter>
    )
  }
}

const mapStateToProps = (state) => ({
  winWidth: state.winsize.width,
  lang: state.lang.locale,
  loggedIn: state.global.loggedIn,
  username: state.global.visitorName,
  adminLoggedIn: state.global.adminLoggedIn
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  userSetLang: lang => dispatch(userSetLang(lang, ownProps.history, ownProps.location, ownProps.i18n)),
  setLang: lang => dispatch(setLang(lang)),
  testLoggedIn: () => dispatch(testLoggedIn()),
  logout: () => dispatch(logout()),
  testAdminLoggedIn: () => dispatch(testAdminLoggedIn())
})

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(BasicLayout)))
