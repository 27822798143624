import React from 'react';

class MyModal extends React.Component {
  constructor(props) {
    super(props);
  }

  onHandleClose(e) {
    if (e !== undefined) {
      e.preventDefault();
    }
    const { onHandleClose } = this.props;
    onHandleClose();
  }

  onHandleConfirme(e) {
    e.preventDefault();
    const { onHandleConfirme } = this.props;
    onHandleConfirme(true);
    this.onHandleClose();
  }

  render() {
    const { showModal, children, modalTitle, footer } = this.props;

    return <div className="modal-root">
      <div className={`modal-mask${showModal ? " zoom-in" : " zoom-out"}`}></div>
      <div className="modal-wrap" style={showModal ? { display: "block" } : { display: "none" }}>
        <div className={`modal${showModal ? " scale-in" : " scale-out"}`}>
          <div className="modal-content">
            <button type="button" className="modal-close" onClick={e => this.onHandleClose(e)}>
              <span>
                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg>
              </span>
            </button>
            <div className="modal-header">
              <div className="modal-title">{modalTitle ? modalTitle : "Modal Title"}</div>
            </div>
            <div className="modal-body">
              {children ? children : null}
            </div>
            {
              footer === null || footer !== undefined ? footer
                :
                (
                  <div className="modal-footer">
                    <button className="button-cancel" onClick={e => this.onHandleClose(e)}>取消</button>
                    <button className="button-confirme" onClick={e => this.onHandleConfirme(e)}>接受</button>
                  </div>
                )
            }
          </div>
        </div>
      </div>
    </div>
  }
}

export default MyModal;