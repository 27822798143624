import React from 'react';
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next';
import Breadscrub from '../components/Breadscrub'
import Collapse from '../components/Collapse'
import {initializeRoadMaps} from '../actions'

class LearningPath extends React.PureComponent {
  constructor(props) {
    super(props)
  }
  state = {
  }

  componentDidMount() {
    this.props.initializeRoadMaps();
  }

  render() {
    const { t, roadmap } = this.props;

    return (
      <div style={{backgroundColor: '#F6F7F8'}}>
        <div className="clp-content-area">
          <Breadscrub items={[{ name: '首页', url: '/', trans: true }, { name: '学习路径', url: '/roadmap', trans: false }]} />
          <div className="clp-container">
            <div className="clp-attachment">
              <ul className="attachment">
                <li className="attachment__item">
                  <div className="attachment__icon icon_learning_path"></div>
                  <Link className="attachment__title" to="/roadmap">学习路径</Link>
                </li>
                <li className="attachment__item">
                  <div className="attachment__icon icon_lecturers"></div>
                  <Link className="attachment__title" to="/teamlist">讲师团队</Link>
                </li>
                <li className="attachment__item">
                  <div className="attachment__icon icon_certification"></div>
                  <Link className="attachment__title" to="/certs">认证体系</Link>
                </li>
              </ul>
            </div>
            <div className="learning-path-content">
              {Array.from(roadmap, rm => (
                <Collapse title={rm.roadMapName} cusCls={`course course-${rm.roadMapOrder}`} renderData={rm.courses} key={rm.roadMapId}/>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  roadmap: state.roadmap
})

const mapDispatchToProps = (dispatch) => ({
  initializeRoadMaps: () => dispatch(initializeRoadMaps())
})

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LearningPath)))
