import React, { useState, useRef, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { useDeviceType } from '../tools/hooks'

import { initLearnLandingCondition } from "../actions";

import ImgLearnRoutine1 from '../assets/index/learn-routine-1.png'
import ImgLearnRoutine2 from '../assets/index/learn-routine-2.png'
import ImgLearnRoutine3 from '../assets/index/learn-routine-3.png'
import ImgLearnRoutine4 from '../assets/index/learn-routine-4.png'

import { runtimeConfig } from '../config'
const { is_prod } = runtimeConfig

const STEP_SIZE = 316

const LearningPathContainer = ({ winWidth, history, initLearnLandingCondition }) => {
  const wrapperRef = useRef(null)
  const [hasOverflow, setHasOverflow] = useState(false)
  const [containerLeft, setContainerLeft] = useState(0)
  const [wrapperWidth, setWrapperWidth] = useState(0)
  const [containerWidth, setContainerWidth] = useState(0)
  const [activeRoadMapIndex, setActiveRoadMapIndex] = useState(1)
  const isMobile = useDeviceType(winWidth)
  useEffect(() => {
    if (isMobile) {
      setContainerLeft(0)
    }
  }, [isMobile])

  useEffect(() => {
    if (is_prod) {
      effectiveUpdater()
    } else {
      setTimeout(effectiveUpdater, 600)
    }
  }, [winWidth])

  function effectiveUpdater() {
    const wrapperWidth = wrapperRef.current.offsetWidth
    const containerWidth = wrapperRef.current.querySelectorAll('.learn-routine-item').length * STEP_SIZE
    console.log(wrapperWidth, containerWidth)
    if (wrapperWidth < containerWidth) {
      setHasOverflow(true)
    } else {
      setHasOverflow(false)
    }
    setWrapperWidth(wrapperWidth)
    setContainerWidth(containerWidth)
  }

  const hasScroller = !isMobile && hasOverflow

  const scrollLeftEnabled = containerLeft > 0
  const scrollRightEnabled = (containerLeft + wrapperWidth) < containerWidth

  function handleScrollLeft() {
    let _containerLeft = containerLeft - STEP_SIZE;
    if (_containerLeft <= 0) {
      _containerLeft = 0
    }
    setContainerLeft(_containerLeft)
  }

  function handleScrollRight() {
    const maxRight = containerWidth - wrapperWidth;
    let _containerLeft = containerLeft + STEP_SIZE
    if (_containerLeft >= maxRight) {
      _containerLeft = maxRight
    }
    setContainerLeft(_containerLeft)
  }

  function navRoadmap({ name, forceForbiden = false }) {
    if (forceForbiden) {
      return;
    }
    initLearnLandingCondition(name)
    history.push('/learn');
  }

  return (
    <div className="learn-routine">
      <div className="explain">
        <h3>选择不同的学习角色</h3>
        <p>为开发者与管理者提供定制化学习线路，开启精彩的RPA之旅。</p>
      </div>
      <div className="learn-routine-container-wrapper" ref={wrapperRef}>
        {
          hasScroller ?
            (
              <Fragment>
                <div className={`scroll-left${scrollLeftEnabled ? ' active' : ''}`} onClick={() => {
                  scrollLeftEnabled && handleScrollLeft()
                }}></div>
                <div className={`scroll-right${scrollRightEnabled ? ' active' : ''}`} onClick={() => {
                  scrollRightEnabled && handleScrollRight()
                }}></div>
              </Fragment>
            ) :
            null
        }
        <div className="learn-routine-container" style={{ left: -containerLeft }}>
          <Fragment>
            <div className="learn-routine-item">
              <div className={`learn-routine-inner lr-1${activeRoadMapIndex === 1 ? ' active' : ''}`}
                onMouseEnter={() => setActiveRoadMapIndex(1)}>
                <h3>RPA 办公爱好者</h3>
                <p className="time">学习时间：6小时</p>
                <p>针对编程零基础的，日常使用办公软件的你。完成路径后，您将实现办公效率的飞跃！</p>
                <button onClick={() => navRoadmap({ name: 'RPA 办公爱好者' })}>查看详情</button>
                <img src={ImgLearnRoutine1} style={{ width: 102, height: 102, top: 28, right: 23 }} />
              </div>
            </div>
            <div className="learn-routine-item">
              <div className={`learn-routine-inner lr-2${activeRoadMapIndex === 2 ? ' active' : ''}`}
                onMouseEnter={() => setActiveRoadMapIndex(2)}>
                <h3>RPA 开发者</h3>
                <p className="time">学习时间：12小时</p>
                <p>针对有编程基础的，希望深入了解 RPA 知识，开发 RPA 项目的你。完成路径后，您将能够玩转 RPA！</p>
                <button onClick={() => navRoadmap({ name: 'RPA 开发者' })}>查看详情</button>
                <img src={ImgLearnRoutine2} style={{ width: 110, height: 82, top: 40, right: 23 }} />
              </div>
            </div>
            <div className="learn-routine-item">
              <div className={`learn-routine-inner lr-3${activeRoadMapIndex === 3 ? ' active' : ''}`}
                onMouseEnter={() => setActiveRoadMapIndex(3)}>
                <h3>RPA 实施工程师</h3>
                <p className="time">学习时间：12小时</p>
                <p>针对志向成为 RPA 实施工程师的人员。完成路径后，您将了解云扩 RPA 开发部署工具；并可以报名参加 RPA 实施工程师高级培训。</p>
                <button onClick={() => navRoadmap({ name: 'RPA 实施工程师' })}>查看详情</button>
                <img src={ImgLearnRoutine3} style={{ width: 92, height: 92, top: 37, right: 21 }} />
              </div>
            </div>
            <div className="learn-routine-item">
              <div className={`learn-routine-inner lr-4${activeRoadMapIndex === 4 ? ' active' : ''}`}
                onMouseEnter={() => setActiveRoadMapIndex(4)}>
                <h3>RPA 解决方案架构师</h3>
                <p className="time">学习时间：10小时</p>
                <p>针对希望成为 RPA 售前/解决方案架构师的你。 完成路径后，您将了解全面的云扩产品知识，并可以报名参加 RPA 解决方案架构师高级培训。</p>
                <button onClick={() => navRoadmap({ name: 'RPA 解决方案架构师' })}>查看详情</button>
                <img src={ImgLearnRoutine4} style={{ width: 91, height: 92, top: 40, right: 23 }} />
              </div>
            </div>
            <div className="learn-routine-item">
              <div className={`learn-routine-inner lr-5${activeRoadMapIndex === 5 ? ' active' : ''}`}
                onMouseEnter={() => setActiveRoadMapIndex(5)}>
                <h3>RPA 实施项目经理</h3>
                <p className="time">学习时间：12小时</p>
                <p>针对志向成为 RPA 实施项目经理的你。完成路径后，您将了解全面的云扩产品知识，并可以报名参加 RPA 实施项目经理高级培训。</p>
                <button onClick={() => navRoadmap({ name: 'RPA 实施项目经理' })}>查看详情</button>
                <img src={ImgLearnRoutine4} style={{ width: 91, height: 92, top: 40, right: 23 }} />
              </div>
            </div>
          </Fragment>
        </div>
      </div>
    </div >
  )

}

const mapStateToProps = (state) => ({
  winWidth: state.winsize.width
})

const mapDispatchToProps = dispatch => ({
  initLearnLandingCondition: roleName => dispatch(initLearnLandingCondition(roleName))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LearningPathContainer));
