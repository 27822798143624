import {ConstValues} from '../actions'
import update from 'immutability-helper'

const initialData = {
  threadIndicies: [], // [{commentId, threads: [{threadId, content, authorId, author, createTime}]}]
}

export default (state = initialData, action) => {
  switch(action.type) {
    case ConstValues.SET_THREADS:
    {
      const {commentId, threads} = action
      const commentIdx = state.threadIndicies.findIndex(p => p.commentId === commentId);
      if (commentIdx >= 0) {
        return update(state, {threadIndicies: {[commentIdx]: {threads: {$set: threads}}}})
      }
      return update(state, {threadIndicies: {$push: [{commentId, threads}]}})
    }
    default:
      return state;
  }
}