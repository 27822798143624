import React, {Fragment} from 'react'
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'
import {fuzzySearch} from '../../actions'
import ReactLoading from "react-loading"
import MobileSearchDocs from '../../components/search-m/MobileSearchDocs'
import MobileSearchLearns from '../../components/search-m/MobileSearchLearns'

class MobileGenericSearch extends React.Component {
  componentDidMount() {
    const {mobileConfirmWord, fuzzySearch} = this.props
    if (!!mobileConfirmWord) {
      fuzzySearch(mobileConfirmWord)
    }
  }
  componentDidUpdate(prevProps) {
    const {mobileConfirmWord, fuzzySearch} = this.props
    const {mobileConfirmWord: prevMobileConfirmWord} = prevProps
    if (!!mobileConfirmWord && mobileConfirmWord !== prevMobileConfirmWord) {
      fuzzySearch(mobileConfirmWord)
    }
  }

  render() {
    const {word, mobileConfirmWord, fuzzySearch, generic} = this.props
    const isLoading = generic.isLoading
    const isNoResult = generic.docs.length === 0 && generic.learns.length === 0
    if (!word && !mobileConfirmWord && !isLoading) {
      return <EmptyResult />
    } else if (word && !mobileConfirmWord && !isLoading) {
      return <SemiEmptyResult word={word} fuzzySearch={fuzzySearch} />
    } else if (isLoading) {
      return (
        <div className="loading-container">
          <ReactLoading type="bars" color="lightgray" className="loading-element"/>
        </div>
      )
    } else if (isNoResult) {
      return <div className="no-result">
        Oooops<br/>没有相关的搜索结果！
      </div>
    } else {
      return (
        <Fragment>
          {generic.docs.length>0? <MobileSearchDocs isGeneric={true} />: null}
          {generic.learns.length>0? <MobileSearchLearns isGeneric={true} />: null}
        </Fragment>
      )
    }
  }
}

const mapStateToProps = state => {
  const {word} = state.search

  const {mobileConfirmWord, generic} = state.sm

  return {word, mobileConfirmWord, generic}
}

const mapDispatchToProps = dispatch => ({
  fuzzySearch: word => dispatch(fuzzySearch(word)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileGenericSearch)

const EmptyResult = () => (
  <div className="generic-empty">
    <div className="title">搜索指定内容</div>
    <div className="link-line">
      <Link to="/search-m/cat/docs">文档</Link>
      <Link to="/search-m/cat/learn">课程</Link>
    </div>
  </div>
)

const SemiEmptyResult = ({word, fuzzySearch}) => (
  <div className="generic-semi-empty" onClick={() => fuzzySearch(word)}>
    <i/><label>搜索关键词：</label><span>{word}</span>
  </div>
)