import { toast } from 'react-toastify';
import ConstValues from './consts';
import { get, post } from '../tools/api'
import { runtimeConfig } from '../config'
const { api_domain } = runtimeConfig

//version已弃用
export function updateWiki(version, lang, force = false) {
  return async function (dispatch, getState) {
    version || (version = '')
    lang || (lang = '')
    const { wiki } = getState();
    if (!force && !!wiki.tree) {
      return;
    }
    try {
      const url = `${api_domain}api/wiki/wiki-toc?version=${version}&lang=${lang}`
      const result = await get(url)
      let { items } = result
      addKeys4WikiTreeItems(items)
      dispatch({
        type: ConstValues.UPDATE_WIKI_TREE,
        items
      })
    } catch (err) {
      console.error(err)
    }
  }
}

function addKeys4WikiTreeItems(items) {
  for (const item of items) {
    item.key = item.fullPath
    if (item.folderId) {
      addKeys4WikiTreeItems(item.items)
    }
  }
}

function setWikiBreadscrumbs(breadscrumbs, lang) {
  return function (dispatch) {
    lang || (lang = 'zh-cn')
    breadscrumbs = breadscrumbs.map(p => ({ name: p }))
    breadscrumbs = [{ name: '首页', url: '/', trans: true }, { name: '文档', url: `/${lang}/wiki/Index.md`, trans: true }, ...breadscrumbs]
    dispatch({
      type: ConstValues.SET_BREADSCRUMBS,
      breadscrumbs
    })
  }
}

// export function setWikiBreadscrumbsByWikiId(wikiId) {
//   return function(dispatch, getState) {
//     const {tree} = getState().wiki
//     if (!tree) {
//       return
//     }
//     let keyArray = [], breadscrumbs = []
//     findOpenKeys(tree, Number.parseInt(wikiId), keyArray, breadscrumbs)
//     dispatch(setWikiBreadscrumbs(breadscrumbs))
//   }
// }

export function setWikiBreadscrumbsByPath(path, lang) {
  return function (dispatch) {
    const pathSplit = path.split('/')
    if (pathSplit && pathSplit.length > 0) {
      let breadscrumbs = dispatch(getLocaleBreadscrumbs(pathSplit))
      dispatch(setWikiBreadscrumbs(breadscrumbs, lang))
    }
  }
}

// export function initializeWikiTreeOpenKeys(wikiId) {
//   return function(dispatch, getState) {
//     const {tree} = getState().wiki
//     if (!tree) {
//       return
//     }
//     let keyArray = [], breadscrumbs = []
//     findOpenKeys(tree, Number.parseInt(wikiId), keyArray, breadscrumbs)
//     dispatch({
//       type: ConstValues.INITIALIZE_WIKI_TREE_OPEN_KEYS,
//       openKeys: keyArray
//     })
//     dispatch(setWikiBreadscrumbs(breadscrumbs))
//   }
// }

export function initializeWikiTreeOpenKeysByPath(path, lang) {
  return function (dispatch) {
    const pathSplit = path.split('/')
    let openKeys = []
    for (let i = 0; i < pathSplit.length; ++i) {
      openKeys[i] = pathSplit.filter((_, idx) => idx <= i).join('/')
    }
    dispatch({
      type: ConstValues.INITIALIZE_WIKI_TREE_OPEN_KEYS,
      openKeys: openKeys
    })
    if (pathSplit && pathSplit.length > 0) {
      let breadscrumbs = dispatch(getLocaleBreadscrumbs(pathSplit))
      dispatch(setWikiBreadscrumbs(breadscrumbs, lang))
    }

  }
}

function getLocaleBreadscrumbs(pathSplit) {
  return function (dispatch, getState) {
    const { tree } = getState().wiki
    let items = tree
    if (items === null) {
      return []
    }
    let result = []
    for (const pathItem of pathSplit) {
      const isFile = pathItem.endsWith('.md')
      const pathItem2 = isFile ? pathItem.substr(0, pathItem.length - 3) : pathItem
      const currentItem = items.find(p => p.name === pathItem2 && (isFile && p.wikiId !== undefined || !isFile && p.folderId !== undefined))
      if (!currentItem) {
        break;
      }
      items = currentItem.items
      result.push(currentItem.localeName)
    }
    return result
  }
}

// function findOpenKeys(items, wikiId, keyArray, breadscrumbs) {
//   for (let i=0; i < items.length; ++i) {
//     const item = items[i];
//     if (item.wikiId) {
//       if (item.wikiId === wikiId) {
//         keyArray.push(item.key);
//         breadscrumbs.push(item.name)
//         return true;
//       }
//     } else {
//       keyArray.push(item.key)
//       breadscrumbs.push(item.name)
//       const res = findOpenKeys(item.items, wikiId, keyArray, breadscrumbs)
//       if (res) {
//         return true
//       } else {
//         keyArray.pop()
//         breadscrumbs.pop()
//       }
//     }
//   }
//   return false;
// }

export function toggleWikiFolder(key) {
  return {
    type: ConstValues.TOGGLE_WIKI_TREE_FOLDER,
    key
  }
}

export function setWikiPage(key) {
  return {
    type: ConstValues.SET_WIKI_TREE_PAGE,
    key
  }
}

// export function getWikiMarkdown(wikiId) {
//   return async function(dispatch) {
//     const url = `${api_domain}api/wiki/wiki-markdown?wikiId=${wikiId}`
//     try {
//       const data = await get(url)
//       dispatch({
//         type: ConstValues.SET_WIKI_MARKDOWN,
//         text: data
//       })
//     } catch (err) {
//       throw err
//     }
//   }
// }

export function getWikiMarkdownByPath(path, version, lang) {
  return async function (dispatch) {
    version || (version = '')
    lang || (lang = '')
    const url = `${api_domain}api/wiki/wiki-markdown-bypath`
    try {
      const data = await post(url, { path, version, lang })
      dispatch({
        type: ConstValues.SET_WIKI_MARKDOWN,
        text: data.content,
        wikiId: data.wikiId,
        labels: data.labels,
        lastUpdateTime: data.lastUpdateTime
      })
    } catch (err) {
      throw err
    }
  }
}

export function setNavInPage(navInPage) {
  return {
    type: ConstValues.SET_WIKI_NAVINPAGE,
    navInPage
  }
}

export function updateActiveNavInPage(pageYOffset) {
  return function (dispatch, getState) {
    const { navInPage } = getState().wiki
    const { wikiTop } = getState().winsize
    if (!navInPage || !wikiTop) {
      return
    }
    const navInPage_length = navInPage.length;
    let inRangeNavIdx = -1;
    for (let i = 0; i < navInPage_length; ++i) {
      const currentItem = navInPage[navInPage_length - 1 - i];
      if (currentItem.top + wikiTop <= pageYOffset) {
        inRangeNavIdx = navInPage_length - 1 - i;
        break;
      }
    }
    //不显示文档标题，即从1开始显示
    if (inRangeNavIdx < 1) {
      inRangeNavIdx = 1
    }
    const newNavInPage = navInPage.map((item, idx) => {
      return {
        ...item,
        active: inRangeNavIdx === idx
      }
    })
    dispatch({
      type: ConstValues.SET_WIKI_NAVINPAGE,
      navInPage: newNavInPage
    })
  }
}

export function updateActiveNavInPageForce(id) {
  return function (dispatch, getState) {
    const { navInPage } = getState().wiki
    if (!navInPage) {
      return
    }
    const newNavInPage = navInPage.map(item => {
      return {
        ...item,
        active: id === item.id
      }
    })
    dispatch({
      type: ConstValues.SET_WIKI_NAVINPAGE,
      navInPage: newNavInPage
    })
  }
}

export function wikiSearch(searchWord, version, lang) {
  return async function () {
    version || (version = '')
    lang || (lang = '')
    const url = `${api_domain}api/wiki/wiki-search?searchWord=${searchWord}&version=${version}&lang=${lang}`;
    try {
      const data = get(url)
      return data
    } catch (err) {
      console.error(err)
      return []
    }
  }
}

export function getComments(wikiId, asc) {
  return async function (dispatch) {
    let _asc = !!asc;
    const url = `${api_domain}api/comment/wiki-comments?wikiId=${wikiId}&asc=${_asc}`
    try {
      const data = await get(url)
      dispatch({
        type: ConstValues.SET_COMMENT_DATA,
        data
      })
    } catch (err) {
      console.error(err)
    }
  }
}

export function addWikiComment(wikiId, content, asc) {
  return async function (dispatch) {
    const url = `${api_domain}api/comment/add-wiki-comment`
    wikiId = Number.parseInt(wikiId)
    try {
      await post(url, { wikiId, content })
      dispatch(getComments(wikiId, asc))
    } catch (err) {
      let { errorMessage } = err
      errorMessage || (errorMessage = '????')
      throw new Error(errorMessage)
    }
  }
}

// export function setWikiEditBreadscrumbsByWikiId(wikiId) {
//   return function(dispatch, getState) {
//     let keyArray = [], breadscrumbs = []
//     const {tree} = getState().wiki
//     if (!tree) {
//       breadscrumbs.push('编辑wiki页面')
//       dispatch(setWikiBreadscrumbs(breadscrumbs))
//       return
//     }
//     findOpenKeys(tree, Number.parseInt(wikiId), keyArray, breadscrumbs)
//     breadscrumbs.push('编辑wiki页面')
//     dispatch(setWikiBreadscrumbs(breadscrumbs))
//   }
// }

// export function saveWidiEditingRequest(wikiId, content, comment) {
//   return async function() {
//     var formData = new FormData();
//     formData.append('file', new File([new Blob([content])], 'new_article.md'));
//     formData.append('wikiId', wikiId);
//     formData.append('comment', comment);
//     try {
//       const response = await fetch(`${api_domain}api/wiki/wiki-edit-request`, {
//         method: 'POST',
//         body: formData,
//         credentials: 'include'
//       });
//       const result = await response.json();
//       if (!result.success) {
//         toast(result.errorMessage)
//         throw new Error(result.errorMessage)
//       }
//       toast("文章修改的申请提交成功")
//     } catch (error) {
//       toast("上传文章出错，未知错误")
//       console.error('Error:', error);
//       throw new Error("上传文章出错，未知错误")
//     }
//   }
// }

export function setWikiCoverBreadscrumbs() {
  return function (dispatch) {
    let breadscrumbs = [{ name: '首页', url: '/', trans: true }, { name: '文档', url: '/zh-cn/wiki/Index.md', trans: true }]
    dispatch({
      type: ConstValues.SET_BREADSCRUMBS,
      breadscrumbs
    })
  }
}

export function getFullClassNameRedirectPath(id, version, lang) {
  return async function () {
    version || (version = '')
    lang || (lang = '')
    const url = `${api_domain}api/wiki/redirect-path?id=${id}&version=${version}&lang=${lang}`
    try {
      const data = await get(url, true)
      return data
    } catch (err) {
      return null
    }
  }
}

export function getWikiVersions() {
  return async function (dispatch, getState) {
    const { versions, locale: lang } = getState().lang
    if (versions.length > 0) {
      return
    }
    console.log(`get wiki versions with lang = ${lang}`)
    const url = `${api_domain}api/wiki/versions?lang=${lang}`
    try {
      const data = await get(url)
      dispatch({
        type: ConstValues.SET_DOC_VERSIONS,
        versions: data
      })
    } catch (err) { }
  }
}

export function getGithubPageUrl(path) {
  return async function (_, getState) {
    let { version, locale: lang } = getState().lang
    version || (version = '')
    lang || (lang = '')
    const url = `${api_domain}api/wiki/github-page-url?path=${window.encodeURIComponent(path)}&version=${version}&lang=${lang}`
    try {
      const data = await get(url)
      return data
    } catch (err) {
      return ''
    }
  }
}

export function getGithubIssueUrl(path) {
  return async function (_, getState) {
    let { version, locale: lang } = getState().lang
    version || (version = '')
    lang || (lang = '')
    const url = `${api_domain}api/wiki/github-issue-url?path=${window.encodeURIComponent(path)}&version=${version}&lang=${lang}`
    try {
      const data = await get(url)
      return data
    } catch (err) {
      return ''
    }
  }
}

export function getUuidByPath(pathname = '', lang, version) {
  return async function () {
    pathname = pathname.replace(/\\/g, "\/")
    const url = `${api_domain}api/wiki/get-uuid-by-fullpath?path=${pathname}&lang=${lang}&version=${version}`
    try {
      const data = await get(url)
      return data
    } catch (err) {
      return ''
    }
  }
}

export function getWikiCommentCount() {
  return async function (_, getState) {
    const { wiki: { wikiId } } = getState()
    const url = `${api_domain}api/AdminComment/wiki-comment-count?wikiId=${wikiId}`
    try {
      const c = await get(url, true)
      return c
    } catch {
      return 0
    }
  }
}

export function getLearnCommentCount(learnId) {
  return async function () {

    const url = `${api_domain}api/AdminComment/learn-comment-count?learnId=${learnId}`
    try {
      const c = await get(url, true)
      return c
    } catch {
      return 0
    }
  }
}

export function getRelatedWiki(labelList) {
  return async function (_, getState) {
    let { version, locale: lang } = getState().lang
    version || (version = '')
    lang || (lang = '')
    const url = `${api_domain}api/wiki/get-related-wiki?labels=${labelList.join(",")}&version=${version}&lang=${lang}`
    // console.log(`getRelatedWiki url ${url}`)
    try {
      const data = await get(url)
      // console.log(`getRelatedWiki data ${JSON.stringify(data)}`)
      return data
    } catch (err) {
      return ''
    }
  }
}