import { ConstValues } from '../actions'
import update from 'immutability-helper'

const initialData = {
  docs: [],
  docsLoading: false,
  docsCount: 0,
  docsStart: 0,
  learns: [],
  learnsLoading: false,
  learnsCount: 0,
  learnsStart: 0,

  word: '',
  category: '',

  lastSearchWord: ''
}

export default (state = initialData, action) => {
  switch (action.type) {
    case ConstValues.START_SEARCH_DOCS:
      return update(state, {
        docs: { $set: [] },
        docsLoading: { $set: true },
        lastSearchWord: { $set: '' }
      })
    case ConstValues.FINISH_SEARCH_DOCS:
      return update(state, {
        docs: { $set: action.list },
        docsLoading: { $set: false },
        docsCount: { $set: action.count },
        docsStart: { $set: action.start },
        lastSearchWord: { $set: action.word }
      })
    case ConstValues.START_SEARCH_LEARN:
      return update(state, {
        learns: { $set: [] },
        learnsLoading: { $set: true },
        lastSearchWord: { $set: '' }
      })
    case ConstValues.FINISH_SEARCH_LEARN:
      return update(state, {
        learns: { $set: action.list },
        learnsLoading: { $set: false },
        learnsCount: { $set: action.count },
        learnsStart: { $set: action.start },
        lastSearchWord: { $set: action.word }
      })
    case ConstValues.SET_FUZZY_SEARCH_WORD:
      return update(state, {
        word: { $set: action.word }
      })
    case ConstValues.SET_SEARCH_CATEGORY:
      return update(state, {
        category: { $set: action.c }
      })
    case ConstValues.RESET_SEARCH:
      {
        if (action.hard) {
          return initialData
        } else {
          return { ...initialData, word: state.word }
        }
      }
    case ConstValues.REFRESH_SL_LIST:
      {
        let id = action.id;
        let _learns = [...state.learns];
        for (let i = 0; i < _learns.length; i++) {
          if (_learns[i].id == id) {
            _learns[i].isFavorite = !_learns[i].isFavorite;
          }
        }
        update(state, { learns: { $set: _learns } });
      }
    default:
      return state;
  }
}