import React, { Fragment } from 'react'
import { connect } from 'react-redux'

import { setWinSize, setWikiContainerSize, setUnitContainerSize, setFooterSize } from '../actions'

class RemSizeSetter extends React.PureComponent {
  constructor(props) {
    super(props)
    this.onResizeRem = this.onResizeRem.bind(this)
  }
  onResizeRem() {
    const windowWidth = window.innerWidth;
    const { setWinSize } = this.props;
    setWinSize(windowWidth, window.innerHeight)
    this.measureFooter()
  }
  initializeMeasureWikiContainer() {
    this.wikiMeasHandler = setInterval(() => {
      const wikiEl = document.querySelector('.wiki-container')
      if (!wikiEl) {
        return
      }
      const { setWikiContainerSize } = this.props;
      const windowWidth = document.body.offsetWidth;
      const left = wikiEl.offsetLeft
      const right = windowWidth - left - wikiEl.offsetWidth
      const height = wikiEl.offsetHeight
      const top = wikiEl.offsetTop
      setWikiContainerSize(left, right, top, height)
    }, 500)
  }
  initializeMeasureUnitContainer() {
    this.unitMeasHandler = setInterval(() => {
      const unitEl = document.querySelector('.learn-unit-detail')
      if (!unitEl) {
        return
      }
      const { setUnitContainerSize } = this.props;
      const windowWidth = document.body.offsetWidth;
      const left = unitEl.offsetLeft
      const right = windowWidth - left - unitEl.offsetWidth
      const height = unitEl.offsetHeight
      const top = unitEl.offsetTop
      setUnitContainerSize(left, right, top, height)
    }, 500)
  }
  measureFooter() {
    const { setFooterSize } = this.props
    const footerEl = document.querySelector('.footer-widgets')
    if (!!footerEl) {
      this.footerMeasHandler = setTimeout(() => {
        setFooterSize(footerEl.clientHeight)
      }, 500)
    }
  }
  componentDidMount() {
    window.addEventListener('resize', this.onResizeRem)
    this.onResizeRem()
    this.initializeMeasureWikiContainer()
    this.initializeMeasureUnitContainer()
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.onResizeRem)
    this.footerMeasHandler && clearTimeout(this.footerMeasHandler)
    this.wikiMeasHandler && clearInterval(this.wikiMeasHandler)
    this.unitMeasHandler && clearInterval(this.unitMeasHandler)
  }
  render() {
    return (
      <Fragment>
        {this.props.children}
      </Fragment>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  setWinSize: (width, height) => dispatch(setWinSize(width, height)),
  setWikiContainerSize: (left, right, top, height) => dispatch(setWikiContainerSize(left, right, top, height)),
  setUnitContainerSize: (left, right, top, height) => dispatch(setUnitContainerSize(left, right, top, height)),
  setFooterSize: footerHeight => dispatch(setFooterSize(footerHeight)),
})

export default connect(null, mapDispatchToProps)(RemSizeSetter)